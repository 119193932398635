import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useTheme } from "styled-components";

import IconLayoutTree from "Components/icons/IconLayoutTree";
import IconLayoutList2 from "Components/icons/IconLayoutList2";
import ToggleWrapper from "Components/ToggleWrapper";

const ToggleTree = ({ toggleLayout, gridLayout }) => {
  const intl = useIntl();
  const theme = useTheme();

  return (
    <ToggleWrapper>
      <button
        className={`toggle-button ${gridLayout ? "active" : ""}`}
        onClick={() => {
          toggleLayout(true);
          document.activeElement.blur();
        }}
        aria-label={intl.formatMessage({
          id: "environments.tree_layout"
        })}
      >
        <IconLayoutTree color={theme.textLight} />
      </button>
      <button
        className={`toggle-button ${!gridLayout ? "active" : ""}`}
        onClick={() => {
          toggleLayout(false);
          document.activeElement.blur();
        }}
        aria-label={intl.formatMessage({
          id: "environments.list_layout"
        })}
      >
        <IconLayoutList2 color={theme.textLight} />
      </button>
    </ToggleWrapper>
  );
};

ToggleTree.propTypes = {
  toggleLayout: PropTypes.func,
  gridLayout: PropTypes.bool
};

export default ToggleTree;
