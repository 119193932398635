import styled from "styled-components";
import { semiBoldAlias, themeHelpers } from "Libs/theme";

export const Wrapper = styled.div``;

export const Live = styled.span`
  display: inline-block;
  margin: 4px 0 4px auto;
  background: ${props => props.theme.liveBtn};
  border-radius: 2px;
  color: ${props => props.theme.primary};
  background: ${props =>
    props.theme.name === "dark"
      ? themeHelpers(props.theme, "sectionColor")
      : themeHelpers(props.theme, "linkTint")};
  color: ${props =>
    props.theme
      ? props => themeHelpers(props.theme, "buttonColor")
      : "#4786ff"};
  font-size: 12px;
  line-height: 24px;
  height: 24px;
  padding: 0 8px;
  ${semiBoldAlias()};
`;
