import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const SyncIcon = ({ color = "#fff" }) => {
  const uid = useUniqueId();

  return (
    <svg
      className="icon-sync"
      height="24px"
      width="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`sync-icon-${uid}`}
    >
      <title id={`sync-icon-${uid}`}>Sync icon</title>
      <path
        fill={color}
        className="fill-target"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1,2.7L13.7,4l2,1.8c0.4,0.3,0.4,0.9,0,1.2l-2,1.6l-1.6,1.3c-0.5,0.4-1.1,0-1.1-0.6V7.2 c-2.8,0.5-5,2.9-5,5.9c0,2.7,1.8,5,4.3,5.8c0.5,0.1,0.8,0.6,0.7,1.1c-0.1,0.6-0.7,1-1.3,0.8C6.4,19.7,4,16.6,4,13.1 C4,9,7.1,5.7,11,5.2V3.3C11,2.6,11.6,2.3,12.1,2.7z M17.6,9c-0.4,0.3-0.5,0.8-0.3,1.3c0.2,0.4,0.4,0.8,0.5,1.2 c0.1,0.5,0.6,0.8,1.1,0.7c0.6-0.1,1-0.7,0.8-1.3c-0.1-0.2-0.2-0.5-0.3-0.8v0c-0.1-0.3-0.2-0.6-0.3-0.8C18.8,8.8,18.1,8.6,17.6,9z M17.2,16c-0.2,0.5-0.1,1,0.3,1.3c0.5,0.3,1.2,0.2,1.4-0.3c0.2-0.4,0.4-0.8,0.6-1.3c0-0.1,0.1-0.2,0.1-0.3c0.2-0.6-0.2-1.2-0.8-1.3 c-0.5-0.1-1,0.2-1.1,0.7C17.6,15.2,17.4,15.6,17.2,16z M13.7,18.9C13.2,19,12.9,19.5,13,20s0.7,0.9,1.3,0.8c0.5-0.1,1-0.3,1.5-0.6 c0.5-0.3,0.7-1,0.3-1.5c-0.3-0.4-0.9-0.5-1.3-0.3C14.5,18.6,14.1,18.8,13.7,18.9z"
      />
    </svg>
  );
};

SyncIcon.propTypes = {
  color: PropTypes.string
};

export default SyncIcon;
