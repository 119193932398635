import {
  createTheme,
  defaultThemeDefinition,
  platformPalette
} from "@platformsh/ui-kit";

const ibexaPalette = {
  ...platformPalette,
  // TODO: Move this to vendor_resources
  name: "ibexa",
  colors: {
    ...platformPalette.colors,
    secondary_400: "#CE70A2",
    secondary_500: "#AE1164",
    secondary_600: "#8B0E50"
  }
};

const magentoPalette = {
  ...platformPalette,
  // TODO: Move this to vendor_resources
  name: "magento",
  colors: {
    ...platformPalette.colors,
    secondary_400: "#8B0E50",
    secondary_500: "#fa0f00",
    secondary_600: "#AE1164"
  }
};

let palette = platformPalette;

if (process.env.VENDOR_NAME === "ibexa_cloud") {
  palette = ibexaPalette;
} else if (process.env.VENDOR_NAME === "magento_cloud") {
  palette = magentoPalette;
}

export default createTheme(defaultThemeDefinition, palette);
