import React from "react";
import PropTypes from "prop-types";

import Tabs from "./Tabs";
import * as S from "./styles";

const SubNavBar = ({ className, currentPathName, links, tabs }) => {
  return (
    <S.Wrapper className={className}>
      {tabs.length > 0 && (
        <S.SubNavBarLayout>
          <S.SubNavBarTabWrapper>
            <Tabs
              currentPathName={currentPathName}
              links={links || {}}
              tabs={tabs}
            />
          </S.SubNavBarTabWrapper>
        </S.SubNavBarLayout>
      )}
    </S.Wrapper>
  );
};

SubNavBar.propTypes = {
  className: PropTypes.string,
  currentPathName: PropTypes.string.isRequired,
  links: PropTypes.object,
  tabs: PropTypes.array
};

export default SubNavBar;
