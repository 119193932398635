import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const IconLayoutList2 = ({ color = "#5792FF" }) => {
  const uid = useUniqueId();

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`layout-list-icon-${uid}`}
    >
      <title id={`layout-list-icon-${uid}`}>Layout list icon</title>
      <path
        d="M19 11H5C4.4 11 4 11.4 4 12C4 12.6 4.4 13 5 13H19C19.6 13 20 12.6 20 12C20 11.4 19.6 11 19 11Z"
        fill={color}
      />
      <path
        d="M19 16H5C4.4 16 4 16.4 4 17C4 17.6 4.4 18 5 18H19C19.6 18 20 17.6 20 17C20 16.4 19.6 16 19 16Z"
        fill={color}
      />
      <path
        d="M19 6H5C4.4 6 4 6.4 4 7C4 7.6 4.4 8 5 8H19C19.6 8 20 7.6 20 7C20 6.4 19.6 6 19 6Z"
        fill={color}
      />
    </svg>
  );
};

IconLayoutList2.propTypes = {
  color: PropTypes.string
};

export default IconLayoutList2;
