import React from "react";
import { useSelector } from "react-redux";
import { Redirect, useRouteMatch } from "react-router-dom";
import { getUrls } from "Libs/utils";

const SupportRedirect = () => {
  const username = useSelector(state => state.app?.getIn(["me", "username"]));
  return (
    <Redirect
      from={useRouteMatch().path}
      to={
        getUrls({ accountUrl: process.env.ACCOUNTS_URL, username }).supportUrl
      }
    />
  );
};

export default SupportRedirect;
