import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const Back = ({ color = "#4b6180" }) => {
  const uid = useUniqueId();

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`back-icon-${uid}`}
    >
      <title id={`back-icon-${uid}`}>Back icon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.69933 11.3334L11.9868 7.0459C12.3251 6.70762 12.3251 6.15917 11.9868 5.8209C11.6486 5.48262 11.1001 5.48262 10.7618 5.8209L5.08143 11.5013C4.69091 11.8918 4.69091 12.525 5.08144 12.9155L10.7618 18.5959C11.1001 18.9342 11.6486 18.9342 11.9868 18.5959C12.3251 18.2576 12.3251 17.7092 11.9868 17.3709L7.69933 13.0834L17.4993 13.0834C17.9826 13.0834 18.3743 12.6916 18.3743 12.2084C18.3743 11.7251 17.9826 11.3334 17.4993 11.3334L7.69933 11.3334Z"
        fill={color}
      />
      <mask
        id={`back-mask0-${uid}`}
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="4"
        y="5"
        width="15"
        height="14"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.69933 11.3334L11.9868 7.0459C12.3251 6.70762 12.3251 6.15917 11.9868 5.8209C11.6486 5.48262 11.1001 5.48262 10.7618 5.8209L5.08143 11.5013C4.69091 11.8918 4.69091 12.525 5.08144 12.9155L10.7618 18.5959C11.1001 18.9342 11.6486 18.9342 11.9868 18.5959C12.3251 18.2576 12.3251 17.7092 11.9868 17.3709L7.69933 13.0834L17.4993 13.0834C17.9826 13.0834 18.3743 12.6916 18.3743 12.2084C18.3743 11.7251 17.9826 11.3334 17.4993 11.3334L7.69933 11.3334Z"
          fill="white"
        />
      </mask>
      <g mask={`url(#back-mask0-${uid})`}>
        <rect
          y="24"
          width="24"
          height="24"
          transform="rotate(-90 0 24)"
          fill={color}
        />
      </g>
    </svg>
  );
};

Back.propTypes = {
  color: PropTypes.string
};

export default Back;
