// In this module we track  all the redux actions that trigger a push
// of data to google analytics
import { hasSafeRole } from "Libs/utils";
import {
  goToWizardStep,
  prevWizardStep,
  toggleProjectWizard,
  nextWizardStep
} from "Reducers/project/wizard";
import { addSubcription } from "Reducers/subscription";
import { saveConfig } from "Reducers/project/setup";
import { GET_ME_SUCCESS } from "Reducers/app";
import { selectWizardStepId, getWizardStepFromStore } from "./selectors";

// Qualifyes a user based on parameters that marketing gives us
// Right now we only distinguish between platform employee and regular
// users, since with the introduction of organizations, the way we
// track trials has to change (marketing is still deciding how)
function getUserType(user) {
  if (user.roles && hasSafeRole(user.roles)) {
    return "Platformer";
  }

  // This no longer works, trials are now attached to organizations
  if (user.trial) {
    return "Trial User";
  }

  return "Customer";
}

// Here we map events that trigger some push of data to Google Tag Manager
// As a key put the name of the redux action that we want to act as a
// trigger of a push to the data layer, the value is a function that receives
// the action and the store (if needed) and returns a GTM event object
export const GTMEvents = {
  [GET_ME_SUCCESS]: action => ({
    event: "userLoaded",
    userType: getUserType(action.payload.me),
    userId: action?.payload?.me?.id
  }),
  [addSubcription.fulfilled.type]: () => ({
    event: "free trial started"
  }),
  [toggleProjectWizard.fulfilled.type]: action => ({
    event: action.payload ? "Wizard - Opened" : "Wizard - Closed"
  }),
  [saveConfig().type]: action => {
    // We reuse this action during the various steps of the project creation
    // so depending on the payload we will send a different analytics event
    let trackableEvent = {};

    if (action?.payload?.template) {
      trackableEvent = {
        event: "Project - Template Selected",
        template: action?.payload?.template
      };
    }

    if (action?.payload?.region) {
      trackableEvent = {
        event: "Project - Region Selected",
        region: action?.payload?.region?.id
      };
    }

    return trackableEvent;
  },
  [goToWizardStep().type]: (action, store) => ({
    event: "Wizard - Jumped to Step",
    // steps are an array in the store, so they start at index 0
    // but in the action we pass the step number that starts at
    // index 1, hence the -1
    step: selectWizardStepId(store, action.payload?.step - 1)
  }),
  [prevWizardStep().type]: (_action, store) => ({
    event: "Wizard - Step Viewed",
    step: getWizardStepFromStore(store, "back")
  }),
  [nextWizardStep().type]: (_action, store) => ({
    event: "Wizard - Step Viewed",
    step: getWizardStepFromStore(store)
  })
};

function createGTMEvent(action, store) {
  let event = GTMEvents[action.type]
    ? GTMEvents[action.type](action, store)
    : null;

  return event;
}

export default function googleTagManagerTracker(action, store) {
  const GTMEvent = createGTMEvent(action, store);

  if (GTMEvent !== null) {
    window?.dataLayer?.push(GTMEvent);
  }
}
