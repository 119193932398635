import { useMemo, useEffect } from "react";
import { createPortal } from "react-dom";

const InfoDialogPortal = ({ children }) => {
  const portalContainer = useMemo(() => {
    const container = document.createElement("div");
    container.setAttribute("data-role", "info-dialog-portal");
    return container;
  }, []);

  useEffect(
    () => {
      const app = document.querySelector("#portal-root");
      app.appendChild(portalContainer);

      return () => app.removeChild(portalContainer);
    },
    [portalContainer]
  );

  return createPortal(children, portalContainer);
};

export default InfoDialogPortal;
