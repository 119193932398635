import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import moment from "moment";
import { Link } from "react-router-dom";
import { useTheme } from "styled-components";

import AlertIcon from "Icons/AlertIcon";
import { Wrapper, LeftArt, RightArt } from "../";
import * as S from "./styles";

import useLocalForage from "Hooks/useLocalForage";

const Trial = ({ user, useBrandedArt, organizationId }) => {
  const [isClosed, setIsClosed, , isLoading] = useLocalForage(
    "trial_banner_closed"
  );
  const intl = useIntl();
  const theme = useTheme();

  useEffect(
    () => {
      // coonvert to Array because previously we saved only a global
      // close state, now we have a state for each type of banner
      if (!Array.isArray(isClosed)) setIsClosed([]);
    },
    [isLoading]
  );

  const { current_trial } = user;
  let count;
  if (current_trial && current_trial?.expiration) {
    // We add 1 day, because the expiration date is include to the period
    count = moment(current_trial.expiration).diff(moment(), "days") + 1;
  }

  const { msg, showBrandedArt = false, type } = (() => {
    // Free trial not used or Day 1-20
    if (typeof count === "undefined" || count >= 20)
      return {
        type: "info",
        msg: "state_1",
        showBrandedArt: useBrandedArt
      };

    // Free trial Day 20-30
    if (count >= 1)
      return { type: "info", msg: "state_2", showBrandedArt: useBrandedArt };

    // Free trial Ended Week 1
    if (count > -6) return { type: "warning", msg: "state_3" };

    // Free trial Ended Week 2
    return { type: "critical", msg: "state_4" };
  })();

  const handleClose = e => {
    e?.preventDefault();
    const banners = isClosed || [];
    setIsClosed([...banners, type]);
  };

  if (
    !process.env.ENABLE_BILLING ||
    isLoading ||
    (Array.isArray(isClosed) && isClosed?.includes(type))
  )
    return null;

  let billingUrl = `/billing`;
  if (process.env.ENABLE_ORGANIZATION) {
    billingUrl = `/${organizationId}/-/billing`;
  }

  return (
    <Wrapper type={type}>
      {showBrandedArt ? (
        <LeftArt type={type} />
      ) : (
        <AlertIcon color={type === "critical" ? "#fff" : theme.dark} />
      )}
      {intl.formatMessage(
        {
          id: `banner.trial.${msg}`
        },
        {
          // eslint-disable-next-line react/display-name
          linkBilling: txt => <Link to={billingUrl}>{txt}</Link>
        }
      )}
      {showBrandedArt && <RightArt type={type} />}
      <S.CloseButton type={type} onClick={handleClose} />
    </Wrapper>
  );
};

Trial.propTypes = {
  user: PropTypes.object,
  useBrandedArt: PropTypes.bool,
  organizationId: PropTypes.string
};

export default Trial;
