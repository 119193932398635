import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";

const isLocal = url => {
  return !/^https?:\/\//.test(url);
};

export const Link = ({ to, children, ...props }) =>
  isLocal(to) ? (
    <RouterLink to={to} {...props}>
      {children}
    </RouterLink>
  ) : (
    <a href={to} rel="noopener noreferrer" target="_blank" {...props}>
      {children}
    </a>
  );

Link.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node
};

export default Link;
