import styled from "styled-components";
import { semiBoldAlias, themeHelpers } from "Libs/theme";

export const Layout = styled.div`
  height: 100%;
  position: relative;
  .rcs-custom-scroll {
    margin-right: -30px;
    .rcs-inner-container > div {
      margin-right: 50px !important;
    }
  }
  hr {
    background: ${props => props.theme.sectionBorder};
    box-shadow: none;
    height: 1px;
    border: none;
    margin: 16px 0;
    &.full-width {
      margin: 0 -32px 16px;
    }
  }
  .environments-filter-window > section {
    overflow: visible;
  }
  h6 .info-dialog {
    vertical-align: middle;
  }
  .show-all {
    text-align: center;
    margin-bottom: -16px;
    button.outline {
      margin: 0;
    }
  }
  .environment-row.inactive {
    display: none;
  }
  .show-inactive .environment-row.inactive {
    display: block;
  }
  .hide-active .environment-row.active {
    display: none;
  }
  .environment-row.inactive.active-children,
  .hide-active .environment-row.active.inactive-children,
  .hide-active .environment-row.root.active,
  .environment-row.root.inactive {
    display: block !important;
  }
  .hide-all .environment-row.active,
  .hide-all .environment-row.inactive,
  .hide-all .environment-row.active.inactive-children,
  .hide-all .environment-row.inactive.active-children {
    display: none !important;
  }
  .environments-list {
    a.label.active {
      span.chevron svg path {
        fill: ${props => props.theme.primary};
      }
    }
    .environment-row.active ~ .environment-row.active {
      border-top: 1px solid ${props => props.theme.sectionBorder};
    }
    &.show-inactive {
      .environment-row ~ .environment-row {
        border-top: 1px solid ${props => props.theme.sectionBorder};
      }
    }
    @media (min-width: 600px) {
      .website-link {
        right: 108px;
      }
    }
    @media (min-width: 1280px) {
      .website-link {
        right: 188px;
      }
    }
  }
  .compact {
    .depth-0,
    .depth-1 {
      .list-bar-left {
        line-height: 24px;
        .icon {
          min-width: 24px;
          width: 24px;
          height: 24px;
        }
      }
    }
    .environment-row-item {
      margin-bottom: 8px;
    }
  }
  .rcs-custom-scroll {
    .rcs-inner-container {
      padding: 0;
      box-sizing: border-box;
    }
    .rcs-custom-scrollbar {
      .rcs-inner-handle {
        background: ${props => themeHelpers(props.theme, "darkTint")};
        width: 4px;
        border-radius: 4px;
        opacity: 1;
      }
    }
  }
`;

export const TreeWrapper = styled.div`
  padding: 0;
  ol {
    margin: 0;
    padding: 0;
  }
  .environments-list {
    padding-top: 27px;
  }
  .search-results {
    .sort-heading {
      display: none;
    }
    .environment-row {
      padding: 0 4px 16px;
      a {
        margin: -4px;
        border-radius: 4px;
        padding: 4px;
        height: inherit;
        line-height: inherit;
      }
      .info-button.website-link {
        top: 0;
      }
    }
  }
  .environments-list.search-results {
    .environment-row ~ .environment-row {
      &.active,
      &.inactive {
        border-top: none;
      }
    }
  }
  &.partial {
    max-height: 706px;
    overflow: hidden;
    padding: 4px 4px 0;
    margin: -16px -4px 0;
  }
  @media (max-width: 1130px) {
    li.root li.environment-row {
      padding-left: 32px !important;
      .vertical-line:after {
        left: 43px !important;
        top: 38px !important;
        height: calc(100% - 44px);
      }
      .environment-row-item {
        margin-bottom: 16px;
      }
      li.environment-row {
        padding-left: 24px !important;
        .vertical-line:after {
          left: 35px !important;
          top: 34px !important;
          height: calc(100% - 40px);
        }
        .horizontal-line:after {
          left: 12px;
          width: 14px;
        }
      }
    }
  }
`;

export const EnvironmentsMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  padding: 0;
  margin: 0 -20px -20px;
  clear: both;
  .grid {
    margin: 0;
    border-top: 1px solid #eee;
    > div {
      padding: 15px;
      width: 100%;
      margin: 0;
    }
    a {
      white-space: nowrap;
      color: ${props => props.theme.links};
      ${semiBoldAlias};
    }
  }
  .is-root.inactive,
  &.global-inactive .inactive,
  &.global-expanded .children {
    display: block;
  }
`;

export const SortWrapper = styled.div`
  justify-content: space-between;
  margin: -32px -32px 0;
  padding: 0 32px 8px;
  color: ${props => props.theme.sectionText};
  border-bottom: 1px solid ${props => props.theme.sectionBorder};
  display: none;
  @media (min-width: 1025px) {
    display: flex;
  }
  a {
    color: ${props => props.theme.sectionText};
    text-decoration: none;
    display: flex;
    align-items: center;
    label {
      margin: 0;
    }
    &:focus {
      outline: none;
      label {
        color: ${props => props.theme.links};
      }
    }
  }
  svg {
    width: 24px;
    > g > g {
      fill: ${props => props.theme.primary};
    }
  }
  .descend svg {
    transform: rotate(180deg);
  }
  .label {
    cursor: pointer;
    &.active {
      padding-right: 0;
    }
    &.label-name {
      width: 100%;
    }
    &.label-updated {
      padding-right: 0;
      display: flex;
      min-width: 76px;
      @media (min-width: 1280px) {
        min-width: 120px;
      }
    }
    &.label-url {
      min-width: 28px;
      margin: 0;
      display: flex;
      align-items: center;
      @media (min-width: 600px) {
        min-width: 40px;
      }
      @media (min-width: 1025px) {
        min-width: 60px;
      }
      @media (min-width: 1280px) {
        min-width: 96px;
      }
    }
  }
`;
