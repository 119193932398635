import styled from "styled-components";
import { themeHelpers } from "Libs/theme";

export default styled.nav`
  position: absolute;
  background: ${props => themeHelpers(props.theme, "dropdownColor")};
  display: ${props => (props.isOpen ? "inline-block" : "none")};
  left: 24px;
  top: 100%;
  margin-top: 4px;
  font-size: 12px;
  z-index: 10;
  box-shadow: ${props => props.theme.menuDropShadow};
  border-radius: 2px;
  min-width: 300px;
  width: calc(100vw - 48px);
  ul,
  ol {
    margin: 0;
    padding: 0;
    li {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }
  .dropdown-links {
    max-height: 332px;
    .rcs-inner-container,
    .no-scroll {
      padding: 8px;
      box-sizing: border-box;
    }
  }
  a {
    color: ${props => props.theme.text};
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    span {
      color: ${props => props.theme.text};
      font-size: 14px;
      white-space: nowrap;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &:hover {
      background: ${props => themeHelpers(props.theme, "linkHover")};
      border-radius: 4px;
    }
    &:focus {
      outline: none;
      border: 1px solid #1664ff;
      border-radius: 4px;
      box-shadow: 0 0 2px #1664ff;
      margin: -1px;
    }
    &.active,
    &:active {
      background: transparent;
      border: none;
      box-shadow: none;
      margin: 0;
      span {
        color: ${props => props.theme.links};
      }
    }
  }
  .show-all {
    color: ${props => props.theme.links};
    border-top: 1px solid ${props => props.theme.sectionBorder};
    line-height: 65px;
    margin-top: 15px;
    cursor: pointer;
  }

  @media (min-width: 768px) {
    position: absolute;
    width: ${props => props.width || "auto"};
    max-height: 388px;
    width: auto;
    left: 0;
  }
`;
