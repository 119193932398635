import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import Highlighter from "react-highlight-words";
import momentjs from "moment";

import { capitalize, getEnvironmentURI } from "Libs/utils";
import IconEnvBranch from "Components/icons/IconEnvBranch";
import IconEnvMaster from "Components/icons/IconEnvMaster";
import IconLink from "Components/icons/IconLink";
import WingsIcon from "Components/icons/WingsIcon";
import InProgressStateIcon from "Components/icons/InProgressStateIcon";

import ActionDropdown from "Components/ActionDropdown";
import AccessibleTooltip from "Components/AccessibleTooltip";

import EnvironmentRoutes from "Containers/EnvironmentRoutes";
import { DOCS_DEDICATED_CLUSTER_URL } from "Constants/documentationUrls";

import * as S from "./EnvironmentListBar.styles";

const EnvironmentListBar = ({
  className,
  list,
  node,
  onClick,
  organizationId,
  titleFilter
}) => {
  const intl = useIntl();
  const theme = useContext(ThemeContext);
  const hasChildren = node.children?.length > 0;

  const handleClick = e => {
    e.preventDefault();
    onClick();
  };

  return (
    <S.ListBarWrapper className="environment-line">
      <Link
        onClick={handleClick}
        tabIndex="0"
        to={getEnvironmentURI(
          organizationId,
          node.projectId,
          node.environmentId
        )}
        id={`environment-listbar-link-${node.environmentId}`}
      >
        <S.ListBar className={`environment-listbar ${className}`}>
          <S.ListBarLeft className="list-bar-left">
            <span
              className={`icon${node.isRoot ? " root" : ""}${
                node.status === "inactive" ? " inactive" : ""
              }`}
            >
              {node.isRoot ? (
                <AccessibleTooltip
                  tooltipProps={{
                    id: `${node.environmentId}-root`,
                    children: (
                      <React.Fragment>
                        {node.status === "inactive" && "Inactive "}Main branch
                      </React.Fragment>
                    )
                  }}
                >
                  <span className="icon-wrapper">
                    <IconEnvMaster />
                  </span>
                </AccessibleTooltip>
              ) : (
                <>
                  {hasChildren ? (
                    <AccessibleTooltip
                      className="parent"
                      tooltipProps={{
                        id: `${node.environmentId}-children`,
                        children:
                          node.status === "inactive"
                            ? "Inactive parent branch"
                            : "Parent branch"
                      }}
                    >
                      <span className="icon-wrapper">
                        <IconEnvBranch color={theme?.iconBg} />
                      </span>
                    </AccessibleTooltip>
                  ) : (
                    <>
                      {node.status === "inactive" && (
                        <AccessibleTooltip
                          className="icon-wrapper"
                          tooltipProps={{
                            id: `${node.environmentId}-inactive`,
                            children: "Inactive child branch"
                          }}
                        >
                          <IconEnvBranch color={theme?.iconBg} />
                        </AccessibleTooltip>
                      )}
                      {node.status !== "inactive" && (
                        <IconEnvBranch color={theme?.iconBg} />
                      )}
                    </>
                  )}
                </>
              )}
            </span>
            <span className="title">
              <AccessibleTooltip
                tooltipProps={{
                  id: `${node.environmentId}-root`,
                  disable: !node.type,
                  children: (
                    <React.Fragment>
                      {capitalize(node.type || "")} environment
                    </React.Fragment>
                  )
                }}
              >
                <Highlighter
                  highlightClassName="blue"
                  searchWords={[titleFilter]}
                  textToHighlight={node.title ? node.title : ""}
                  autoEscape={true}
                  activeIndex={-1}
                  caseSensitive={false}
                  className="environment-item"
                />
              </AccessibleTooltip>
            </span>
          </S.ListBarLeft>
          <S.ListBarRight className="list-bar-right">
            {list && (
              <AccessibleTooltip
                className="moment"
                tooltipProps={{
                  id: `${node.environmentId}-moment`,
                  children: momentjs(node.created_at).format(
                    "h:mm a on MMMM D, YYYY"
                  )
                }}
              >
                {momentjs(node.created_at).fromNow()}
              </AccessibleTooltip>
            )}
            {node.isDirty && <InProgressStateIcon />}
          </S.ListBarRight>
        </S.ListBar>
      </Link>
      {node.status !== "inactive" && !node.isMock ? (
        <ActionDropdown
          id={`environment-list-${node.environmentId}-urls`}
          ariaLabel={intl.formatMessage(
            { id: "environments.links_tooltip" },
            {
              title: node.title
            }
          )}
          className="website-link"
          withArrow={false}
          icon={<IconLink />}
          withPortal={true}
        >
          <EnvironmentRoutes
            projectId={node.projectId}
            organizationId={organizationId}
            environmentId={node.environmentId}
            routeMaxNumber={5}
          />
        </ActionDropdown>
      ) : (
        <div />
      )}
      {node.deployment_target !== "local" && (
        <S.WingsDialog
          icon={<WingsIcon />}
          title={intl.formatMessage({ id: "dedicatedenv.dialog.title" })}
          text={intl.formatMessage({ id: "dedicatedenv.dialog.text" })}
          linkText={intl.formatMessage({ id: "learnmore" })}
          to={DOCS_DEDICATED_CLUSTER_URL}
          align="center"
        />
      )}
    </S.ListBarWrapper>
  );
};

EnvironmentListBar.propTypes = {
  className: PropTypes.string,
  list: PropTypes.bool,
  node: PropTypes.object,
  onClick: PropTypes.func,
  organizationId: PropTypes.string,
  titleFilter: PropTypes.string
};

export default EnvironmentListBar;
