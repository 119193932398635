import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";

import withReducers from "Hocs/withReducers";
import { hasSafeRole } from "Libs/utils";
import { getMe } from "Reducers/app";

import { ModalTrial } from "Components/";
import ButtonLink from "Components/ButtonLink";

import {
  getPaymentSource,
  paymentSourceLoadingSelector,
  paymentSourceSelector
} from "Reducers/paymentSource";
import {
  organizationPaymentSourceLoadingSelector,
  organizationPaymentSourceSelector,
  getOrganizationPaymentSource
} from "Reducers/organization/paymentSource";
import {
  organizationProfileSelector,
  getOrganizationProfile
} from "Reducers/organization/profile";
import { organizationByDescriptionIdSelector } from "Reducers/organization";

const NewProjectButton = ({ linkType, organizationId, user }) => {
  const [isModalOpen, toggleModal] = useState(false);
  const [estimate, setEstimate] = useState();

  const dispatch = useDispatch();
  const intl = useIntl();
  const ref = useRef();

  const loadingPaymentSource = useSelector(state => {
    let isLoading = false;
    if (process.env.ENABLE_ORGANIZATION) {
      isLoading = organizationPaymentSourceLoadingSelector(state);
    } else {
      isLoading = paymentSourceLoadingSelector(state);
    }

    return isLoading && isLoading !== "idle";
  });

  const hasPaymentSource = useSelector(state => {
    let ps;
    if (process.env.ENABLE_ORGANIZATION) {
      ps = organizationPaymentSourceSelector(state, { organizationId });
    } else {
      ps = paymentSourceSelector(state);
    }
    return !!ps?.type;
  });

  const organizationProfile = useSelector(state =>
    organizationProfileSelector(state, { organizationId })
  );

  const organization = useSelector(state =>
    organizationByDescriptionIdSelector(state, {
      organizationDescriptionId: organizationId
    })
  );

  const getEstimation = async () => {
    if (estimate !== undefined) return;
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    if (process.env.ENABLE_ORGANIZATION && organization) {
      await client
        .getOrganizationSubscriptionEstimate(
          organization?.id,
          "trial/development",
          "1024",
          "3",
          "1",
          undefined,
          "complex"
        )
        .then(estimation => setEstimate(estimation));
    } else {
      await client
        .getSubscriptionEstimate(
          "trial/development",
          "1024",
          "3",
          "1",
          undefined,
          "complex"
        )
        .then(estimation => setEstimate(estimation));
    }
    if (user.data?.current_trial === undefined) dispatch(getMe(true));
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);

    return () =>
      document.removeEventListener("click", handleClickOutside, false);
  }, []);

  useEffect(
    () => {
      if (
        !process.env.ENABLE_ORGANIZATION &&
        user &&
        !hasSafeRole(user.roles)
      ) {
        if (user.trial) getEstimation();
        if (!loadingPaymentSource) dispatch(getPaymentSource());
      } else if (
        process.env.ENABLE_ORGANIZATION &&
        organizationId &&
        organizationProfile &&
        user &&
        !hasSafeRole(user.roles)
      ) {
        if (
          organizationProfile?.data?.current_trial?.created ||
          organizationProfile?.data?.current_trial?.active
        )
          if (organization) getEstimation();
        if (!loadingPaymentSource)
          dispatch(getOrganizationPaymentSource({ organizationId }));
      }
    },
    [user?.id, organizationProfile, organization]
  );

  useEffect(
    () => {
      if (
        process.env.ENABLE_ORGANIZATION &&
        organizationId &&
        organization &&
        !organizationProfile
      ) {
        dispatch(getOrganizationProfile({ organizationId }));
      }
    },
    [organizationId, organization]
  );

  const handleClickOutside = e => {
    if (!ref.current?.contains(e.target)) {
      toggleModal(false);
    }
  };

  const openModal = e => {
    e?.preventDefault();
    toggleModal(true);
  };

  const checkRights = () => {
    if (
      !process.env.ENABLE_BILLING ||
      (process.env.ENABLE_ORGANIZATION && !organizationId)
    ) {
      return [true, null];
    }

    let { data, trial } = user;

    if (process.env.ENABLE_ORGANIZATION) {
      data = organizationProfile?.data;
      trial =
        organizationProfile?.data?.current_trial?.created ||
        organizationProfile?.data?.current_trial?.active;
    }

    if (hasSafeRole(user.roles) || hasPaymentSource) return [true, null];

    if (!trial) {
      if (hasPaymentSource === false) return [false, "very_hard"];
    } else {
      const { current_trial } = data;
      if (
        (current_trial === null || !current_trial?.active) &&
        !hasPaymentSource
      )
        return [false, "hard"];

      if (current_trial?.active && estimate?.trial?.allowed)
        return [true, null];

      if (
        current_trial?.active &&
        moment(current_trial?.expiration).isAfter(moment()) &&
        !estimate?.trial?.allowed
      )
        return [false, "soft"];

      if (
        current_trial?.active &&
        moment(current_trial?.expiration).isSameOrBefore(moment()) &&
        !estimate?.trial?.allowed
      )
        return [false, "hard"];

      if (!current_trial?.active) return [false, "hard"];
    }
    return [null, null];
  };

  if (
    !user ||
    (process.env.ENABLE_ORGANIZATION && organizationId && !organizationProfile)
  )
    return false;

  const [canCreate, kindModal] = checkRights();
  if (canCreate === null && kindModal === null) return false;

  let external = false;
  let buttonUrl = `/${
    organizationId ? organizationId : "projects"
  }/create-project`;

  if (process.env.CUSTOM_PROJECT_CREATION_URL) {
    buttonUrl = process.env.CUSTOM_PROJECT_CREATION_URL;
    external = true;
  }

  return (
    <div ref={ref}>
      {kindModal && (
        <ModalTrial
          isOpen={isModalOpen}
          organizationId={organizationId}
          closeModal={() => toggleModal(false)}
          kind={kindModal}
        />
      )}

      <ButtonLink
        id="create_project"
        className="create_project"
        addLink={linkType === "nav"}
        onClick={!canCreate ? openModal : null}
        to={buttonUrl}
        external={external}
        text={intl.formatMessage({ id: "create_project" })}
      />
    </div>
  );
};

NewProjectButton.propTypes = {
  linkType: PropTypes.string,
  organizationId: PropTypes.string,
  user: PropTypes.object
};

export default withReducers({
  paymentSource: () => import("Reducers/paymentSource"),
  organizationPaymentSource: () =>
    import("Reducers/organization/paymentSource"),
  organizationProfile: () => import("Reducers/organization/profile")
})(NewProjectButton);
