import localForage from "localforage";

const FORAGE_KEY = "environment-tree-filter-settings";

export const persistSettings = async (pid, data) => {
  const filters = (await localForage.getItem(FORAGE_KEY)) || {};
  const current = filters[pid];
  const updated = {
    ...current,
    ...data
  };

  filters[pid] = updated;

  await localForage.setItem(FORAGE_KEY, filters);

  return updated;
};

export const loadSettings = async pid => {
  const filters = (await localForage.getItem(FORAGE_KEY)) || {};
  return filters[pid] || {};
};
