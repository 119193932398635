import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";

export default styled.h4`
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  color: ${props => props.theme.sectionHeading};
  ${semiBoldAlias};
`;
