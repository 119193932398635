import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const PlatformIcon = ({ color = "#fff" }) => {
  const uid = useUniqueId();

  return (
    <div>
      <svg
        width="28px"
        height="28px"
        viewBox="0 0 28 28"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-labelledby={`platform-sh-icon-${uid}`}
      >
        <title id={`platform-sh-icon-${uid}`}>Platform.sh icon</title>
        <defs>
          <polygon
            id={`path-1-${uid}`}
            points="0 0 27.6677333 0 27.6677333 11.0021333 0 11.0021333"
          />
          <polygon
            id={`path-3-${uid}`}
            points="0 0.0373333333 27.6677333 0.0373333333 27.6677333 3.7744 0 3.7744"
          />
          <polygon
            id={`path-5-${uid}`}
            points="0 0.365866667 27.6677333 0.365866667 27.6677333 6.01066667 0 6.01066667"
          />
        </defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-61.000000, -43.000000)">
            <g transform="translate(61.000000, 43.000000)">
              <g>
                <mask id={`mask-2-${uid}`} fill="white">
                  <use xlinkHref={`#path-1-${uid}`} />
                </mask>
                <g />
                <polygon
                  fill={color}
                  mask={`url(#mask-2-${uid})`}
                  points="-1.86666667 12.8688 29.5344 12.8688 29.5344 -1.86666667 -1.86666667 -1.86666667"
                />
              </g>
              <g transform="translate(0.000000, 23.893333)">
                <mask id={`mask-4-${uid}`} fill="white">
                  <use xlinkHref={`#path-3-${uid}`} />
                </mask>
                <polygon
                  fill={color}
                  mask={`url(#mask-4-${uid})`}
                  points="-1.86666667 5.64106667 29.5344 5.64106667 29.5344 -1.82933333 -1.86666667 -1.82933333"
                />
              </g>
              <g transform="translate(0.000000, 14.186667)">
                <mask id={`mask-6-${uid}`} fill="white">
                  <use xlinkHref={`#path-5-${uid}`} />
                </mask>
                <polygon
                  fill={color}
                  mask={`url(#mask-6-${uid})`}
                  points="-1.86666667 7.87733333 29.5344 7.87733333 29.5344 -1.5008 -1.86666667 -1.5008"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

PlatformIcon.propTypes = {
  color: PropTypes.string
};

export default PlatformIcon;
