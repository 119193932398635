import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { organizationsMemberOrOwnerOfSelector } from "Reducers/organization";
import useMediaQuery from "Hooks/useMediaQuery";
import AnimatedHeaderWithArrow from "Components/AnimatedHeaderWithArrow";
import { ORGANIZATION_ID_FIELD } from "Constants/constants";

import * as S from "./styles";
import OrganizationMenu from "./OrganizationMenu";

const OrganizationsSwitcher = ({ organizationId }) => {
  const [isMenuOpen, openMenu] = useState(false);
  const match = useMediaQuery("(min-width: 764px)");
  const history = useHistory();

  const intl = useIntl();

  const toggleMenu = () => {
    openMenu(!isMenuOpen);
  };

  const organizations = useSelector(organizationsMemberOrOwnerOfSelector);
  const organizationsArray = organizations?.valueSeq();

  const selectedOrganization = organizations.find(
    o => o.name === organizationId
  );

  return (
    <S.Wrapper>
      {organizationsArray?.size || organizationId ? (
        match ? (
          <>
            <AnimatedHeaderWithArrow
              prefixId="navbar-projects"
              isOpen={isMenuOpen}
              onHeaderClick={toggleMenu}
              arrowClick={true}
              onArrowClick={toggleMenu}
              animate={false}
              ariaLinkLabel="All projects"
              ariaToggleLabel="Open projects"
              className="navbar-link"
              headerPadding="0 8px"
              closeDropDown={() => openMenu(false)}
            >
              <S.CurrentOrganization className="text">
                <span className="active" />
                {selectedOrganization?.label ||
                  organizationId ||
                  intl.formatMessage({ id: "all_projects" })}
              </S.CurrentOrganization>
            </AnimatedHeaderWithArrow>
            <OrganizationMenu
              prefixId="navbar-orgnizations"
              isOpen={isMenuOpen}
              onClick={(e, id) => {
                e.stopPropagation();
                if (!id) {
                  return history.push("/");
                }

                const organization = organizations.find(o => o.id === id);
                const organizationDescriptionId =
                  organization[ORGANIZATION_ID_FIELD];
                history.push(`/${organizationDescriptionId}`);
              }}
              onKeyUp={e => e.keyCode === 27 && isMenuOpen && toggleMenu()}
              objects={organizationsArray}
              allLabel={intl.formatMessage({ id: "all_projects" })}
              headerText={intl.formatMessage({ id: "my_organizations" })}
              toggleMenu={toggleMenu}
              aria-label="Organizations"
              selectedId={selectedOrganization?.id || ""}
              urlPrefix=""
              hideSearchInput
            />
          </>
        ) : null
      ) : null}
    </S.Wrapper>
  );
};

OrganizationsSwitcher.propTypes = {
  organizations: PropTypes.object,
  pushAction: PropTypes.func,
  organizationId: PropTypes.string
};

export default OrganizationsSwitcher;
