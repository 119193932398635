import { FC, InputHTMLAttributes } from "react";

import * as S from "./Checkbox.styles";

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  /** Renders the error state if false */
  isValid?: boolean;
}

const Checkbox: FC<Props> = props => <S.Layout data-testid="root" {...props} />;

Checkbox.defaultProps = {
  isValid: true
};

export default Checkbox;
