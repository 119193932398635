import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const RightArt = ({ type }) => {
  const uid = useUniqueId();

  let color1 = "white",
    color2 = "white";

  if (type === "info") {
    color1 = "#FFBDBB";
    color2 = "#6D144F";
  }
  return (
    <svg
      width="83"
      height="26"
      viewBox="0 0 83 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={`right-art-${uid}`}
    >
      <title id={`right-art-${uid}`}>Right art</title>
      <path
        d="M43 0L44.3506 3.64943L48 5L44.3506 6.35057L43 10L41.6494 6.35057L38 5L41.6494 3.64943L43 0Z"
        fill={color2}
      />
      <path
        d="M23 10L25.1609 15.8391L31 18L25.1609 20.1609L23 26L20.8391 20.1609L15 18L20.8391 15.8391L23 10Z"
        fill={color1}
      />
      <circle
        r="1.5"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 50.5 19.5)"
        fill={color1}
      />
      <circle
        r="1.5"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 81.5 10.5)"
        fill={color1}
      />
      <circle
        r="1.5"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 1.5 9.5)"
        fill={color2}
      />
    </svg>
  );
};

RightArt.propTypes = {
  type: PropTypes.string
};

export default RightArt;
