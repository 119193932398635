import localForage from "localforage";
import legacyThemes from "./legacyThemes";

import baseTheme from "./theme";

import {
  setCurrentColorMode,
  defaultTheme,
  createStyledTheme,
  injectTheme
} from "@platformsh/ui-kit";

async function getColorMode() {
  const params = new URLSearchParams(window.location.search);
  const colorModeFromCache = await localForage.getItem("ui_colorscheme");

  const colorMode =
    params.get("theme") || params.get("mode") || colorModeFromCache || "light";

  return colorMode;
}

export async function addUIKitToTheme(themePromise) {
  const theme = await themePromise;
  const uikitTheme = createStyledTheme(baseTheme);
  return { ...theme, ...uikitTheme };
}

// setupUIKitTheme calls getColorMode to see which mode the app
// needs to use, then selects that mode from the default theme,
// and injects its color values as CSS variables into the document
// UI-Kit uses those CSS variables to theme its components
export async function setupUIKitTheme() {
  let theme = baseTheme;
  let mode = await getColorMode();

  // contrast was a mode in console, but in UI-Kit is a theme
  // this will do it for now, but we need to take contrast
  // apart from mode, in order to be able to have a light/dark contrast theme
  // Until we have a contrast theme we alias contrastTheme to defaultTheme
  const contrastTheme = defaultTheme;

  if (mode === "contrast") {
    theme = contrastTheme;
    mode = "light";
  }

  injectTheme(theme);
  setCurrentColorMode(mode);
}

export async function getLegacyTheme() {
  const mode = await getColorMode();
  return legacyThemes[mode];
}
