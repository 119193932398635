import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { themeHelpers } from "Libs/theme";
import Favicon from "react-favicon";
import { Link } from "react-router-dom";

import VendorIcon from "Components/VendorIcon";
import PlatformIcon from "Icons/PlatformIcon";

export const PlatformBrandingMessage = styled.h2`
  color: white;
  line-height: 1;
  font-size: 12px;
  font-weight: 700;
  margin: -4px 0;
  padding: 0;
  @media (min-width: 768px) {
    position: relative;
    line-height: 40px;
    padding-right: 16px;
    ${props =>
      props.variant !== "standalone" &&
      css`
        &:after {
          content: "";
          display: block;
          position: absolute;
          top: 8px;
          width: 1px;
          height: 24px;
          right: 0;
          background: ${props => props.theme.ebony["400"]};
        }
      `}}
`;

const NavBarLogo = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  height: 36px;
  width: auto;
  display: inline-flex;
  justify-content: center;
  margin: -4px;
  padding: 4px;
  &:hover {
    background: rgba(255, 255, 255, 0.16);
    border-radius: 2px;
  }
  &:focus {
    margin: -5px;
    outline: none;
    border: 1px solid ${props => themeHelpers(props.theme, "buttonHover")};
    box-shadow: 0 0 2px ${props => themeHelpers(props.theme, "buttonHover")};
  }
  > div {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    svg {
      max-height: 100%;
      max-width: 100%;
    }
  }
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-wrap: wrap;
  @media (min-width: 768px) {
    align-items: flex-start;
    flex-direction: row;
  }
`;

const VendorLogo = props => {
  let VendorFavicon;
  let vendorLogo;
  if (process.env.VENDOR_URL) {
    VendorFavicon = require("Vendor_resources/favicon.ico");
    vendorLogo = require("Vendor_resources/images/logo-ui.svg");
  }

  return (
    <Wrap>
      {VendorFavicon && <Favicon url={VendorFavicon} />}
      {vendorLogo && (
        <Link id="test-logo" to={"/"} aria-label="Logo">
          <VendorIcon width={process.env.CUSTOM_LOGO_WIDTH} path={vendorLogo} />
        </Link>
      )}
      {process.env.CUSTOM_BRANDING_MESSAGE && (
        <PlatformBrandingMessage variant={props.variant}>
          {process.env.CUSTOM_BRANDING_MESSAGE}
        </PlatformBrandingMessage>
      )}
    </Wrap>
  );
};

VendorLogo.propTypes = {
  variant: PropTypes.string
};

const Logo = () => (
  <NavBarLogo className="navbar-logo" id="test-logo" to={"/"} aria-label="Logo">
    <PlatformIcon alt="Console" color="#ffffff" size={35} />
  </NavBarLogo>
);

let exportable = Logo;

if (process.env.VENDOR_URL) {
  exportable = VendorLogo;
}

export default exportable;
