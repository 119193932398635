import React from "react";
import { createPortal } from "react-dom";

const Portal = ({ children, parent }) => {
  return createPortal(
    <>{children}</>,
    parent || document.getElementById("portal-root")
  );
};

export default Portal;
