import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

import LoadingIcon from "Icons/LoadingIcon";

import { LoadingWrapper, MessageWrapper } from "./styles";

const Loading = ({ iconOnly, loadType, className }) => (
  <LoadingWrapper iconOnly={iconOnly} className={`loading ${className}`}>
    <LoadingIcon />
    {!iconOnly && (
      <MessageWrapper>
        <FormattedMessage id="loading" />
        {loadType ? " " + loadType : ""}...
      </MessageWrapper>
    )}
  </LoadingWrapper>
);

Loading.propTypes = {
  className: PropTypes.string,
  iconOnly: PropTypes.bool,
  loadType: PropTypes.string
};

export default Loading;
