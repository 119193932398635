import styled from "styled-components";

export default styled.h2`
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  margin: 0 0 32px;
  color: ${props => props.theme.sectionHeading};
  &:first-letter {
    text-transform: capitalize;
  }
`;
