import styled from "styled-components";

export const Wrapper = styled.div`
  .more .tooltip {
    width: auto;
  }
  .tooltip > section {
    padding: 10px 16px;
  }
`;

export const Button = styled.button`
  display: block;
  width: 100% !important;
  color: ${props => props.theme.textLight};
  text-decoration: none;
  font-size: 14px;
  line-height: 19px;
  margin: 5px 0 0 0;
  background: none;
  text-align: left;
  &:hover {
    color: ${props => props.theme.links};
    background: none !important;
    cursor: pointer;
    margin: 5px 0 0 0 !important;
  }
`;

export const IdProject = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.textLight};
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    color: ${props => props.theme.links};
  }
`;

export const Icon = styled.div`
  display: flex;
  margin: 0 0 0 16px;
  svg path {
    fill: ${props => props.theme.iconHoverColor};
  }
`;
