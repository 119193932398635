import styled from "styled-components";

const ContentPaneLayout = styled.form`
  width: 100%;

  .field,
  .toggle-field {
    margin-bottom: 32px;
  }
  .toggle-field .field {
    margin-bottom: 0;
  }
  .copyable-area {
    margin-bottom: 32px;
  }
  h4 {
    display: flex;
    align-items: center;
  }
  p {
    color: ${props => props.theme.sectionText};
    font-size: 15px;
    margin: 0 0 24px 0;
    line-height: 24px;
  }
`;

export default ContentPaneLayout;
