export enum Variant {
  primary = "primary",
  secondary = "secondary",
  tertiary = "tertiary",
  link = "link"
}

export enum Content {
  icon = "icon",
  mixed = "mixed",
  text = "text"
}

export type Variants = keyof typeof Variant;
export type Contents = keyof typeof Content;
