import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Button from "Components/Button";
import Error from "Components/Error";
import Heading2 from "Components/styleguide/Heading2";
import InputField from "Components/fields/InputField";
import ListGroup from "Components/ListGroup/ListGroup";
import ProjectLayout from "../../../../organization/pages/project/components/ProjectLayout/ProjectLayout";

import * as S from "./styles";
import NavBar from "Containers/NavBar";
import PageLayout from "Components/PageLayout";
import { ADMIN_ROLES } from "Constants/constants";
import { useHistory, useLocation } from "react-router-dom";
import { Layout } from "../styles";

const Page = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const [appUrl, setAppUrl] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [apps, setApps] = useState({ routeEnabled: true, installed: {} });
  const [error, setError] = useState("");

  const me = useSelector(state => state.app?.get("me"));

  useEffect(
    () => {
      if (
        me &&
        !me
          .get("roles")
          .toArray()
          .some(role => ADMIN_ROLES.includes(role))
      ) {
        return (window.location.href = "/");
      }

      const storedApps = localStorage.getItem("plugin-apps");
      if (storedApps) {
        let appsObject = JSON.parse(storedApps);
        appsObject.routeEnabled = true;
        setApps(appsObject);
        localStorage.setItem("plugin-apps", JSON.stringify(appsObject));
      } else {
        localStorage.setItem("plugin-apps", JSON.stringify(apps));
      }
    },
    [me]
  );
  const installApp = async () => {
    setError(false);
    setLoading(true);
    try {
      const response = await fetch(appUrl);
      if (response.status !== 200) {
        setError(`Can't get the manifest...`);
        setLoading(false);
        return false;
      }
      const manifest = await response.json();
      apps.installed[manifest.id] = manifest;
      localStorage.setItem("plugin-apps", JSON.stringify(apps));
      setAppUrl("");
      setApps(apps);
      setError(false);
    } catch (err) {
      setError(err.toString());
    }
    setLoading(false);
  };
  const removeApp = appId => {
    const {
      routeEnabled,
      // eslint-disable-next-line no-unused-vars
      installed: { [appId]: value, ...keptApps }
    } = apps;
    localStorage.setItem(
      "plugin-apps",
      JSON.stringify({ routeEnabled, installed: keptApps })
    );
    setApps({ routeEnabled, installed: keptApps });
  };

  if (!me) {
    return false;
  }

  return (
    <>
      <NavBar
        push={push}
        role="navigation"
        currentPathName={pathname}
        fixed={true}
      />
      <PageLayout>
        <Layout>
          <Heading2>App manager</Heading2>
          <ProjectLayout>
            <ListGroup>
              <S.Wrapper>
                <InputField
                  id="manifest_url"
                  name="manifest_url"
                  label="Manifest URL"
                  placeholder="App manifest URL"
                  onChange={e => setAppUrl(e.target.value)}
                  value={appUrl}
                  width="100%"
                />
                {isLoading ? (
                  <span>Loading</span>
                ) : (
                  <Button
                    id="install_app"
                    type="submit"
                    className="primary"
                    onClick={installApp}
                    aria-label="Install"
                    disabled={!appUrl}
                  >
                    Install
                  </Button>
                )}
              </S.Wrapper>
              <S.Wrapper>
                {error && <Error>{error}</Error>}
                {Object.keys(apps.installed).map(appId => (
                  <S.AppItem key={appId}>
                    <span>{apps.installed[appId]?.name || appId}</span>
                    <Button onClick={() => removeApp(appId)}>Remove</Button>
                  </S.AppItem>
                ))}
              </S.Wrapper>
            </ListGroup>
          </ProjectLayout>
        </Layout>
      </PageLayout>
    </>
  );
};

export default Page;
