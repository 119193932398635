import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fromJS, Map } from "immutable";
import localForage from "localforage";

import client, { entities } from "Libs/platform";
import { getOwnerInfoName, isJson, isSubscriptionOwner } from "Libs/utils";

import { canCreateProjectOrganizationsSelector } from "Reducers/organization";

export const loadSubscriptions = createAsyncThunk(
  "app/subscriptions/load",
  async () => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;
    const subscriptions = await client.getSubscriptions();
    return subscriptions;
  }
);

export const loadSubscription = createAsyncThunk(
  "app/subscription/load",
  async ({ organizationId, projectId, id }, { getState }) => {
    let subscription = getState().subscription.getIn([
      "data",
      organizationId,
      projectId
    ]);
    if (!subscription) {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;
      subscription = await client.getSubscription(id);
    }

    const organizations = getState().organization?.get("data", Map());

    return {
      subscription,
      userName: getOwnerInfoName(subscription, organizations.toJS())
    };
  }
);

export const addSubcription = createAsyncThunk(
  "app/subscription/add",
  async ({ config }, { getState, rejectWithValue }) => {
    // If the user as no organization, we need to fallback to the old accounts API
    const organizations = canCreateProjectOrganizationsSelector(getState());
    try {
      if (organizations.size && process.env.ENABLE_ORGANIZATION) {
        return await client.createOrganizationSubscription(config);
      }
      return await client.createSubscription(config);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateSubscription = createAsyncThunk(
  "app/subscription/update",
  async ({ updates, subscription }, { getState }) => {
    const response = await subscription.update({ ...updates });
    const updatedSubscription = new entities.Subscription(response.data);

    const organizations = getState().organization.get("data", Map());
    return {
      subscription: updatedSubscription,
      projectId: updatedSubscription.data.project_id,
      userName: getOwnerInfoName(updatedSubscription, organizations.toJS())
    };
  }
);

export const deleteSubscription = createAsyncThunk(
  "app/subscription/delete",
  async ({ subscription }, { getState }) => {
    const organizations = getState().organization.get("data", Map());

    await subscription.delete();

    const deletedProjectIds =
      (await localForage.getItem("deletedProjectIds")) || [];
    const updatedDeletedProjectIds = deletedProjectIds.concat([
      subscription.project_id
    ]);
    await localForage.setItem("deletedProjectIds", updatedDeletedProjectIds);

    return {
      subscription,
      projectId: subscription.project_id,
      userName: getOwnerInfoName(subscription, organizations)
    };
  }
);

const setError = (state, action) => {
  let message = action.error.message || action.error.detail;
  if (isJson(action.error.message)) {
    const errors = JSON.parse(action.error.message);
    message = errors.detail;
    if (errors?.detail?.errors?.length) message = errors.detail.errors[0];
  }

  return state
    .set("errors", message)
    .set("loading", false)
    .set("status", "rejected");
};

const subscriptions = createSlice({
  name: "subscriptions",
  initialState: Map({ data: Map() }),
  extraReducers: {
    // LOAD SUBSCRIPTIONS
    [loadSubscriptions.pending]: state => state.set("loading", true),
    [loadSubscriptions.fulfilled]: (state, { meta, payload }) => {
      const { organizationId } = meta.arg;
      const oldest = fromJS(payload)
        .filter(s => isSubscriptionOwner(s, organizationId))
        .sort(
          (a, b) => new Date(a.data.created_at) < new Date(b.data.created_at)
        )
        .first();
      return state
        .set("loading", false)
        .setIn(
          ["data", organizationId],
          fromJS(
            payload.reduce((acc, cu) => {
              acc[cu.project_id] = cu;
              return acc;
            }, {})
          )
        )
        .setIn(
          ["projectIdBySubscriptionId"],
          fromJS(
            payload.reduce((acc, cu) => {
              acc[cu.id] = cu.project_id;
              return acc;
            }, {})
          )
        )
        .set("oldest", oldest);
    },
    [loadSubscriptions.rejected]: (state, action) => setError(state, action),

    // LOAD SUBSCRIPTION
    [loadSubscription.pending]: state => state.set("loading", true),
    [loadSubscription.fulfilled]: (state, { meta, payload }) => {
      const { projectId } = meta.arg;
      const { subscription, userName } = payload;
      return state
        .set("loading", false)
        .setIn(
          ["data", userName, projectId || subscription.project_id],
          fromJS(subscription)
        )
        .setIn(
          ["projectIdBySubscriptionId", subscription.id],
          projectId || subscription.project_id
        );
    },
    [loadSubscription.rejected]: (state, action) => setError(state, action),

    // ADD SUBSCRIPTION
    [addSubcription.pending]: state =>
      state
        .set("loading", true)
        .set("status", "pending")
        .delete("errors"),
    [addSubcription.fulfilled]: (state, { payload }) =>
      state
        .set("loading", false)
        .set("status", "added")
        .set("lastAdded", payload.data.id),
    [addSubcription.rejected]: (state, { payload }) => {
      const { detail, status, title } =
        typeof payload === "string" ? JSON.parse(payload) : payload;
      return state
        .set("errors", `${title}: ${detail}`)
        .set("httpStatus", status)
        .set("status", "rejected")
        .set("loading", false);
    },

    // UPDATE SUBSCRIPTION
    [updateSubscription.pending]: state =>
      state
        .set("loading", true)
        .set("status", "pending")
        .delete("errors"),
    [updateSubscription.fulfilled]: (state, { payload }) =>
      state
        .set("loading", false)
        .set("status", "updated")
        .setIn(
          ["data", payload.userName, payload.projectId],
          fromJS(payload.subscription)
        ),
    [updateSubscription.rejected]: (state, action) => setError(state, action),

    // DELETE SUBSCRIPTION
    [deleteSubscription.pending]: state =>
      state
        .set("loading", true)
        .set("status", "pending")
        .delete("errors"),
    [deleteSubscription.fulfilled]: (state, { payload }) =>
      state
        .set("loading", false)
        .set("status", "deleted")
        .removeIn(["data", payload.userName, payload.projectId]),
    [deleteSubscription.rejected]: () => location.replace(location.origin)
  }
});

export default subscriptions.reducer;

export const subscriptionSelector = (state, props) =>
  state.subscription?.getIn(["data", props.organizationId, props.projectId]) ||
  state.organizationSubscription?.getIn([
    "data",
    props.organizationId,
    props.id
  ]);
