import styled from "styled-components";

export default styled.h3`
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: ${props => props.theme.sectionHeading};
  &:first-letter {
    text-transform: uppercase;
  }
  &.settings-subheading {
    margin-top: 0;
  }
`;
