import React, { useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";

import * as S from "./Skeleton.styles";

const animationAPIAvailable =
  window.Animation &&
  window.KeyframeEffect &&
  typeof document.body.getAnimations === "function";

/**
 * The APIs used below are currently a working draft, even though are
 * implemented by many browsers, are not fully standard.
 *
 * On compatible browsers we can rely on the JS `HTMLElement.animate` function
 * but if this is not available, it should fall back to a standard animation.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Animation
 *
 */
const loadingAnimation = (() => {
  let animation;
  if (animationAPIAvailable) {
    animation = new Animation(
      new KeyframeEffect(null, [], {
        duration: 2000,
        easing: "linear",
        iterations: Infinity
      })
    );

    animation.play();
  }

  return animation;
})();

const Skeleton = ({ synchronize, children, ...props }) => {
  const shadow = useRef();
  const container = useRef();

  // Even if there's another call on the same element to HTMLElement.animate()
  // the previous animation has to be stoppped manually
  const cleanup = useCallback(
    () => {
      shadow.current
        ?.getAnimations({ subtree: true })
        .forEach(animation => animation.cancel());
    },
    [shadow]
  );

  useEffect(
    () => {
      if (!loadingAnimation) {
        return;
      }

      const $shadow = shadow.current;
      const rect = container.current.getBoundingClientRect();
      const start = synchronize
        ? `-${rect.left + S.SHADOW_WIDTH}px`
        : `-${S.SHADOW_WIDTH}px`;
      const end = synchronize
        ? document.documentElement.clientWidth - rect.left + S.SHADOW_WIDTH
        : rect.right;

      cleanup();

      const animation = new Animation(
        new KeyframeEffect(
          $shadow,
          [
            { transform: `translateX(${start}` },
            { transform: `translateX(${end}px)` }
          ],
          {
            duration: 2000,
            easing: "linear",
            iterations: Infinity
          }
        )
      );
      if (synchronize) {
        animation.currentTime = loadingAnimation.currentTime;
      }
      animation.play();

      return cleanup;
    },
    [shadow, container]
  );

  return (
    <S.Layout ref={container} {...props}>
      {children}
      <S.Shadow ref={shadow} fallbackAnimation={!loadingAnimation} />
    </S.Layout>
  );
};

Skeleton.propTypes = {
  synchronize: PropTypes.bool,
  children: PropTypes.node
};

export default Skeleton;
