import styled from "styled-components";

export default styled.h6`
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  color: ${props => props.theme.sectionHeading};
  margin: 0 0 16px;
  &:first-letter {
    text-transform: uppercase;
  }
`;
