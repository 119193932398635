// This is actually getting the types from styled.d.ts
// We override these in "../types/styled.d.ts"
import { StyledTheme, StyledThemeColors } from "styled-components";
import { Theme } from "../types/themes.types";

import { replaceLodash } from "../core/themeEngine";

/**
 * Converts a theme from our theme format to the styled components format
 * The resulting styled-components theme will just reference values from the global CSS
 * @param theme - A theme generated by the combination of a theme definition and a palette
 * @returns styled theme - a theme formatted for styled components, follwing the System UI theme spec https://system-ui.com/theme/
 */
function createStyledTheme(theme: Theme): StyledTheme {
  let styledTheme: Partial<StyledTheme> = {};
  let colors: Partial<StyledThemeColors> = {};

  Object.getOwnPropertyNames(theme.light).forEach(val => {
    colors[val] = `var(--${replaceLodash(val)})`;
  });

  styledTheme.colors = colors as StyledThemeColors;
  return styledTheme as Readonly<StyledTheme>;
}

export default createStyledTheme;
