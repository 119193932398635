import styled from "styled-components";
import { semiBoldAlias, themeHelpers } from "Libs/theme";

export const Wrapper = styled.li`
  position: relative;
  .children {
    padding-left: 0;
    margin-left: 0;
  }
  &.with-children {
    .environment-link {
      padding-left: 1em;
    }
    &.is-root > .link-wrapper > .link {
      ${semiBoldAlias};
    }
  }
  &.inactive > .link-wrapper > .link {
    color: ${props => props.theme.sectionText};
  }
  &.active > .link-wrapper .link,
  .link-wrapper .link:active {
    color: ${props => props.theme.links};
    background: transparent;
    border: none;
    box-shadow: none;
    outline: none;
    margin: 0;
    position: relative;
    span.active {
      position: absolute;
      top: 14px;
      left: 4px;
      display: ${props =>
        props.theme.name === "contrast" ? "inline-block" : "none"};
      height: 4px;
      width: 4px;
      border-radius: 4px;
      background: ${props => props.theme.links};
    }
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;

  .link {
    color: ${props => props.theme.sectionText};
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    line-height: 32px;
    font-size: 14px;
    min-width: 150px;
    white-space: nowrap;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 16px;
    &:hover {
      background: ${props => themeHelpers(props.theme, "linkHover")};
      border-radius: 4px;
      outline: none;
    }
    &:focus {
      outline: none;
      border: 1px solid ${props => themeHelpers(props.theme, "primaryShade")};
      border-radius: 4px;
      box-shadow: 0 0 2px ${props => themeHelpers(props.theme, "primaryShade")};
      margin: -1px;
    }
    .blue {
      color: ${props => props.theme.links};
      background: transparent;
    }
  }
  .search-result {
    > span {
      margin: 0;
    }
  }
`;
