import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import NavBar from "Containers/NavBar";
import PageLayout from "Components/PageLayout";
import PluginApp from "../../../organization/pages/project/app/Page";
import { Layout } from "./styles";

const Routes = () => {
  const { push } = useHistory();
  const { pathname } = useLocation();

  return (
    <React.Fragment>
      <NavBar
        push={push}
        role="navigation"
        currentPathName={pathname}
        fixed={true}
      />
      <PageLayout>
        <Layout>
          <PluginApp route="/" type="page" />
        </Layout>
      </PageLayout>
    </React.Fragment>
  );
};

export default Routes;
