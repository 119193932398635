import styled from "styled-components";
import { semiBoldAlias } from "Libs/theme";

export const Wrapper = styled.div`
  line-height: 0;
  .UserAvatar {
    min-width: 24px;
    overflow: hidden;
    font-size: 12px;
    text-transform: uppercase;
    color: #1a192b;
    ${semiBoldAlias};
  }
  .UserAvatar--inner,
  .UserAvatar--img {
    border-radius: 4px !important;
    overflow: hidden;
  }
`;
