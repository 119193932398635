import React from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const BillingRedirect = () => {
  const username = useSelector(state => state.app?.getIn(["me", "username"]));
  return <Redirect to={`/-/users/${username}/billing`} />;
};

export default BillingRedirect;
