import { getOrganizationProfile } from "Reducers/organization/profile";

// We place events that trigger some drift chatbot reaction here
// As a key put the name of the redux action that we want to act as a
// trigger of a drift event, the value is a function that takes the action
// and the store (if needed) and returns a drift event
const driftEvents = {
  [getOrganizationProfile.fulfilled.type]: action => {
    const isTrialOrganization = action?.payload?.data?.current_trial?.active;

    if (isTrialOrganization && process.env.DRIFT_CHATBOT_ID) {
      // we only want the chatbot active if the user belongs to any
      // organization that is on trial, I know loading the drift chatbot
      // is a side effect and should ideally happen on an action/saga but
      // this middleware only deals with the chatbot, so I think
      // it's safe & simpler to keep this here
      window?.drift?.load?.(process.env.DRIFT_CHATBOT_ID);

      return [
        action?.payload?.me?.id, // Drift wants the user id as a separate argument
        {
          display_name: action?.payload?.me?.display_name,
          is_trial_status_active: isTrialOrganization
        }
      ];
    }

    return null;
  }
};

function createDriftEvent(action, store) {
  let event = driftEvents[action.type]
    ? driftEvents[action.type](action, store)
    : null;

  return event;
}

export default function driftChatbot(action, store) {
  const driftEvent = createDriftEvent(action, store);

  if (driftEvent !== null) {
    // For now we only want to identify users, so we can call identify
    // directly here, if marketing wants to do more fancy stuff, maybe we would
    // need to switch here depending on the event
    // https://devdocs.drift.com/docs/user-identification
    window.drift?.identify(...driftEvent);
  }
}
