import React, { useRef } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";

import useMediaQuery from "Hooks/useMediaQuery";
import useExpandable from "Hooks/useExpandable";
import ButtonLink from "Components/ButtonLink";

import * as S from "./styles";

export function getActiveTab(tabs, currentPathName) {
  const exactMatch = tabs.find(
    tab => decodeURIComponent(currentPathName) === decodeURIComponent(tab.route)
  );

  if (exactMatch) {
    return exactMatch;
  }

  if (currentPathName.includes) {
    return tabs
      .filter(tab => currentPathName.includes(tab.route))
      .reduce(
        (acc, tab) => (tab.route.length > acc.route?.length ? tab : acc),
        { route: "" }
      );
  }

  return null;
}

const getTabName = (tabName, intl) =>
  tabName.startsWith("plugin.")
    ? tabName.replace("plugin.", "")
    : intl.formatMessage({ id: `tabs.${tabName}` });

const Dropdown = ({ tabs, currentPathName, links, intl }) => {
  const currentTabName = getActiveTab(tabs, currentPathName).name;
  const [expanded, toggleExpand] = useExpandable(useRef());

  return (
    <div>
      <S.Tab onClick={toggleExpand}>
        {intl.formatMessage({ id: `tabs.${currentTabName}` })}
        <S.Chevron isOpen={expanded} animate />
      </S.Tab>
      {expanded && (
        <S.DropdownPanel>
          {tabs.map(tab => {
            if (tab.permission && links && !links[tab.permission]) {
              return;
            }

            const isActive = tab.name === currentTabName;
            return (
              <S.DropdownTab key={tab.name} active={isActive} role="menuitem">
                {tab.type === "button" ? (
                  <ButtonLink
                    id={tab.name}
                    className={`tab tab-${tab.name} ${
                      isActive ? "active" : ""
                    }`}
                    to={tab.route}
                    external={true}
                    blank={false}
                    text={intl.formatMessage({ id: `tabs.${tab.name}` })}
                  />
                ) : (
                  <Link
                    id={tab.name}
                    to={tab.route}
                    onClick={toggleExpand}
                    className={`tab tab-${tab.name} ${
                      isActive ? "active" : ""
                    }`}
                  >
                    {intl.formatMessage({ id: `tabs.${tab.name}` })}
                  </Link>
                )}
              </S.DropdownTab>
            );
          })}
        </S.DropdownPanel>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  tabs: PropTypes.array,
  currentPathName: PropTypes.string.isRequired,
  links: PropTypes.object,
  intl: PropTypes.object
};

const Tabs = ({ tabs, currentPathName, links, intl }) => {
  const layout = useMediaQuery("(min-width: 768px)") ? "inline" : "dropdown";
  const currentTabName = getActiveTab(tabs, currentPathName).name;

  if (layout === "dropdown") {
    return (
      <Dropdown
        tabs={tabs}
        currentPathName={currentPathName}
        links={links}
        intl={intl}
      />
    );
  }

  return (
    <S.TabsLayout className="tabs" role="menubar">
      {tabs.map(tab => {
        if (
          tab.permission &&
          links &&
          ((typeof tab.permission === "function" &&
            !tab.permission(currentPathName, links)) ||
            (typeof tab.permission === "string" && !links[tab.permission]))
        ) {
          return;
        }
        const isActive = tab.name === currentTabName;

        return (
          <S.Tab
            key={tab.name}
            active={isActive}
            className={`tab-wrapper${tab.position === "right" ? " right" : ""}`}
            role="menuitem"
          >
            {tab.type === "button" ? (
              <ButtonLink
                id={tab.name}
                className={`tab tab-${tab.name} ${isActive ? "active" : ""}`}
                to={tab.route}
                external={true}
                blank={false}
                text={intl.formatMessage({ id: `tabs.${tab.name}` })}
              />
            ) : (
              <Link
                id={tab.name}
                to={tab.route}
                className={`tab tab-${tab.name} ${isActive ? "active" : ""}`}
              >
                {getTabName(tab.name, intl)}
              </Link>
            )}
          </S.Tab>
        );
      })}
    </S.TabsLayout>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.array,
  currentPathName: PropTypes.string.isRequired,
  links: PropTypes.object,
  intl: PropTypes.object
};

export default injectIntl(Tabs);
