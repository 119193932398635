import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";

import CloseIcon from "Icons/CloseIcon";

const Layout = styled.span`
  button,
  a {
    width: 32px;
    border: none;
    background-color: transparent;
    height: 32px;
    filter: ${props => props.filter};
    display: inline-flex;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    padding: 0;
    font-size: 14px;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    .icon {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      border-radius: 4px;
      min-width: 32px;
      background: transparent;
      svg {
        width: 12px;
        height: auto;
        margin: 6px;
      }
    }
    &:hover,
    &:focus {
      outline: none;
      .icon {
        background-color: #ededed;
      }
    }
    &:active {
      background-color: transparent;
    }
    &.with-text {
      width: auto;
      text-decoration: none;
      .text {
        display: block;
        margin-right: 16px;
        color: ${props => props.theme.subtitleText};
        &:first-letter {
          text-transform: uppercase;
        }
      }
      &:hover {
        background span {
          color: #4a495e;
        }
      }
    }
  }
  svg {
    height: auto;
    width: 100%;
    > g > g {
      fill: #98a0ab;
    }
  }
  &:hover svg > g > g {
    fill: #4a495e;
  }
  .close.white {
    background: transparent;
    svg > g > g {
      fill: #ffffff;
    }
  }

  ${props =>
    props.theme.name === "contrast" &&
    css`
      svg > g > g {
        fill: #4a495e;
      }

      a:hover,
      a:focus,
      a:active {
        box-shadow: 0 0 0 1px #4786ff;
        border-radius: 2px;

        .icon {
          background-color: transparent;
        }
      }
    `};
`;

const CloseBtn = ({
  onClick,
  filter,
  id,
  color,
  intl,
  floatRight,
  toLink,
  className,
  closeText
}) => (
  <Layout style={{ float: floatRight && "right" }}>
    {!toLink ? (
      <button
        id={id}
        aria-label={intl.formatMessage({ id: "close" })}
        className={`close${className ? " " + className : ""}${
          closeText ? " with-text" : ""
        }`}
        onClick={e => {
          e.preventDefault();
          onClick();
        }}
        filter={filter}
      >
        {closeText && <span className="text">{closeText}</span>}{" "}
        <span className="icon">
          <CloseIcon color={color} />
        </span>
      </button>
    ) : (
      <Link
        id={id}
        aria-label={intl.formatMessage({ id: "close" })}
        role="button"
        className={`close${className ? " " + className : ""}${
          closeText ? " with-text" : ""
        }`}
        to={toLink}
        filter={filter}
        tabIndex="0"
        href="#"
      >
        {closeText && <span className="text">{closeText}</span>}{" "}
        <span className="icon">
          <CloseIcon color={color} />
        </span>
      </Link>
    )}
  </Layout>
);

CloseBtn.propTypes = {
  onClick: PropTypes.func,
  filter: PropTypes.string,
  id: PropTypes.string,
  intl: PropTypes.object,
  color: PropTypes.string,
  floatRight: PropTypes.bool,
  toLink: PropTypes.string,
  className: PropTypes.string,
  closeText: PropTypes.string
};

export default injectIntl(CloseBtn);
