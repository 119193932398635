import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import ModalWrapper from "Components/Modal";
import Heading2 from "Components/styleguide/Heading2";
import Button from "Components/Button";
import ButtonWrapper from "Components/ButtonWrapper";

import * as S from "./styles";

const ModalTrial = ({ closeModal, isOpen, kind, organizationId }) => {
  const intl = useIntl();
  const { push } = useHistory();
  const username = useSelector(state => state.app?.getIn(["me", "username"]));

  const goToBilling = e => {
    e?.preventDefault();
    closeModal();
    if (process.env.ENABLE_ORGANIZATION) {
      push(`/${organizationId}/-/billing`);
    } else {
      push(`/-/users/${username}/billing`);
    }
  };

  return (
    <ModalWrapper
      modalClass={`modal-small`}
      isOpen={isOpen}
      closeModal={closeModal}
    >
      <S.Body>
        <Heading2>
          {intl.formatMessage({ id: `modal.trial.limit.${kind}.title` })}
        </Heading2>
        <p>
          {intl.formatMessage(
            { id: `modal.trial.limit.${kind}.details` },
            {
              // eslint-disable-next-line react/display-name
              a: txt => <a href="https://platform.sh/contact/">{txt}</a>,
              // eslint-disable-next-line react/display-name
              br: <br />
            }
          )}
        </p>
        <ButtonWrapper className="modal-buttons">
          <Button
            id={`add-billing-modal-button`}
            type="submit"
            aria-label={intl.formatMessage({
              id: `modal.trial.limit.${kind}.button`
            })}
            onClick={goToBilling}
          >
            {intl.formatMessage({ id: `modal.trial.limit.${kind}.button` })}
          </Button>
          <Button
            id={`cancel-modal-button`}
            className="secondary"
            type="button"
            aria-label={intl.formatMessage({ id: "cancel" })}
            onClick={closeModal}
          >
            {intl.formatMessage({ id: "cancel" })}
          </Button>
        </ButtonWrapper>
      </S.Body>
    </ModalWrapper>
  );
};

ModalTrial.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  kind: PropTypes.string.isRequired,
  organizationId: PropTypes.string
};

export default ModalTrial;
