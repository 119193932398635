import { fromJS, Map } from "immutable";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getOrganizationId } from "Libs/utils";

export const getOrganizationProfile = createAsyncThunk(
  "app/organization/profile",
  async ({ organizationId }, { getState }) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;

    const profile = await client.getOrganizationProfile(
      getOrganizationId(getState, organizationId)
    );

    return profile;
  }
);

export const updateOrganizationProfile = createAsyncThunk(
  "app/organization/profile",
  async ({ organizationId, data }, { getState }) => {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;

    const profile = await client.updateOrganizationProfile(
      getOrganizationId(getState, organizationId),
      data
    );

    return profile;
  }
);

const setError = (state, action) =>
  state.set("errors", action.error.message).set("loading", false);

const organizationProfile = createSlice({
  name: "organizationProfile",
  initialState: Map({ data: new Map(), loading: "idle" }),
  reducers: {},
  extraReducers: {
    [getOrganizationProfile.pending]: state => state.set("loading", true),
    [getOrganizationProfile.fulfilled]: (state, action) => {
      const { organizationId } = action.meta.arg;

      return state
        .setIn(["data", organizationId], fromJS(action.payload))
        .set("loading", false);
    },
    [getOrganizationProfile.rejected]: (state, action) =>
      setError(state, action)
  }
});

export default organizationProfile.reducer;

export const organizationProfileLoadingSelector = state =>
  state.organizationProfile.get("loading");

export const organizationProfileSelector = (state, props) =>
  state.organizationProfile.getIn(["data", props.organizationId]);
