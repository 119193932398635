import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import styled, { css } from "styled-components";

import useMediaQuery from "Hooks/useMediaQuery";

const ActionButton = ({ use, expandWhen, ...props }) => {
  const content = useMediaQuery(expandWhen) ? "mixed" : "icon";
  return <Button as={use} content={content} {...props} />;
};

ActionButton.propTypes = {
  use: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  expandWhen: PropTypes.string
};

const StyledActionButton = styled(ActionButton)`
  ${props =>
    props.expandWhen &&
    css`
      & span {
        display: none;
      }

      @media ${props => props.expandWhen} {
        & span {
          display: inherit;
        }
      }
    `};
`;

export default StyledActionButton;
