import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import copyToClipboard from "copy-to-clipboard";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { interpolateURL, getSubscriptionEditUrl } from "Libs/utils";

import CopyIcon from "Icons/CopyIcon";
import SuccessStateIcon from "Icons/SuccessStateIcon";
import IconMore from "Icons/IconMore";
import ActionDropdown from "../ActionDropdown";
import ModalConfirmDelete from "../ModalConfirmDelete";

import { deleteSubscription } from "Reducers/subscription";

import * as S from "./ProjectMoreLinks.styles";

const ProjectMoreLinks = ({
  iconColor = "white",
  from,
  project,
  subscription,
  toggleProjectWizard
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [modalIsOpen, setOpenModal] = useState(false);

  const intl = useIntl();
  const dispatch = useDispatch();

  const subscriptionStatus = useSelector(({ subscription }) =>
    subscription?.get("status")
  );

  useEffect(
    () => {
      let isCanceled = false;
      let timer;
      if (isCopied) {
        timer = setTimeout(() => !isCanceled && setIsCopied(false), 500);
      }
      return () => {
        isCanceled = true;
        clearTimeout(timer);
      };
    },
    [isCopied]
  );

  useEffect(
    () => {
      if (subscriptionStatus === "deleted")
        window.location.replace(window.location.origin);
    },
    [subscriptionStatus]
  );

  const subscriptionEditUrl = useMemo(
    () => getSubscriptionEditUrl({ project, subscription }),
    [project, subscription]
  );

  const copyId = e => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent?.stopPropagation();
    setIsCopied(true);
    copyToClipboard(project.id);
  };

  const openModal = e => {
    e?.preventDefault();
    e?.nativeEvent.stopPropagation();
    if (process.env.CUSTOM_SUBSCRIPTION_DELETE_REDIRECT_URL) {
      window.location.href = interpolateURL(
        process.env.CUSTOM_SUBSCRIPTION_DELETE_REDIRECT_URL,
        { projectId: project.id }
      );
    } else {
      setOpenModal(true);
    }
  };

  const handleDelete = e => {
    e?.preventDefault();
    e?.nativeEvent.stopPropagation();

    if (process.env.CUSTOM_SUBSCRIPTION_DELETE_REDIRECT_URL) {
      window.location.href = interpolateURL(
        process.env.CUSTOM_SUBSCRIPTION_DELETE_REDIRECT_URL,
        { projectId: project.id }
      );
    } else {
      dispatch(deleteSubscription({ subscription }));
    }
  };

  const handleEditSubscription = e => {
    e?.preventDefault();
    e?.nativeEvent.stopPropagation();
    window.location.href = subscriptionEditUrl.url;
  };

  if (!project || (!subscriptionEditUrl && !toggleProjectWizard)) {
    return null;
  }
  return (
    <S.Wrapper>
      <ActionDropdown
        icon={<IconMore color={iconColor} />}
        ariaLabel="More"
        withArrow={false}
        className="more"
        id={project.subscription_id}
      >
        <ul>
          <li role="menuitem">
            <S.IdProject onClick={copyId}>
              {intl.formatMessage(
                { id: "project.morelinks.id", defaultMessage: "ID {id}" },
                {
                  id: project.id
                }
              )}
              <S.Icon>
                {isCopied ? (
                  <SuccessStateIcon aria-hidden="true" />
                ) : (
                  <CopyIcon aria-hidden="true" />
                )}
              </S.Icon>
            </S.IdProject>
          </li>

          {subscriptionEditUrl && (
            <li role="menuitem" data-testid="sub-link-edit">
              {subscriptionEditUrl.external ? (
                <S.Button onClick={handleEditSubscription}>
                  {intl.formatMessage({
                    id: "project.morelinks.edit",
                    defaultMessage: "Edit plan"
                  })}
                </S.Button>
              ) : (
                <Link
                  to={{
                    pathname: subscriptionEditUrl.url,
                    state: { from }
                  }}
                >
                  {intl.formatMessage({
                    id: "project.morelinks.edit",
                    defaultMessage: "Edit plan"
                  })}
                </Link>
              )}
            </li>
          )}

          {toggleProjectWizard && (
            <li role="menuitem" data-testid="toggle-wizard">
              <S.Button onClick={toggleProjectWizard}>
                {intl.formatMessage({
                  id: "project.morelinks.wizard",
                  defaultMessage: "Setup wizard"
                })}
              </S.Button>
            </li>
          )}

          {subscriptionEditUrl && (
            <li role="menuitem" data-testid="sub-link-delete">
              <S.Button onClick={e => openModal(e)}>
                {intl.formatMessage({
                  id: "project.morelinks.delete",
                  defaultMessage: "Delete"
                })}
              </S.Button>
            </li>
          )}
        </ul>
      </ActionDropdown>

      <ModalConfirmDelete
        title={intl.formatMessage({
          id: "project.morelinks.delete_modal.title",
          defaultMessage: "Delete project"
        })}
        deleteFunction={handleDelete}
        isOpen={modalIsOpen}
        closeModal={() => setOpenModal(false)}
        itemId={`subscription-${project.subscription_id}`}
        size="medium"
        body={intl.formatMessage(
          {
            id: "project.morelinks.delete_modal.sentence"
          },
          {
            sub: project.title,
            br: <br />,
            b: (...chunks) => <strong>{chunks}</strong> //eslint-disable-line
          }
        )}
      />
    </S.Wrapper>
  );
};

ProjectMoreLinks.propTypes = {
  iconColor: PropTypes.string,
  from: PropTypes.string,
  project: PropTypes.object.isRequired,
  subscription: PropTypes.object.isRequired,
  toggleProjectWizard: PropTypes.func
};

export default ProjectMoreLinks;
