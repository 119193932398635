import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const VendorLogoContainer = styled.div`
  img {
    width: ${props => props.width || "fit-content"};
    height: 35px;
  }
`;

const VendorIcon = ({ width, path }) => (
  <VendorLogoContainer width={width}>
    <img src={path} alt="logo" />
  </VendorLogoContainer>
);

VendorIcon.propTypes = {
  path: PropTypes.string,
  width: PropTypes.string
};

export default VendorIcon;
