import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { List } from "immutable";

import useDecodedParams from "Hooks/useDecodedParams";
import { environmentsSelector } from "Reducers/environment";

import EnvironmentTree from "Components/EnvironmentTree";

const getTreeData = (nodes, environments, depth = 0) => {
  return nodes.map(node => {
    const newNodes = environments
      .filter(environment => environment.parent === node.id)
      .valueSeq()
      .toJS();

    let children = [];

    if (newNodes.length) {
      children = getTreeData(newNodes, environments, depth + 1);
    }

    return {
      environmentId: node.id,
      projectId: node.project,
      organizationId: node.organization,
      created_at: node.created_at,
      updated_at: node.updated_at,
      title: node.title,
      isRoot: !node.parent,
      type: node.type,
      isDirty: node.status === "dirty",
      expanded: true,
      status: node.status,
      isMock: node.isMock,
      deployment_target: node.deployment_target,
      depth,
      children
    };
  });
};

const EnvironmentsTree = ({ closeDropDown, layout = "" }) => {
  const { organizationId, projectId } = useDecodedParams();

  const environments = useSelector(state =>
    environmentsSelector(state, { organizationId, projectId })
  );

  const treeData = useMemo(
    () => {
      if (!environments) return List();
      const rootEnv = environments
        .filter(environment => !environment.parent)
        .valueSeq()
        .toJS();
      const data = getTreeData(rootEnv, environments);
      return data;
    },
    [environments]
  );

  return (
    <EnvironmentTree
      data={treeData}
      layout={layout}
      closeDropDown={closeDropDown}
    />
  );
};

EnvironmentsTree.propTypes = {
  closeDropDown: PropTypes.func,
  layout: PropTypes.string
};

export default EnvironmentsTree;
