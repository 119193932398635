import styled from "styled-components";
import { semiBoldAlias, themeHelpers } from "Libs/theme";

export const NavBarLink = styled.nav`
  flex-shrink: 2;
  position: relative;
  height: 56px;
  box-sizing: border-box;
  width: 100%;

  > span {
    color: ${props => props.theme.navText};
    text-decoration: none;
    font-size: 16px;
    z-index: 3;
    position: relative;
  }
  &.header-arrow {
    ${semiBoldAlias};
  }
  &.header-arrow-open:focus {
    outline: none;
  }
  .navbar-link button,
  .navbar-link a {
    font-size: 15px;
    line-height: 22px;
    font-weight: normal;
    color: #fff;
    padding: 0 0 0 24px;
    width: 100%;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    &:hover,
    &:focus {
      outline: none;
    }
    &:focus {
      span.active {
        position: absolute;
        top: 27px;
        left: 12px;
        display: ${props =>
          props.theme.name === "contrast" ? "inline-block" : "none"};
        height: 4px;
        width: 4px;
        border-radius: 4px;
        background: #fff;
      }
    }
    label {
      color: #fff;
      opacity: 0.7;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: 0;
      font-size: 10px;
    }
    &.chevron-link {
      width: 43px;
      min-width: 43px;
      padding: 0 1px 0 0;
      svg {
        opacity: 0.5;
        path {
          fill: #fff;
        }
      }
    }
  }
  &.active,
  &:hover {
    background: ${props => themeHelpers(props.theme, "darkTint")};
    .navbar-link .chevron-link svg {
      opacity: 1;
    }
  }
  span.chevron {
    min-width: 42px;
    height: 24px;
    border-left: ${props =>
      props.theme.name === "contrast"
        ? "1px solid " + props.theme.sectionTextLight
        : "1px solid rgba(255, 255, 255, 0.1)"};
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    &.open {
      border-left: none;
      border-right: ${props =>
        props.theme.name === "contrast"
          ? "1px solid " + props.theme.sectionTextLight
          : "1px solid rgba(255, 255, 255, 0.1)"};
    }
  }
  &:not(.active):hover {
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-width: 0 1px 0 0;
    button.chevron-link,
    a.chevron-link {
      margin-right: -2px;
    }
    span.chevron {
      width: 40px;
      min-width: 40px;
    }
  }
  @media (min-width: 768px) {
    width: 232px;
  }
`;
