import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

import useUniqueId from "Libs/useUniqueId";

const InfoIcon = ({ color }) => {
  const uid = useUniqueId();

  const theme = useContext(ThemeContext);
  const fillColor = color || theme?.buttonCancelText;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`info-icon-${uid}`}
    >
      <title id={`info-icon-${uid}`}>Info icon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 12C4 7.6 7.6 4 12 4C16.4 4 20 7.6 20 12C20 16.4 16.4 20 12 20C7.6 20 4 16.4 4 12ZM11 11C11 10.4 11.4 10 12 10C12.6 10 13 10.4 13 11V15C13 15.6 12.6 16 12 16C11.4 16 11 15.6 11 15V11ZM11 8C11 7.4 11.4 7 12 7C12.6 7 13 7.4 13 8C13 8.6 12.6 9 12 9C11.4 9 11 8.6 11 8Z"
        fill={fillColor}
      />
    </svg>
  );
};

InfoIcon.propTypes = {
  color: PropTypes.string
};

export default InfoIcon;
