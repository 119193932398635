import React from "react";
import Proptypes from "prop-types";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import AlertIcon from "Icons/AlertIcon";
import { Wrapper } from "../";

const PaymentMissing = ({ organizationId }) => {
  const intl = useIntl();
  const username = useSelector(state => state.app?.getIn(["me", "username"]));

  let billingUrl = `/-/users/${username}/billing`;
  if (process.env.ENABLE_ORGANIZATION) {
    billingUrl = `/${organizationId}/-/billing`;
  }

  return (
    <Wrapper type={"critical"}>
      <AlertIcon color="#FFF" />
      {intl.formatMessage(
        {
          id: "banner.payment_missing",
          defaultMessage:
            "Payment method missing. Add your <a>billing details</a> to keep your projects running."
        },
        {
          // eslint-disable-next-line react/display-name
          a: txt => <Link to={billingUrl}>{txt}</Link>
        }
      )}
    </Wrapper>
  );
};

PaymentMissing.propTypes = {
  organizationId: Proptypes.string
};

export default PaymentMissing;
