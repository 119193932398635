import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const BranchIcon = ({ color = "#fff" }) => {
  const uid = useUniqueId();

  return (
    <svg
      className="icon-branch"
      height="24px"
      width="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`branch-icon-${uid}`}
    >
      <title id={`branch-icon-${uid}`}>Branch icon</title>
      <path
        fill={color}
        className="fill-target"
        d="M14.5,4.9l1.6,1.6l-2.4,2.4c-0.4,0.4-0.4,1,0,1.4l0.1,0.1c0.4,0.4,1,0.4,1.4,0L17.6,8l1.6,1.6c0.3,0.3,0.9,0.1,0.9-0.4V4.5
c0-0.3-0.2-0.5-0.5-0.5h-4.7C14.4,4,14.2,4.5,14.5,4.9z M8.8,4H4.5C4.2,4,4,4.2,4,4.5v4.3c0,0.4,0.5,0.7,0.9,0.4l1.4-1.4l4.1,4.1
c0.4,0.4,0.6,0.9,0.6,1.4V19c0,0.6,0.4,1,1,1l0,0c0.6,0,1-0.4,1-1v-6.6c0-0.5-0.2-1-0.6-1.4L7.7,6.3l1.4-1.4C9.5,4.5,9.2,4,8.8,4z"
      />
    </svg>
  );
};

BranchIcon.propTypes = {
  color: PropTypes.string
};

export default BranchIcon;
