// This file is just for test purposes, vendor theme generation
// should happen elsewhere
import { Palette } from "../types/themes.types";
import {
  colors,
  defaultThemeDefinition,
  platformPalette
} from "./defaultTheme";

import { createTheme } from "../core/themeCreation";

const vendorPalette: Palette = {
  ...platformPalette,
  ...{
    name: "vendor palette",
    colors: {
      ...platformPalette.colors,
      secondary_500: colors.orange,
      success_500: "fuchsia"
    }
  }
};

export default createTheme(defaultThemeDefinition, vendorPalette);
