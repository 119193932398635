import React from "react";
import PropTypes from "prop-types";

import MaxWidthContainer from "Components/MaxWidthContainer";

import * as S from "./styles";

const HeaderAlert = ({ children, type }) => {
  return (
    <S.Wrapper type={type}>
      <MaxWidthContainer>
        <S.Content>{children}</S.Content>
      </MaxWidthContainer>
    </S.Wrapper>
  );
};

HeaderAlert.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string
};

export default HeaderAlert;
