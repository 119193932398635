import React from "react";
import PropTypes from "prop-types";
import CloseBtn from "Components/CloseBtn";
import * as S from "./styles";

const Close = ({ isOpen, ...props }) => (
  <S.Layout aria-label={isOpen ? `Open menu` : "Close menu"} {...props}>
    {isOpen ? (
      <div className="menu-close-btn">
        <CloseBtn />
      </div>
    ) : (
      <>
        <S.Line isOpen={isOpen} />
        <S.Line isOpen={isOpen} />
      </>
    )}
  </S.Layout>
);

Close.propTypes = {
  isOpen: PropTypes.bool
};

export default Close;
