import { useCallback, useState, useEffect } from "react";

// Takes a node and returns an array with a boolean value representing
// the expanded state of the node, and a function that toggles that value.
// Simplest way to use this is by passing useRef()

// const [expanded, toggleExpanded] = useExpandable(useRef())

// Then you can render/not render components based on the value of expanded
// If the user clicks outside of the passed node, or press Escape the expanded
// state is automatically set to false

function useExpandable(node) {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleClickOutside = useCallback(
    e => {
      if (node?.current?.contains(e.target)) {
        return;
      }

      if (expanded) {
        setExpanded(false);
      }
    },
    [expanded]
  );

  const handleKeyDown = useCallback(
    e => {
      const ESCAPE_KEYCODE = 27;
      if (e.keyCode === ESCAPE_KEYCODE && expanded) {
        setExpanded(false);
      }
    },
    [expanded]
  );

  const handleExternalEvents = useCallback(
    () => {
      if (expanded) {
        document.addEventListener("click", handleClickOutside);
        document.addEventListener("keydown", handleKeyDown);
      } else {
        document.removeEventListener("click", handleClickOutside);
        document.removeEventListener("keydown", handleKeyDown);
      }

      return () => {
        document.removeEventListener("click", handleClickOutside);
        document.removeEventListener("keydown", handleKeyDown);
      };
    },
    [expanded]
  );

  useEffect(handleExternalEvents, [expanded]);

  return [expanded, toggleExpand];
}

export default useExpandable;
