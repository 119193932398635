import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const HeartIcon = ({ color = "#98a0ab" }) => {
  const uid = useUniqueId();

  return (
    <svg
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`heart-icon-${uid}`}
    >
      <title id={`heart-icon-${uid}`}>Heart icon</title>
      <path
        d="M11 2.80547C11 5.78554 8.25 7.64418 5.5 10C2.75 7.64418 0 5.78554 0 2.80547C0 -0.174597 4.58333 -1.24128 5.5 1.90616C6.41667 -1.24128 11 -0.174597 11 2.80547Z"
        fill={color}
      />
    </svg>
  );
};

HeartIcon.propTypes = {
  color: PropTypes.string
};

export default HeartIcon;
