import styled from "styled-components";
import ModalBody from "Components/ModalBody";

export const Body = styled(ModalBody)`
  h2 {
    margin-bottom: 16px;
  }
  p {
    line-height: 24px;
    a {
      text-decoration: none;
    }
  }
`;
