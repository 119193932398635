// Overrides on this file can get moved to the ui-kit
// once we are sure this is what we want
import { Button } from "@platformsh/ui-kit";
import styled from "styled-components";

export const RightAlignedButton = styled(Button)`
  float: right;
  margin-left: auto;
`;

export default Button;
