import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import AccessibleTooltip from "Components/AccessibleTooltip";

const RequiredWrapper = styled(AccessibleTooltip)`
  background-color: ${props => props.theme.primary};
  display: inline-block;
  height: 4px;
  width: 4px;
  border-radius: 4px;
  margin: 0 0 2px 4px;
`;

const RequiredTag = ({ id, ...props }) => (
  <RequiredWrapper
    className="required-notice"
    tooltipProps={{
      id: `${id}-required`,
      place: "top",
      children: "Required field"
    }}
    {...props}
  />
);

RequiredTag.propTypes = {
  id: PropTypes.string
};

export default RequiredTag;
