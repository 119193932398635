import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const RedeployIcon = ({ color = "currentColor" }) => {
  const uid = useUniqueId();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className="icon-redeploy"
      role="img"
      aria-labelledby={`redeploy-icon-${uid}`}
    >
      <title id={`redeploy-icon-${uid}`}>Redeploy icon</title>
      <path
        d="M11 14.5996C11.8284 14.5996 12.5 13.928 12.5 13.0996C12.5 12.2712 11.8284 11.5996 11 11.5996C10.1716 11.5996 9.5 12.2712 9.5 13.0996C9.5 13.928 10.1716 14.5996 11 14.5996Z"
        fill={color}
        className="fill-target"
      />
      <path
        d="M17 12.7C17 16 14.3 18.7 11 18.7C7.7 18.7 5 16 5 12.7C5 11.1 5.7 9.6 6.7 8.5"
        stroke={color}
        className="stroke-target"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M11 18.6992C14.3 18.6992 17 15.9992 17 12.6992C17 12.1992 17 8.69922 17 8.69922"
        stroke={color}
        className="stroke-target"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2998 4.19961C17.0998 3.89961 16.6998 3.89961 16.4998 4.19961L14.8998 6.19961L13.4998 7.89961C13.1998 8.19961 13.4998 8.69961 13.8998 8.69961H16.8998H19.8998C20.2998 8.69961 20.5998 8.19961 20.2998 7.89961L18.9998 6.19961L17.2998 4.19961Z"
        fill={color}
        className="fill-target"
      />
    </svg>
  );
};

RedeployIcon.propTypes = {
  color: PropTypes.string
};

export default RedeployIcon;
