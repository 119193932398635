import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { useLocation, useParams } from "react-router-dom";

import Node from "./Node";

import * as S from "./styles";

const Breadcrumbs = ({ environmentPath = [], hiddenXs, projectName }) => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const { organizationId, projectId } = useParams();

  const getItems = () => {
    const isMetricsURL = projectId && pathname.match(/\/metrics$/);
    let items = [];
    if (projectId) {
      items.push({
        title: intl.formatMessage({ id: "breadcrumb.projects" }),
        path: "/"
      });
      items.push({
        title: projectName || intl.formatMessage({ id: "breadcrumb.project" }),
        path: `/${organizationId}/${projectId}`,
        id: "project-navigation"
      });
      const environmentPathLength = environmentPath.length - 1;
      environmentPath.forEach((env, i) => {
        items.push({
          title: env.title,
          path: `/${organizationId}/${projectId}/${env.id}`,
          separator:
            isMetricsURL && environmentPathLength === i ? undefined : "/",
          id: environmentPath.length === i + 1 ? "environment-navigation" : null
        });
      });

      if (isMetricsURL) {
        items.push({
          title: intl.formatMessage({ id: "metrics" }),
          path: pathname,
          separator: ">"
        });
      }

      return items;
    }

    const rootPath = `/-/users/${organizationId}`;
    const organizationRootPath = `/${organizationId}/-`;
    if (new RegExp(`^${rootPath}/(billing|settings|tickets)`).test(pathname)) {
      let paths = pathname
        .replace(rootPath, "")
        .split("/")
        .filter(elt => elt);

      // When the path is just /settings we want to show as a breadcrumb
      // just the tab name (my profile) instead of /account setttings/my profile
      if (paths.length === 1 && paths[0] === "settings") {
        paths = ["profile"];
      }

      paths.forEach((elt, i) => {
        const path = paths.slice(0, i + 1);
        // Manage path when last element is an ID
        const title =
          intl.messages[`breadcrumb.${path.join(".")}`] !== undefined
            ? intl.formatMessage(
                { id: `breadcrumb.${path.join(".")}` },
                {
                  elements: paths.length // hack to switch title if first element
                }
              )
            : intl.formatMessage(
                { id: `breadcrumb.${path.slice(0, i).join(".")}.id` },
                { id: elt }
              );

        items.push({
          title,
          path: `${rootPath}/${path.join("/")}`
        });
      });
      return items;
    }

    if (
      new RegExp(`^${organizationRootPath}/(billing|settings|tickets)`).test(
        pathname
      )
    ) {
      let paths = pathname
        .replace(organizationRootPath, "")
        .split("/")
        .filter(elt => elt);

      paths.forEach((elt, i) => {
        const path = paths.slice(0, i + 1);
        // Manage path when last element is an ID
        const title =
          intl.messages[`breadcrumb.organization.${path.join(".")}`] !==
          undefined
            ? intl.formatMessage(
                { id: `breadcrumb.organization.${path.join(".")}` },
                {
                  elements: paths.length // hack to switch title if first element
                }
              )
            : intl.formatMessage(
                { id: `breadcrumb.${path.slice(0, i).join(".")}.id` },
                { id: elt }
              );

        items.push({
          title,
          path: `${organizationRootPath}/${path.join("/")}`
        });
      });
      return items;
    }

    return items;
  };

  const items = getItems();
  return (
    <S.Wrapper aria-label="Breadcrumb" hiddenXs={hiddenXs} id="breadcrumb">
      <S.List>
        {items.map((item, i) => {
          const isLast = items.length === i + 1;
          return (
            <Node
              key={`item-${i}`}
              to={item.path}
              separator={!isLast && (item.separator || "chevron")}
              isActive={isLast}
              id={item.id}
            >
              {item.title}
            </Node>
          );
        })}
      </S.List>
    </S.Wrapper>
  );
};

Breadcrumbs.propTypes = {
  environmentPath: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      title: PropTypes.string
    })
  ),
  hiddenXs: PropTypes.bool,
  projectName: PropTypes.string
};

export default Breadcrumbs;
