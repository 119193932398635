import styled from "styled-components";

import { themeHelpers } from "Libs/theme";

export const MenuLayout = styled.div`
  padding: 0;
  .menu-item {
    display: block;
    width: auto;
  }
  .rcs-custom-scroll {
    .rcs-custom-scrollbar {
      opacity: 1;
    }
    .rcs-inner-container {
      padding: 8px 0;
      box-sizing: border-box;
      > div {
        padding: 0 8px;
      }
    }
    .rcs-inner-handle {
      background: ${props => themeHelpers(props.theme, "darkTint")};
      width: 4px;
      border-radius: 4px;
      opacity: 1;
    }
  }
  .project-link {
    display: inline;
    span {
      display: inline;
    }
  }
  .blue {
    color: ${props => props.theme.links};
    background: transparent;
  }
  a {
    text-decoration: none;
    color: ${props =>
      props.theme.name === "dark"
        ? props.theme.sectionTextLight
        : themeHelpers(props.theme, "darkTint")} !important;
    position: relative;
    span.highlight {
      color: ${props => props.theme.links};
    }
  }
`;

export const Active = styled.span`
  position: absolute;
  top: 14px;
  left: 4px;
  height: 4px;
  width: 4px;
  border-radius: 4px;
  background: ${props => props.theme.links};
`;

export const Empty = styled.div`
  color: ${props => props.theme.text};
  line-height: 32px;
  padding: 0 16px;
  font-size: 15px;
`;

export const HeaderText = styled.li`
  color: #98a0ab;
  font-size: 13px;
  padding: 0px 16px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
