import React, { useRef, useState, useMemo } from "react";
import PropTypes from "prop-types";

import Link from "./Link";
import InfoIcon from "Icons/InfoIcon";

import * as S from "./styles";

import InfoDialogPortal from "./InfoDialogPortal";

const recalculateAlign = (width, left, align) => {
  const viewportWidth = window.innerWidth;

  if (align === "left" && left + width > viewportWidth) {
    return "right";
  }
  if (align === "right" && left - width < 0) {
    return "left";
  }
  if (align === "center") {
    if (left + width > viewportWidth) return "right";
    if (left - width < 0) return "left";
  }

  return align;
};

const InfoDialog = ({
  align = "center",
  className = "",
  icon,
  linkText,
  linkText2,
  text,
  title,
  to,
  to2,
  iconWidth = "24px",
  iconHeight = "24px"
}) => {
  const wrapper = useRef();
  const [isActive, setIsActive] = useState(false);

  const [left, top] = useMemo(
    () => {
      if (!wrapper.current || !isActive) {
        return [0, 0];
      }

      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;

      const { left, right, bottom } = wrapper.current.getBoundingClientRect();
      const adjustedAlign = recalculateAlign(S.DIALOG_WIDTH, left, align);

      switch (adjustedAlign) {
        case "left":
          return [left, bottom + scrollTop];
        case "right":
          return [right - S.DIALOG_WIDTH, bottom + scrollTop];
        case "center":
          return [left - S.DIALOG_WIDTH / 2, bottom + scrollTop];
        default:
          return [0, 0];
      }
    },
    [wrapper?.current, isActive, align]
  );

  const handleKeyUp = event => {
    if (event.which === 27) document.activeElement.blur();
  };

  const hide = useMemo(() => () => setIsActive(false), []);
  const show = useMemo(() => () => setIsActive(true), []);

  return (
    <S.Wrapper
      ref={wrapper}
      className={`info-dialog ${className}`}
      tabIndex="0"
      onKeyUp={handleKeyUp}
      onFocus={show}
      onBlur={hide}
      onMouseEnter={show}
      onMouseLeave={hide}
      width={iconWidth}
      height={iconHeight}
    >
      {icon ? icon : <InfoIcon />}
      {isActive &&
        (title || text || to) && (
          <InfoDialogPortal>
            <S.DialogWrapper top={top} left={left}>
              <S.DialogContent>
                {title && <S.Title>{title}</S.Title>}
                {text && <S.Text>{text}</S.Text>}
                {to && <Link to={to}>{linkText ? linkText : to}</Link>}
                {to2 && <Link to={to2}>{linkText2 ? linkText2 : to2}</Link>}
              </S.DialogContent>
            </S.DialogWrapper>
          </InfoDialogPortal>
        )}
    </S.Wrapper>
  );
};

InfoDialog.propTypes = {
  align: PropTypes.oneOf(["left", "right", "center"]),
  className: PropTypes.string,
  icon: PropTypes.node,
  linkText: PropTypes.string,
  linkText2: PropTypes.string,
  text: PropTypes.node,
  title: PropTypes.string,
  to: PropTypes.string,
  to2: PropTypes.string,
  iconWidth: PropTypes.string,
  iconHeight: PropTypes.string
};

export default InfoDialog;
