import { Theme, ModeDefinition } from "../types/themes.types";

// This is how we identify the injected styles on a page
const THEME_ID = "ui-kit-theme";

/**
 * Transforms a JSON object into CSS custom properties, for
 * each of the modes the theme contains it will put its rules
 * under a [data-color-mode-<theme-name>] selector
 * @param theme
 * @returns modes css
 */
function _createCSS(theme: Theme) {
  let CSS = "";
  Object.entries(theme).forEach(mode => {
    const [modeName, rules] = mode;

    CSS += `[data-color-mode='${modeName}'] {
        color-scheme: ${modeName};
        ${_parseRulesToCSS(rules)}
    }
    `;
  });
  return CSS;
}

/**
 * Converts every record from a mode to CSS custom variable syntax
 * i.e. `primary_inverse: "brown"` will return `--primary-inverse: brown;`
 * @param mode
 * @returns css string
 */
function _parseRulesToCSS(mode: ModeDefinition) {
  let rules = "";
  Object.entries(mode).forEach(([k, v]) => {
    rules += `--${replaceLodash(k)}: ${v};\n`;
  });
  return rules;
}

/**
 * Replaces a string with lodashes replaced by dashes
 * This is needed cause dashes are not allowed in JS variable names
 * but are the standard in CSS custom properties
 * @param str
 * @retuns str
 */
export function replaceLodash(str: String) {
  return str.replace(/_/g, "-");
}

/**
 * Parses a JSON theme object into CSS custom properties and injects
 * the resulting CSS into the document head. Making it available globally
 * in the DOM
 * @param theme
 * @returns void
 */
export function injectTheme(theme: Theme) {
  if (!theme) {
    throw new Error(
      `renderTheme expects a theme object as an argument and instead got ${theme}`
    );
  }
  const style = document.createElement("style");
  style.id = THEME_ID;

  style.innerHTML = _createCSS(theme);

  const previousStyles = document.getElementById(THEME_ID);

  previousStyles
    ? previousStyles.replaceWith(style)
    : document.head.appendChild(style);
}

/**
 * Returns the current value of the data-color-mode attribute in
 * the DOM. We use this value to switch between light and dark modes
 * of a theme
 * @returns str
 */
export function getCurrentColorMode() {
  const html = document.querySelector("html");
  return html?.getAttribute("data-color-mode");
}

/**
 * Changes the value of the data-color-mode attribute in the DOM.
 * @param modeName str
 * @returns void
 */
export function setCurrentColorMode(modeName = "light") {
  const html = document.querySelector("html");
  html?.setAttribute("data-color-mode", modeName);
}
