import React from "react";
import styled from "styled-components";
import { semiBoldAlias, themeHelpers } from "Libs/theme";

const Button = styled(props => {
  return (
    <button {...props} aria-disabled={!!props.disabled}>
      {props.children}
    </button>
  );
})`
  background-color: ${props => themeHelpers(props.theme, "buttonColor")};
  color: ${props => props.theme.buttonText};
  border: 1px solid transparent;
  border-radius: 2px;
  padding: 0 24px 2px;
  height: 32px;
  line-height: 30px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  &:first-letter {
    text-transform: uppercase;
  }
  &:hover {
    background-color: ${props => themeHelpers(props.theme, "buttonHover")};
  }
  &:focus {
    border-color: ${props => themeHelpers(props.theme, "buttonHover")};
    box-shadow: 0 0 2px ${props => themeHelpers(props.theme, "buttonHover")};
    outline: none;
  }
  &:active {
    box-shadow: none;
    background-color: ${props => themeHelpers(props.theme, "buttonHover")};
  }

  &.secondary {
    background: transparent;
    color: ${props => props.theme.buttonCancelText};
    &:hover,
    &:active {
      background-color: ${props => props.theme.secondaryBtnHoverBg};
    }
    &:focus {
      border-color: ${props => props.theme.outlinedBtnBorderFocus};
      box-shadow: 0 0 2px ${props => props.theme.outlinedBtnBorderFocus};
    }
  }

  &.outline {
    background: transparent;
    color: ${props => props.theme.links};
    border: 1px solid ${props => props.theme.outlinedBtnBorder};
    margin: ${({ margin }) => margin};
    line-height: 30px;
    &:hover,
    &:active {
      color: ${props => props.theme.buttonText};
      background-color: ${props => props.theme.outlinedBtnHover};
      border-color: ${props => props.theme.outlinedBtnHover};
    }
    &:focus {
      border-color: ${props => props.theme.outlinedBtnBorderFocus};
      box-shadow: 0 0 2px ${props => props.theme.outlinedBtnBorderFocus};
    }
  }
  &:disabled {
    background-color: ${props => props.theme.buttonDisabled};
    cursor: not-allowed;
  }
  &.edit {
    width: 80px;
    font-size: 15px;
    padding: 0 0;
    text-align: right;
    cursor: pointer;
    background: transparent;
    border-radius: 0;
    border: none;
    color: ${props => themeHelpers(props.theme, "darkTint")};
    ${semiBoldAlias};
    img {
      margin-left: 10px;
    }
  }
  &.delete {
    color: ${props => props.theme.error};
    font-size: 14px;
    cursor: pointer;
    background: transparent;
    border-radius: 0;
    border: none;
    float: right;
    padding: 0;
  }
  &:first-letter {
    text-transform: uppercase;
  }
  &.link-style {
    color: ${props => props.theme.links};
    background: transparent;
    border: 1px solid transparent;
    height: auto;
    line-height: inherit;
    position: relative;
    font-weight: 400;
    &:focus,
    &:hover {
      &:after {
        content: "";
        width: calc(100% - 16px);
        position: absolute;
        height: 1px;
        background: ${props => props.theme.links};
        bottom: -1px;
        left: 8px;
        display: inline-block;
      }
    }
    &:focus {
      outline: none;
      border: 1px solid ${props => themeHelpers(props.theme, "buttonHover")};
      border-radius: 4px;
      box-shadow: 0 0 2px ${props => themeHelpers(props.theme, "buttonHover")};
      text-decoration: none;
      margin: -1px -9px;
    }
    &:active {
      border: 1px solid transparent;
      box-shadow: none;
      margin: 0 -8px;
    }
  }
`;

export default Button;
