import { Palette, ThemeDefinition } from "../types/themes.types";
import { createTheme } from "../core/themeCreation";

export const colors = {
  aquaIsland: "hsl(175deg 41% 79% / 1)",
  black: "hsl(242deg 100% 5% / 1)",
  blizzardBlue: "hsl(187deg 71% 82% / 1)",
  blueChalk: "hsl(260deg 100% 95% / 1)",
  brandBlue: "hsl(216deg 82% 43% / 1)",
  buttermilk: "hsl(45deg 100% 85% / 1)",
  chantilly: "hsl(339deg 81% 85% / 1)",
  darkGrey: "hsl(217deg 10% 59% / 1)",
  deepBlue: "hsl(220deg 100% 52% / 1)",
  deepRed: "hsl(0deg 100% 45% / 1)",
  ebony: "hsl(243deg 19% 23% / 1)",
  ebonyLight: "hsl(243deg 13% 33% / 1)",
  elf: "hsl(180deg 69% 30% / 1)",
  fire: "hsl(15deg 100% 67% / 1)",
  frangipani: "hsl(36deg 100% 85% / 1)",
  frenchLilac: "hsl(291deg 46% 83% / 1)",
  frenchPass: "hsl(199deg 92% 85% / 1)",
  frost: "hsl(88deg 51% 86% / 1)",
  gold: "hsl(43deg 100% 46% / 1)",
  granite: "hsl(215deg 10% 63% / 1)",
  green: "hsl(180deg 68% 43% / 1)",
  grey: "hsl(216deg 20% 95% / 1)",
  ice: "hsl(216deg 33% 97% / 1)",
  java: "hsl(180deg 68% 43% / 1)",
  lemonChiffon: "hsl(54deg 100% 88% / 1)",
  lightBlue: "hsl(217deg 100% 76% / 1)",
  night: "hsl(243deg 26% 13% / 1)",
  oldGold: "hsl(46deg 100% 36% / 1)",
  orange: "hsl(15deg 100% 57% / 1)",
  pastelPink: "hsl(354deg 100% 90% / 1)",
  pearlBush: "hsl(16deg 24% 87% / 1)",
  periwinkle: "hsl(232deg 45% 84% / 1)",
  periwinkleGrey: "hsl(224deg 33% 84% / 1)",
  prelude: "hsl(261deg 46% 84% / 1)",
  red: "hsl(4deg 96% 57% / 1)",
  romantic: "hsl(14deg 100% 87% / 1)",
  sail: "hsl(207deg 89% 86% / 1)",
  skye: "hsl(219deg 100% 64% / 1)",
  skyeDark: "hsl(220deg 100% 54% / 1)",
  skyeLight: "hsl(218deg 100% 95% / 1)",
  slate: "hsl(242deg 9% 40% / 1)",
  snow: "hsl(0deg 0% 100% / 1)",
  stone: "hsl(0deg 0% 91% / 1)",
  swirl: "hsl(16deg 16% 81% / 1)",
  tusk: "hsl(65deg 69% 86% / 1)",
  zanah: "hsl(122deg 38% 84% / 1)"
};

export const platformPalette: Palette = {
  name: "platform",
  colors: {
    primary_400: colors.ebonyLight,
    primary_400_inverse: colors.snow,
    primary_500: colors.ebony,
    primary_500_inverse: colors.snow,
    primary_600: colors.night,
    primary_600_inverse: colors.snow,

    neutral_100: colors.snow,
    neutral_100_inverse: colors.ebony,
    neutral_200: colors.ice,
    neutral_200_inverse: colors.ebony,
    neutral_300: colors.grey,
    neutral_300_inverse: colors.ebony,
    neutral_400: colors.stone,
    neutral_400_inverse: colors.ebony,
    neutral_500: colors.periwinkleGrey,
    neutral_500_inverse: colors.ebony,
    neutral_600: colors.granite,
    neutral_600_inverse: colors.ebony,
    neutral_700: colors.darkGrey,
    neutral_700_inverse: colors.snow,
    neutral_800: colors.slate,
    neutral_800_inverse: colors.snow,
    neutral_900: colors.black,
    neutral_900_inverse: colors.snow,

    secondary_100: colors.skyeLight,
    secondary_100_inverse: colors.ebony,
    secondary_100_inverse_2: colors.skye,
    secondary_400: colors.lightBlue,
    secondary_400_inverse: colors.snow,
    secondary_400_inverse_2: colors.skye,
    secondary_500: colors.skye,
    secondary_500_inverse: colors.snow,
    secondary_600: colors.skyeDark,
    secondary_600_inverse: colors.snow,
    secondary_700: colors.deepBlue,
    secondary_700_inverse: colors.snow,
    secondary_800: colors.brandBlue,
    secondary_800_inverse: colors.snow,

    success_100: colors.blizzardBlue,
    success_100_inverse: colors.snow,
    success_500: colors.java,
    success_500_inverse: colors.snow,
    success_600: colors.elf,
    success_600_inverse: colors.snow,
    warning_100: colors.lemonChiffon,
    warning_100_inverse: colors.ebony,
    warning_500: colors.gold,
    warning_500_inverse: colors.snow,
    warning_600: colors.oldGold,
    warning_600_inverse: colors.snow,
    failure_100: colors.romantic,
    failure_100_inverse: colors.snow,
    failure_500: colors.fire,
    failure_500_inverse: colors.snow,
    failure_600: colors.orange,
    failure_600_inverse: colors.snow,
    error_500: colors.red,
    error_500_inverse: colors.snow,
    error_600: colors.deepRed,
    error_600_inverse: colors.snow,
    info_500: colors.skye,
    info_500_inverse: colors.snow,
    info_600: colors.deepBlue,
    info_600_inverse: colors.snow,

    auxiliary_1: colors.blueChalk,
    auxiliary_1_inverse: colors.ebony,
    auxiliary_2: colors.pastelPink,
    auxiliary_2_inverse: colors.ebony,
    auxiliary_3: colors.chantilly,
    auxiliary_3_inverse: colors.ebony,
    auxiliary_4: colors.frenchLilac,
    auxiliary_4_inverse: colors.ebony,
    auxiliary_5: colors.prelude,
    auxiliary_5_inverse: colors.ebony,
    auxiliary_6: colors.periwinkle,
    auxiliary_6_inverse: colors.ebony,
    auxiliary_7: colors.sail,
    auxiliary_7_inverse: colors.ebony,
    auxiliary_8: colors.frenchPass,
    auxiliary_8_inverse: colors.ebony,
    auxiliary_9: colors.aquaIsland,
    auxiliary_9_inverse: colors.ebony,
    auxiliary_10: colors.zanah,
    auxiliary_10_inverse: colors.ebony,
    auxiliary_11: colors.frost,
    auxiliary_11_inverse: colors.ebony,
    auxiliary_12: colors.tusk,
    auxiliary_12_inverse: colors.ebony,
    auxiliary_13: colors.buttermilk,
    auxiliary_13_inverse: colors.ebony,
    auxiliary_14: colors.frangipani,
    auxiliary_14_inverse: colors.ebony,
    auxiliary_15: colors.pearlBush,
    auxiliary_15_inverse: colors.ebony,
    auxiliary_16: colors.swirl,
    auxiliary_16_inverse: colors.ebony
  }
};

export const defaultThemeDefinition: ThemeDefinition = {
  name: "default",
  modes: {
    light: {
      ui_background: "neutral_100",
      header_background: "primary_400",
      header_floatbox: "primary_500",
      card_background: "neutral_100",
      card_header: "neutral_200",
      overlay: "primary_400",

      text_navbar: "primary_500_inverse",
      text_navbar_secondary: "primary_500_inverse",
      text_body: "primary_500",
      text_body_secondary: "primary_400",
      text_body_tertiary: "neutral_600",

      button_primary_background: "secondary_500",
      button_primary_foreground: "secondary_500_inverse",
      button_primary_highlight: "secondary_600",
      button_primary_pressed: "secondary_700",
      button_primary_disabled: "secondary_400",

      button_secondary_foreground: "neutral_800",
      button_secondary_hover_background: "neutral_300",
      button_secondary_pressed_background: "secondary_400",
      button_secondary_disabled_foreground: "neutral_500",

      button_tertiary_border: "neutral_500",
      button_tertiary_foreground: "secondary_500",
      button_tertiary_hover_background: "secondary_500",
      button_tertiary_hover_foreground: "secondary_500_inverse",
      button_tertiary_active_background: "secondary_700",
      button_tertiary_disabled: "secondary_400",

      button_link_foreground: "secondary_500",
      button_link_focus: "secondary_600",
      button_link_active: "secondary_700",
      button_link_disabled: "secondary_400",

      checkbox_unselected_disabled: "neutral_300",
      checkbox_unselected: "neutral_600",
      checkbox_selected: "secondary_500",
      checkbox_error: "error_500",

      link: "secondary_500",
      link_visited: "secondary_600",
      link_background: "secondary_400",

      icon_primary: "primary_400",
      icon_primary_inverse: "primary_400_inverse",
      icon_secondary: "neutral_600",
      icon_active: "secondary_500",
      icon_success: "success_500",
      icon_success_inverse: "success_500_inverse",

      support_error: "error_500",
      support_error_text: "error_500_inverse",
      support_success: "success_500",
      support_success_text: "success_500_inverse",
      support_warning: "warning_500",
      support_warning_text: "warning_500_inverse",
      support_information: "info_500",
      support_information_text: "info_500_inverse",

      field_background: "neutral_200",
      field_text: "primary_500",
      field_placeholder: "neutral_600",
      field_active: "neutral_600",

      code_background: "primary_500_inverse",
      code_text: "primary_500",
      code_inline_background: "secondary_600",
      code_inline_text: "secondary_500",

      hover_row: "secondary_600",
      hover_shadow: "neutral_600",

      focus: "secondary_600",
      focus_outline: "secondary_600"
    },
    dark: {
      ui_background: "primary_600",
      header_background: "primary_600",
      header_floatbox: "primary_500",
      card_background: "primary_500",
      card_header: "primary_500",
      overlay: "primary_400",

      text_navbar: "primary_500_inverse",
      text_navbar_secondary: "primary_500_inverse",
      text_body: "primary_500_inverse",
      text_body_secondary: "neutral_600",
      text_body_tertiary: "primary_600",

      button_primary_background: "secondary_500",
      button_primary_foreground: "secondary_500_inverse",
      button_primary_highlight: "secondary_600",
      button_primary_pressed: "secondary_700",
      button_primary_disabled: "secondary_400",

      button_secondary_foreground: "neutral_800",
      button_secondary_hover_background: "neutral_300",
      button_secondary_pressed_background: "secondary_400",
      button_secondary_disabled_foreground: "neutral_500",

      button_tertiary_border: "primary_400",
      button_tertiary_foreground: "secondary_500",
      button_tertiary_hover_background: "secondary_500",
      button_tertiary_hover_foreground: "secondary_500_inverse",
      button_tertiary_active_background: "secondary_700",
      button_tertiary_disabled: "secondary_400",

      button_link_foreground: "secondary_500",
      button_link_focus: "secondary_600",
      button_link_active: "secondary_700",
      button_link_disabled: "secondary_400",

      checkbox_unselected_disabled: "neutral_300",
      checkbox_unselected: "neutral_700",
      checkbox_selected: "secondary_500",
      checkbox_error: "error_500",

      link: "secondary_500",
      link_visited: "secondary_600",
      link_background: "secondary_400",

      icon_primary: "neutral_500",
      icon_primary_inverse: "neutral_500_inverse",
      icon_secondary: "neutral_100",
      icon_active: "secondary_500",
      icon_success: "success_500",
      icon_success_inverse: "success_500_inverse",

      support_error: "error_500",
      support_error_text: "error_500_inverse",
      support_success: "success_500",
      support_success_text: "success_500_inverse",
      support_warning: "warning_500",
      support_warning_text: "warning_500_inverse",
      support_information: "info_500",
      support_information_text: "info_500_inverse",

      field_background: "neutral_200",
      field_text: "primary_500",
      field_placeholder: "neutral_600",
      field_active: "neutral_600",

      code_background: "primary_500",
      code_text: "primary_500_inverse",
      code_inline_background: "secondary_600",
      code_inline_text: "secondary_500",

      hover_row: "secondary_600",
      hover_shadow: "neutral_600",

      focus: "secondary_600",
      focus_outline: "secondary_600"
    }
  }
};

export default createTheme(defaultThemeDefinition, platformPalette);
