import styled from "styled-components";

const calcColumn = (columnSize, totalColumns) => {
  return columnSize * totalColumns + 2.3529 * (totalColumns - 1);
};
export default styled.div`
  width: 100%;
  margin: 0 auto;
  // Only use columns for areas that should be a fixed width.
  // The main area should have a fluid width.
  .col-1 {
    @media (min-width: 1024px) {
      width: 6.17647%;
      min-width: 6.17647%;
    }
  }
  .col-2 {
    @media (min-width: 1024px) {
      width: ${calcColumn(6.17647, 2)}%;
      min-width: ${calcColumn(6.17647, 2)}%;
    }
  }
  .col-3 {
    @media (min-width: 1024px) {
      width: ${calcColumn(6.17647, 3)}%;
      min-width: ${calcColumn(6.17647, 3)}%;
    }
  }
  .col-4 {
    @media (min-width: 1024px) {
      width: ${calcColumn(6.17647, 4)}%;
      min-width: ${calcColumn(6.17647, 4)}%;
    }
  }
  .col-5 {
    @media (min-width: 1024px) {
      width: ${calcColumn(6.17647, 5)}%;
      min-width: ${calcColumn(6.17647, 5)}%;
    }
  }
  .col-6 {
    @media (min-width: 1024px) {
      width: ${calcColumn(6.17647, 6)}%;
      min-width: ${calcColumn(6.17647, 6)}%;
    }
  }
  .col-7 {
    @media (min-width: 1024px) {
      width: ${calcColumn(6.17647, 7)}%;
      min-width: ${calcColumn(6.17647, 7)}%;
    }
  }
  .col-8 {
    @media (min-width: 1024px) {
      width: ${calcColumn(6.17647, 8)}%;
      min-width: ${calcColumn(6.17647, 8)}%;
    }
  }
  .col-9 {
    @media (min-width: 1024px) {
      width: ${calcColumn(6.17647, 9)}%;
      min-width: ${calcColumn(6.17647, 9)}%;
    }
  }
  .col-10 {
    @media (min-width: 1024px) {
      width: ${calcColumn(6.17647, 10)}%;
      min-width: ${calcColumn(6.17647, 10)}%;
    }
  }
  .col-11 {
    @media (min-width: 1024px) {
      width: ${calcColumn(6.17647, 11)}%;
      min-width: ${calcColumn(6.17647, 11)}%;
    }
  }
  .col-12 {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }
  &.create-project-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;
