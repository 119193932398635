import {
  ValidModes,
  ModeKeys,
  ColorValues,
  ModeDefinition,
  Palette,
  Theme,
  ThemeDefinition
} from "../types/themes.types";

/**
 * Generates a theme by comibining a ThemeDefinition and a palette
 * This way one theme definition can be used to generate multiple themes
 * by just switching the palette, if you want something more custom, you could
 * provide a new theme definition
 * @param themeDefinition
 * @param colorPalette
 */
export function createTheme(
  themeDefinition: ThemeDefinition,
  colorPalette: Palette
): Theme {
  return (Object.entries(themeDefinition.modes) as Array<
    [ValidModes, ModeDefinition]
  >).reduce(
    (modeAccumulator, [modeName, mode]) => ({
      ...modeAccumulator,
      [modeName]: (Object.entries(mode) as Array<
        [ModeKeys, ColorValues]
      >).reduce((ruleAccumulator, [rule, colorName]) => {
        const color = colorPalette.colors[colorName];

        if (!color) {
          throw new Error(
            `No color named ${colorName} in ${colorPalette.name} palette`
          );
        }

        return {
          ...ruleAccumulator,
          [rule]: color
        };
      }, {})
    }),
    {} as Theme
  );
}
