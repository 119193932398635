import React from "react";
import ReactDOM from "react-dom";
import styled, { withTheme } from "styled-components";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { semiBoldAlias } from "Libs/theme";

import ChevronIcon from "Icons/ChevronIcon";

const Header = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  &.header-arrow-list {
    width: 100%;
    box-sizing: border-box;
  }
  a {
    text-decoration: none;
    ${semiBoldAlias};
  }
  span.chevron.open {
    transform: rotate(180deg);
  }
  a.chevron-link:focus {
    svg path {
      fill: #fff;
    }
  }
`;

const ChevronButton = styled.button`
  background-color: transparent;
  border: none;
  height: 100%;
  cursor: pointer;
`;

class AnimatedHeaderWithArrow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.closeDropDown = this.closeDropDown.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside);
    if (this.props.isOpen) {
      this.setState({
        isOpen: this.props.isOpen
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  handleClickOutside(e) {
    if (
      !ReactDOM.findDOMNode(this).contains(e.target) &&
      e.target.id !== "navbar-projects"
    ) {
      this.setState({
        isOpen: false
      });
      this.props.closeDropDown && this.props.closeDropDown();
    }
  }

  closeDropDown() {
    this.setState({
      isOpen: false
    });
  }

  render() {
    const {
      headerPadding,
      headerBackgroundColor,
      isOpen,
      isAriaCurrent,
      onArrowClick,
      arrowClick = false,
      onHeaderClick = onArrowClick,
      children,
      animate,
      hideArrow,
      arrowFilter,
      prefixId,
      listType = false,
      ariaLinkLabel,
      ariaToggleLabel,
      className,
      goTo,
      theme,
      tabIndex = "0"
    } = this.props;
    return (
      <Header
        headerPadding={headerPadding}
        headerBackgroundColor={headerBackgroundColor}
        className={className}
      >
        <Link
          id={`${prefixId}-link`}
          onClick={e => {
            if (!goTo) {
              e.stopPropagation();
              e.preventDefault();
              onHeaderClick();
              document.activeElement.blur();
            }
          }}
          onKeyUp={e => {
            if (
              e.keyCode === 13 ||
              e.keyCode === 27 ||
              e.keyCode === 38 ||
              e.keyCode === 40
            ) {
              if (!goTo) {
                e.stopPropagation();
                e.preventDefault();
                onHeaderClick();
                document.activeElement.blur();
              }
            }
          }}
          className={`header-arrow${listType ? " header-arrow-list" : ""}${
            isOpen ? " header-arrow-open" : ""
          }`}
          tabIndex={tabIndex}
          to={goTo ? goTo : "#"}
          role={listType ? null : "menuitem"}
          aria-current={isAriaCurrent ? "page" : null}
          aria-label={ariaLinkLabel}
        >
          {children}
          {animate && (
            <ChevronIcon
              id={`${prefixId}-chevron`}
              isOpen={isOpen}
              animate={animate}
              hideArrow={hideArrow}
              filter={arrowFilter}
              className={`chevron${isOpen ? " open" : ""}`}
              aria-label={ariaToggleLabel}
              color={theme && theme.name === "dark" ? "#c9d0e4" : "#5f5e70"}
            />
          )}
        </Link>
        {!animate &&
          arrowClick && (
            <ChevronButton
              onClick={e => {
                e.stopPropagation();
                (onArrowClick || onHeaderClick)();
              }}
              onKeyUp={e => {
                if (
                  e.keyCode === 13 ||
                  e.keyCode === 27 ||
                  e.keyCode === 38 ||
                  e.keyCode === 40
                ) {
                  onArrowClick();
                }
              }}
              className="chevron-link"
              tabIndex="0"
              aria-haspopup="true"
              aria-expanded={isOpen}
              aria-label="toggle dropdown"
            >
              <ChevronIcon
                id={`${prefixId}-chevron`}
                isOpen={isOpen}
                hideArrow={hideArrow}
                filter={arrowFilter}
                className={`chevron${isOpen ? " open" : ""}`}
                color={theme && theme.name === "dark" ? "#c9d0e4" : "#5f5e70"}
              />
            </ChevronButton>
          )}
        {!animate &&
          !arrowClick && (
            <ChevronIcon
              id={`${prefixId}-chevron`}
              isOpen={isOpen}
              hideArrow={hideArrow}
              filter={arrowFilter}
              className={`chevron${isOpen ? " open" : ""}`}
              color={theme && theme.name === "dark" ? "#c9d0e4" : "#5f5e70"}
            />
          )}
      </Header>
    );
  }
}

AnimatedHeaderWithArrow.propTypes = {
  isOpen: PropTypes.bool,
  isAriaCurrent: PropTypes.bool,
  onHeaderClick: PropTypes.func,
  onArrowClick: PropTypes.func,
  arrowClick: PropTypes.bool,
  headerPadding: PropTypes.string,
  bodyPadding: PropTypes.string,
  header: PropTypes.node,
  children: PropTypes.node,
  hideArrow: PropTypes.bool,
  animate: PropTypes.bool,
  headerBackgroundColor: PropTypes.string,
  bodyBackgroundColor: PropTypes.string,
  arrowFilter: PropTypes.string,
  prefixId: PropTypes.string.isRequired,
  listType: PropTypes.bool,
  ariaLinkLabel: PropTypes.string,
  ariaToggleLabel: PropTypes.string,
  className: PropTypes.string,
  goTo: PropTypes.string,
  closeDropDown: PropTypes.func,
  tabIndex: PropTypes.string,
  theme: PropTypes.object
};

export default withTheme(AnimatedHeaderWithArrow);
