import styled from "styled-components";

import { semiBoldAlias, themeHelpers } from "Libs/theme";

export const DIALOG_WIDTH = 300;

export const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  height: ${props => props.height};
  width: ${props => props.width};
  display: inline-block;
  margin-right: 12px;
  flex-shrink: 0;
  svg {
    height: auto;
    width: 100%;
    > path {
      fill: ${props => props.theme.iconColor};
    }
  }
  &:hover,
  &:focus {
    outline: none;
    svg > path {
      fill: ${props => props.theme.iconHoverColor};
    }
  }
`;

export const DialogContent = styled.div`
  background: ${props => themeHelpers(props.theme, "dropdownColor")};
  border-radius: 2px;
  width: ${DIALOG_WIDTH}px;
  box-shadow: ${props => props.theme.menuDropShadow};
  padding: 16px;
  line-height: 22px;
  font-size: 14px;
  font-weight: normal;
  text-transform: none;
  z-index: 11;
  letter-spacing: 0;
  box-sizing: border-box;

  a {
    color: ${props => props.theme.links};
    text-decoration: none;
    padding: 0 8px;
    margin: 0 -8px -6px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
    box-sizing: border-box;
    display: inline-block;
    &:hover {
      text-decoration: underline;
    }
    &:focus {
      outline: none;
      border: 1px solid ${props => themeHelpers(props.theme, "buttonHover")};
      border-radius: 4px;
      box-shadow: 0 0 2px ${props => themeHelpers(props.theme, "buttonHover")};
      text-decoration: none;
      margin: -1px -9px -7px;
    }
    &:active {
      border: none;
      box-shadow: none;
      text-decoration: underline;
      margin: 0 -8px;
    }
  }
  a + a {
    margin-left: 16px;
    &:focus {
      margin-left: 15px;
    }
    &:active {
      margin-left: 16px;
    }
  }
`;

export const DialogWrapper = styled.div`
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  padding-top: 8px;
  z-index: 100;
`;

export const Title = styled.div`
  margin-bottom: 4px;
  white-space: normal;
  color: ${props => props.theme.sectionText};
  ${semiBoldAlias};
`;

export const Text = styled.div`
  white-space: normal;
  color: ${props =>
    props.theme.name === "dark"
      ? props.theme.sectionText
      : themeHelpers(props.theme, "darkTint")};
`;
