import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const CloseIcon = ({ color = "#5f5e70" }) => {
  const uid = useUniqueId();

  return (
    <svg
      width="12px"
      height="12px"
      viewBox="0 0 12 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`close-icon-${uid}`}
    >
      <title id={`close-icon-${uid}`}>Close icon</title>
      <g
        id={`UI-Guide-${uid}`}
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id={`UI_guide_buttons-${uid}`}
          transform="translate(-535.000000, -1672.000000)"
          fill={color}
        >
          <g
            id={`icon-button-${uid}`}
            transform="translate(72.000000, 1620.000000)"
          >
            <g
              id={`icon-close-${uid}`}
              transform="translate(463.000000, 52.000000)"
            >
              <path
                d="M5.94974747,4.53553391 L10.1923882,0.292893219 C10.5829124,-0.0976310729 11.2160774,-0.0976310729 11.6066017,0.292893219 C11.997126,0.683417511 11.997126,1.31658249 11.6066017,1.70710678 L7.36396103,5.94974747 L11.6066017,10.1923882 C11.997126,10.5829124 11.997126,11.2160774 11.6066017,11.6066017 C11.2160774,11.997126 10.5829124,11.997126 10.1923882,11.6066017 L5.94974747,7.36396103 L1.70710678,11.6066017 C1.31658249,11.997126 0.683417511,11.997126 0.292893219,11.6066017 C-0.0976310729,11.2160774 -0.0976310729,10.5829124 0.292893219,10.1923882 L4.53553391,5.94974747 L0.292893219,1.70710678 C-0.0976310729,1.31658249 -0.0976310729,0.683417511 0.292893219,0.292893219 C0.683417511,-0.0976310729 1.31658249,-0.0976310729 1.70710678,0.292893219 L5.94974747,4.53553391 Z"
                id={`Combined-Shape-${uid}`}
              />
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

CloseIcon.propTypes = {
  color: PropTypes.string
};

export default CloseIcon;
