import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

import MaxWidthContainer from "Components/MaxWidthContainer";
import AppVersion from "Components/AppVersion";

import * as S from "./styles";

let announcementModal = null;

if (process.env.ENABLE_ANNOUNCEMENTS) {
  const AnnouncementModal = React.lazy(() =>
    import("Containers/announcements/Modal")
  );

  announcementModal = <AnnouncementModal />;
}

const PageLayout = ({ className = "", children, footer = true }) => {
  const theme = useContext(ThemeContext);

  return (
    <>
      <S.Main
        id="main"
        className={`main theme-${theme.name} ${className}`}
        role="main"
      >
        <MaxWidthContainer>
          {children}
          {announcementModal}
        </MaxWidthContainer>
      </S.Main>
      {footer && <AppVersion />}
    </>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  footer: PropTypes.bool
};

export default PageLayout;
