import styled from "styled-components";

import Btn from "UI/Button";

export const Button = styled(Btn)`
  svg path {
    fill: ${({ variant }) => `var(--button-${variant}-foreground, #000)`};
  }
`;

export const Text = styled.span`
  position: relative;

  span {
    margin: 0;
  }
`;

export const Copied = styled.span`
  position: absolute;
  top: 0;
  left: 0;
`;
