import React from "react";
import PropTypes from "prop-types";

import MaxWidthContainer from "Components/MaxWidthContainer";

const FloatBox = ({ children }) => (
  <div className="float-box-wrapper">
    <MaxWidthContainer>
      <div className="float-box">{children}</div>
    </MaxWidthContainer>
  </div>
);

FloatBox.propTypes = {
  children: PropTypes.node
};

export default FloatBox;
