import styled from "styled-components";
import { themeHelpers } from "Libs/theme";

export const Layout = styled.div`
  display: inline-block;
  position: relative;
  display: none;

  @media (min-width: 765px) {
    display: inline-block;
  }
  button {
    height: 32px;
    line-height: 32px;
    border-radius: 4px;
    background: transparent;
    border: none;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: inherit;
    padding: 0 35px 0 8px;
    white-space: nowrap;
    cursor: pointer;
    img {
      max-width: 24px;
      max-height: 24px;
      border-radius: 2px;
      overflow: hidden;
    }
    span.chevron {
      position: absolute;
      right: 3px;
      top: 6px;
      height: 24px;
      width: 24px;
    }
    &.picture {
      padding: 0 35px 0 3px;
    }
    &:hover,
    &:focus,
    &:active {
      background-color: rgba(255, 255, 255, 0.12);
      border-radius: 4px;
      outline: none;
    }
  }
  a {
    text-decoration: none;
    color: ${props => props.theme.sectionText};
    font-size: 15px;
  }
  .menu-dropdown {
    padding: 8px;
    width: 214px;
    min-width: 214px;
    box-sizing: border-box;
    left: auto;
    right: 0;
    overflow: hidden;
    margin-top: 8px;
    a {
      &:hover,
      &:focus,
      &:active {
        background: ${props =>
          props.theme.name === "contrast"
            ? "transparent"
            : themeHelpers(props.theme, "linkHover")};
        color: ${props =>
          props.theme.name === "contrast"
            ? props.theme.links
            : props.theme.sectionText};
        border: ${props =>
          props.theme.name === "contrast" ? "none" : "inherit"};
        box-shadow: ${props =>
          props.theme.name === "contrast" ? "none" : "inherit"};
        margin: ${props => (props.theme.name === "contrast" ? "0" : "inherit")};
        position: relative;
        span.active {
          position: absolute;
          top: 14px;
          left: 4px;
          display: ${props =>
            props.theme.name === "contrast" ? "inline-block" : "none"};
          height: 4px;
          width: 4px;
          border-radius: 4px;
          background: ${props => props.theme.links};
        }
      }
    }
  }
`;

export const OrganizationLabel = styled.div`
  color: ${props => props.theme.subNavTextRev};
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  padding: 0 8px;
  margin-top: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
`;

export const Separator = styled.hr`
  width: calc(100% + 16px);
  margin-left: -8px;
  overflow: hidden;
`;
