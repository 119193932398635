import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const isRelative = url => url && url[0] === "/";

const MenuLink = ({ url, label, children, ...props }) => {
  const labelEl = children || (
    <>
      <span className="active" />
      {label}
    </>
  );

  if (isRelative(url)) {
    return (
      <Link className="menu-item" to={url} {...props}>
        {labelEl}
      </Link>
    );
  }

  return (
    <a className="menu-item" href={url} {...props}>
      {labelEl}
    </a>
  );
};

MenuLink.propTypes = {
  label: PropTypes.string,
  url: PropTypes.string,
  children: PropTypes.node
};

export default MenuLink;
