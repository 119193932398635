import styled from "styled-components";

const Link = styled.a`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: ${props => props.theme.primary};
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

export default Link;
