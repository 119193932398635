import styled, { css } from "styled-components";
import { CustomCheckbox } from "@reach/checkbox";

export type LayoutProps = {
  isValid?: boolean;
};

const REACH_OVERRIDES = css`
  display: inline-block;
  position: relative;
  cursor: default;
  box-sizing: border-box;

  [data-reach-custom-checkbox-input] {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
    height: 100% !important;
    opacity: 0 !important;
    z-index: 1 !important;
    cursor: inherit;
    box-shadow: none !important;
    outline: none !important;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Layout = styled(({ isValid, ...props }) => (
  <CustomCheckbox {...props} />
))`
  ${REACH_OVERRIDES};
  margin-top: 3px;
  margin-right: 16px;
  margin-bottom: 3px;
  margin-left: 4px;
  height: 18px;
  width: 18px;
  border-radius: 2px;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: var(--checkbox-unselected, #000);
  opacity: 1;
  transition-duration: 0.25s;
  box-shadow: none;
  transition-property: border-color, opacity, box-shadow;

  :after {
    content: "";
    position: absolute;
    background-color: transparent;
    transition-duration: 0.25s;
    transition-property: background-color;
    top: 3px;
    left: 3px;
    height: 10px;
    width: 10px;
    z-index: 1;
  }

  &[data-state="checked"] {
    border-color: var(--checkbox-selected, #000);
    :after {
      background-color: var(--checkbox-selected, #000);
    }
  }

  :active,
  :focus-within,
  [data-focused] {
    box-shadow: 0px 0px 2px var(--checkbox-selected, #000),
      inset 0px 0px 1px var(--checkbox-selected, #000);
    border-color: var(--checkbox-selected, #000);
    outline: none;
  }

  :hover {
    border-color: var(--checkbox-selected, #000);
  }

  ${({ isValid }) =>
    !isValid &&
    css`
      border-color: var(--checkbox-error, #ff0000);

      &[data-state="checked"] {
        border-color: var(--checkbox-error, #ff0000);
        :after {
          background-color: var(--checkbox-error, #ff0000);
        }
      }
      :focus-within,
      [data-focused] {
        box-shadow: 0px 0px 2px var(--checkbox-error, #ff0000),
          inset 0px 0px 1px var(--checkbox-error, #ff0000);
        border-color: var(--checkbox-error, #ff0000);
      }

      :hover {
        border-color: var(--checkbox-error, #ff0000);
      }
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      border-color: var(--checkbox-unselected, #000);
      opacity: 0.8;

      :not([data-state="checked"]):hover {
        border-color: var(--checkbox-unselected, #000);
      }
    `};
`;
