import { fromJS, Map, List } from "immutable";

import { request } from "Libs/platform";
import config from "Constants/api_config";
import logger from "Libs/logger";
import { normalize } from "Libs/utils";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const getAllPlans = async (
  href = `${config.api_url}/v1/plans`,
  planList = new List()
) => {
  const resultFromHref = await request(href, "GET").catch(err => logger(err));
  const plans = planList.concat(fromJS(resultFromHref?.plans));

  if (resultFromHref?._links?.next) {
    getAllPlans(resultFromHref._links.next.href, plans);
  }
  return plans.toJS();
};

export const getPlans = createAsyncThunk("plans/list", async () => {
  const planList = await getAllPlans();

  const plans = normalize(planList, "name");
  const planNamesBySize = planList.map(plan => plan?.name);

  return {
    plans,
    planNamesBySize
  };
});

const plan = createSlice({
  name: "plan",
  initialState: Map(),
  extraReducers: {
    [getPlans.pending]: state => state.set("loading", true),
    [getPlans.fulfilled]: (state, action) =>
      state
        .set("data", fromJS(action.payload.plans))
        .set("planNamesBySize", fromJS(action.payload.planNamesBySize))
        .set("loading", false),
    [getPlans.rejected]: (state, action) => state.set("errors", action.payload)
  }
});

export default plan.reducer;
