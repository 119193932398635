import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const WingsIcon = ({ color = "#5f5e70" }) => {
  const uid = useUniqueId();

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      className="icon-wings"
      role="img"
      aria-labelledby={`wings-icon-${uid}`}
    >
      <title id={`wings-icon-${uid}`}>Wings icon</title>
      <path
        d="M13.5,6.5c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1l5,0c0.6,0,1,0.5,1,1c0,0,0,5,0,5c0,0.6-0.5,1-1,1c-0.6,0-1-0.5-1-1l0-2.5
      l-4.8,4.7c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1,0-1.4l4.9-4.8L13.5,6.5z"
        fill={color}
        className="icon-wings-path"
      />
      <path
        d="M5.5,4.5c-0.6,0-1,0.4-1,1v13c0,0.6,0.4,1,1,1h13c0.6,0,1-0.4,1-1v-3c0-0.6-0.4-1-1-1c-0.6,0-1,0.4-1,1v2h-11v-11h2
      c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1H5.5z"
        fill={color}
        className="icon-wings-path"
      />
    </svg>
  );
};

WingsIcon.propTypes = {
  color: PropTypes.string
};

export default WingsIcon;
