import { FC } from "react";
import Icon, { IconProps } from "../../Icon";

const CodeIcon: FC<IconProps> = (props: IconProps) => (
  <Icon {...props} title="Code">
    <path d="M4.1999 8.4998L7.6999 11.9998L4.1999 15.4998C3.7999 15.8998 3.7999 16.4998 4.1999 16.8998C4.5999 17.2998 5.1999 17.2998 5.5999 16.8998L9.0999 13.3998L9.7999 12.6998C10.1999 12.2998 10.1999 11.6998 9.7999 11.2998L9.0999 10.5998L5.5999 7.0998C5.1999 6.6998 4.5999 6.6998 4.1999 7.0998C3.7999 7.3998 3.7999 8.0998 4.1999 8.4998Z" />
    <path d="M13.1001 17.2002H19.1001C19.7001 17.2002 20.1001 16.8002 20.1001 16.2002C20.1001 15.6002 19.7001 15.2002 19.1001 15.2002H13.1001C12.5001 15.2002 12.1001 15.6002 12.1001 16.2002C12.1001 16.8002 12.6001 17.2002 13.1001 17.2002Z" />
  </Icon>
);

export default CodeIcon;
