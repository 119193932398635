import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CustomScroll from "react-custom-scroll";

import MenuDropdownWrapper from "Components/MenuDropdownWrapper";

import {
  MenuLayout,
  Empty,
  HeaderText,
  Active
} from "./OrganizationMenu.styles";

const OrganizationMenu = ({
  objects = [],
  isOpen,
  onClick,
  width,
  prefixId,
  className,
  toggleMenu,
  selectedId,
  urlPrefix,
  allLabel,
  headerText
}) => {
  useEffect(() => {
    const awayListener = e => {
      if (e.cancelBubble) {
        return false;
      }
    };

    document.addEventListener("click", awayListener);

    return () => document.removeEventListener("click", awayListener);
  }, []);

  let listCount = objects ? objects.count() : 0;
  return (
    <MenuDropdownWrapper
      className={className ? className : ""}
      isOpen={isOpen}
      width={width}
      onClick={e => e.stopPropagation()}
      onKeyUp={e => e.keyCode === 27 && isOpen && toggleMenu()}
    >
      <MenuLayout className="dropdown-links" role="menu" aria-label="projects">
        {listCount > 10 ? (
          <CustomScroll heightRelativeToParent="332px">
            <ul>
              <HeaderText key="my-orgs">{headerText}</HeaderText>
              <li key="all">
                <Link
                  id={`${prefixId}-allprojects`}
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    onClick(e, "");
                    toggleMenu();
                  }}
                  className={`menu-item${"" === selectedId ? " active" : ""}`}
                  tabIndex="0"
                  to="#"
                  href="/"
                  role="menuitem"
                  aria-label={allLabel}
                >
                  {"" === selectedId && <Active />}
                  <span className={"" === selectedId && `highlight`}>
                    {allLabel}
                  </span>
                </Link>
              </li>
              {objects.map(object => (
                <li key={object.id}>
                  <Link
                    id={`${prefixId}-${object.id}`}
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      onClick(e, object.id);
                      toggleMenu();
                    }}
                    className={`menu-item${
                      object.id === selectedId ? " active" : ""
                    }`}
                    tabIndex="0"
                    to="#"
                    href={`${urlPrefix}/${object.id}`}
                    role="menuitem"
                    aria-label={object.name || object.title}
                  >
                    {object.id === selectedId && <Active />}
                    <span className={object.id === selectedId && `highlight`}>
                      {object.label || object.name
                        ? object.label || object.name
                        : "Organization Title"}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          </CustomScroll>
        ) : listCount === 0 ? (
          <Empty>No results.</Empty>
        ) : (
          <div className="no-scroll">
            <ul>
              <HeaderText key="my-orgs">{headerText}</HeaderText>
              <li key="all">
                <Link
                  id={`${prefixId}-allprojects`}
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    onClick(e, "");
                    toggleMenu();
                  }}
                  className={`menu-item${"" === selectedId ? " active" : ""}`}
                  tabIndex="0"
                  to="#"
                  href="/"
                  role="menuitem"
                  aria-label={allLabel}
                >
                  {"" === selectedId && <Active />}
                  <span className={"" === selectedId ? "highlight" : ""}>
                    {allLabel}
                  </span>
                </Link>
              </li>
              {objects.map(object => (
                <li key={object.id}>
                  <Link
                    id={`${prefixId}-${object.id}`}
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      onClick(e, object.id);
                      toggleMenu();
                    }}
                    className={`menu-item${
                      object.id === selectedId ? " active" : ""
                    }`}
                    tabIndex="0"
                    to="#"
                    href={`${urlPrefix}/${object.id}`}
                    role="menuitem"
                    aria-label={object.name || object.title}
                  >
                    {object.id === selectedId && <Active />}
                    <span
                      className={object.id === selectedId ? "highlight" : ""}
                    >
                      {object.label || object.name
                        ? object.label || object.name
                        : "Organization Title"}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </MenuLayout>
    </MenuDropdownWrapper>
  );
};

OrganizationMenu.propTypes = {
  objects: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
  width: PropTypes.string,
  prefixId: PropTypes.string,
  className: PropTypes.string,
  toggleMenu: PropTypes.func,
  selectedId: PropTypes.string,
  urlPrefix: PropTypes.string,
  allLabel: PropTypes.string,
  headerText: PropTypes.string
};

export default OrganizationMenu;
