import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const LockIcon = ({ color = "#5f5e70" }) => {
  const uid = useUniqueId();

  return (
    <svg
      version="1.1"
      id={`Layer_1-${uid}`}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style={{ enableBackground: "new 0 0 24 24" }}
      role="img"
      aria-labelledby={`lock-icon-${uid}`}
    >
      <title id={`lock-icon-${uid}`}>Lock icon</title>
      <metadata>
        <sfw xmlns="&ns_sfw;">
          <slices />
          <sliceSourceBounds height="298" width="547.1" x="175.9" y="-466" />
        </sfw>
      </metadata>
      <g>
        <defs>
          <path
            id={`${uid}-SVGID_1_`}
            d="M532.4,277.3h-4.6v-1.5c0-1.3,1-2.4,2.3-2.4c1.3,0,2.3,1.1,2.3,2.4V277.3z M530.1,284.2
			c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5C531.6,283.5,530.9,284.2,530.1,284.2z M534.6,277.3h-0.8
			v-1.5c0-2.1-1.7-3.8-3.8-3.8c-2.1,0-3.8,1.7-3.8,3.8v1.5h-0.8c-0.8,0-1.5,0.7-1.5,1.5v7.6c0,0.8,0.7,1.5,1.5,1.5h9
			c0.8,0,1.5-0.7,1.5-1.5v-7.6C536.1,278,535.4,277.3,534.6,277.3z"
          />
        </defs>
        <clipPath id={`${uid}-SVGID_2_`}>
          <use xlinkHref={`#${uid}-SVGID_1_`} style={{ overflow: "visible" }} />
        </clipPath>
        <g style={{ clipPath: `url(#${uid}-SVGID_2_)` }}>
          <defs>
            <rect
              id={`${uid}-SVGID_3_`}
              x="518.1"
              y="269"
              width="24"
              height="24"
            />
          </defs>
          <clipPath id={`${uid}-SVGID_4_`}>
            <use
              xlinkHref={`#${uid}-SVGID_3_`}
              style={{ overflow: "visible" }}
            />
          </clipPath>
          <g
            style={{
              clipPath: `url(#${uid}-SVGID_4_)`,
              enableBackground: "new"
            }}
          >
            <g>
              <defs>
                <rect
                  id={`${uid}-SVGID_5_`}
                  x="518.1"
                  y="267"
                  width="24"
                  height="26"
                />
              </defs>
              <clipPath id={`${uid}-SVGID_6_`}>
                <use
                  xlinkHref={`#${uid}-SVGID_5_`}
                  style={{ overflow: "visible" }}
                />
              </clipPath>
              <g style={{ clipPath: `url(#${uid}-SVGID_6_)` }}>
                <defs>
                  <rect
                    id={`${uid}-SVGID_7_`}
                    x="524.1"
                    y="272"
                    width="12"
                    height="16"
                  />
                </defs>
                <clipPath id={`${uid}-SVGID_8_`}>
                  <use
                    xlinkHref={`#${uid}-SVGID_7_`}
                    style={{ overflow: "visible" }}
                  />
                </clipPath>
                <rect
                  x="519.1"
                  y="267"
                  style={{ clipPath: `url(#${uid}-SVGID_8_)` }}
                  width="22"
                  height="26"
                />
              </g>
              <g style={{ clipPath: `url(#${uid}-SVGID_6_)` }}>
                <defs>
                  <rect
                    id={`${uid}-SVGID_9_`}
                    x="518.1"
                    y="269"
                    width="24"
                    height="24"
                  />
                </defs>
                <clipPath id={`${uid}-SVGID_10_`}>
                  <use
                    xlinkHref={`#${uid}-SVGID_9_`}
                    style={{ overflow: "visible" }}
                  />
                </clipPath>
                <g style={{ clipPath: `url(#${uid}-SVGID_10_)` }}>
                  <defs>
                    <rect
                      id={`${uid}-SVGID_11_`}
                      x="524.1"
                      y="272"
                      width="12"
                      height="16"
                    />
                  </defs>
                  <clipPath id={`${uid}-SVGID_12_`}>
                    <use
                      xlinkHref={`#${uid}-SVGID_11_`}
                      style={{ overflow: "visible" }}
                    />
                  </clipPath>
                  <rect
                    x="513.1"
                    y="264"
                    style={{ clipPath: `url(#${uid}-SVGID_12_)` }}
                    width="34"
                    height="34"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g fill={color}>
        <defs>
          <path
            id={`${uid}-SVGID_13_`}
            d="M14.3,9.3H9.7V7.8c0-1.3,1-2.4,2.3-2.4s2.3,1.1,2.3,2.4V9.3z M12,16.2c-0.8,0-1.5-0.7-1.5-1.5
			s0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5S12.8,16.2,12,16.2z M16.5,9.3h-0.8V7.8C15.8,5.7,14.1,4,12,4S8.2,5.7,8.2,7.8v1.5H7.5
			C6.7,9.3,6,10,6,10.9v7.6C6,19.3,6.7,20,7.5,20h9c0.8,0,1.5-0.7,1.5-1.5v-7.6C18,10,17.3,9.3,16.5,9.3z"
          />
        </defs>
        <clipPath id={`${uid}-SVGID_14_`}>
          <use
            xlinkHref={`#${uid}-SVGID_13_`}
            style={{ overflow: "visible" }}
          />
        </clipPath>
        <g
          style={{
            clipPath: `url(#${uid}-SVGID_14_)`,
            enableBackground: "new"
          }}
        >
          <g>
            <defs>
              <rect
                id={`${uid}-SVGID_15_`}
                x="1"
                y="-1"
                width="22"
                height="26"
              />
            </defs>
            <clipPath id={`${uid}-SVGID_16_`}>
              <use
                xlinkHref={`#${uid}-SVGID_15_`}
                style={{ overflow: "visible" }}
              />
            </clipPath>
            <g style={{ clipPath: `url(#${uid}-SVGID_16_)` }}>
              <defs>
                <rect
                  id={`${uid}-SVGID_17_`}
                  x="6"
                  y="4"
                  width="12"
                  height="16"
                />
              </defs>
              <clipPath id={`${uid}-SVGID_18_`}>
                <use
                  xlinkHref={`#${uid}-SVGID_17_`}
                  style={{ overflow: "visible" }}
                />
              </clipPath>
              <rect
                x="1"
                y="-1"
                style={{ clipPath: `url(#${uid}-SVGID_18_)` }}
                width="22"
                height="26"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

LockIcon.propTypes = {
  color: PropTypes.string
};

export default LockIcon;
