import { ANALYTICS_ENABLED } from "Constants/constants";
import googleTagManagerTracker from "./googleTagManagerTracker";
import driftChatbot from "./driftChatbot";

// This is the middleware we are hooking to any dispatched action
// if analytics are enabled then we pass the action and a copy of the
// store to the tracker functions
function analytics(store) {
  // We want to return a function here instead of the middleware directly,
  // so we can also push data that is only in the store (and not in the action)
  // to our trackers
  return function analyticsMiddleware(next) {
    return function handleAnalyticsAction(action) {
      // If analytics are not enabled we don't want to run the trackers
      // so we just pass the action to the next middleware
      if (!ANALYTICS_ENABLED) return next(action);

      googleTagManagerTracker(action, store);
      driftChatbot(action, store);

      next(action);
    };
  };
}

export default analytics;
