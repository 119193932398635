import { useMemo } from "react";
import { useParams } from "react-router-dom";

const useDecodedParams = () => {
  const params = useParams();

  const decodedParams = useMemo(
    () => {
      const entries = Object.entries(params);
      const decodedParams = entries.reduce((params, [key, value]) => {
        params[key] = value ? decodeURIComponent(value) : value;
        return params;
      }, {});
      return decodedParams;
    },
    [params]
  );

  return decodedParams;
};

export default useDecodedParams;
