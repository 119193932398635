import styled, { css } from "styled-components";

import { Variants, Variant, Content, Contents } from "./types";

const SIDE_PADDING_LG = 24;
const SIDE_PADDING_MD = 16;
const SIDE_PADDING_SM = 8;

export type LayoutProps = {
  /** One of the valid variants */
  variant?: Variants;
  disabled?: boolean;
  content?: Contents;
  type?: "button" | "submit" | "reset";
};

export const __primary = css<LayoutProps>`
  transition-duration: 0.25s;
  transition-property: color, background-color, border-color, box-shadow;
  padding-left: ${SIDE_PADDING_LG}px;
  padding-right: ${SIDE_PADDING_LG}px;
  background-color: var(--button-primary-background, #fff);
  color: var(--button-primary-foreground, #000);
  box-shadow: none;

  :focus {
    border-color: var(--button-primary-highlight, #eee);
    box-shadow: 0px 0px 2px var(--button-primary-highlight, #eee);
  }

  :hover {
    background-color: var(--button-primary-highlight, #eee);
  }

  :active {
    background-color: var(--button-primary-pressed, #ddd);
    box-shadow: none;
    border-color: transparent;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: var(--button-primary-disabled, #eee);
    `};
`;

export const __secondary = css<LayoutProps>`
  transition-duration: 0.25s;
  transition-property: color, border-color, box-shadow, background-color;
  padding-left: ${SIDE_PADDING_LG}px;
  padding-right: ${SIDE_PADDING_LG}px;
  background-color: transparent;
  color: var(--button-secondary-foreground, #000);

  :focus {
    border-color: var(--button-primary-highlight, #eee);
    box-shadow: 0px 0px 2px var(--button-primary-highlight, #eee),
      inset 0 0 2px var(--button-primary-highlight, #eee);
  }

  :hover {
    background-color: var(--button-secondary-hover-background, #eee);
  }

  :active {
    border-color: transparent;
    box-shadow: none;
    background-color: var(--button-secondary-pressed-background, #ddd);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: var(--button-secondary-disabled-foreground, #eee);
    `};
`;

export const __tertiary = css<LayoutProps>`
  transition-duration: 0.25s;
  transition-property: border-color, color, background-color;

  padding-left: ${SIDE_PADDING_LG}px;
  padding-right: ${SIDE_PADDING_LG}px;
  background-color: transparent;
  border-color: var(--button-tertiary-border, #000);
  color: var(--button-tertiary-foreground, #000);

  :focus {
    border-color: var(--button-primary-highlight, #eee);
    box-shadow: 0px 0px 2px var(--button-primary-highlight, #eee),
      inset 0 0 2px var(--button-primary-highlight, #eee);
  }

  :hover {
    color: var(--button-tertiary-hover-foreground, #111);
    border-color: transparent;
    background-color: var(--button-tertiary-hover-background, #eee);
  }

  :active {
    border-color: transparent;
    box-shadow: none;
    background-color: var(--button-tertiary-active-background, #ddd);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: var(--button-tertiary-disabled-background, #eee);
    `};
`;

const SIDE_PADDING_LINK = 0;

export const __link = css<LayoutProps>`
  padding-left: ${SIDE_PADDING_LINK}px;
  padding-right: ${SIDE_PADDING_LINK}px;
  transition-duration: 0.25s;
  transition-property: border-color, box-shadow, color;
  font-weight: 400;
  color: var(--button-link-foreground, #000);
  background-color: transparent;
  height: 24px;
  position: relative;

  :after {
    transition-duration: 0.25s;
    transition-property: background-color;
    content: "";
    position: absolute;
    top: 19.17px;
    left: ${SIDE_PADDING_LINK}px;
    height: 1px;
    width: calc(100% - ${SIDE_PADDING_LINK * 2}px);
    background-color: transparent;
  }

  :focus {
    border-color: var(--button-link-focus, #111);
    box-shadow: 0px 0px 2px var(--button-link-focus, #111),
      inset 0 0 2px var(--button-link-focus, #111);
  }

  :hover {
    color: var(--button-link-focus, #111);
  }

  :hover:after,
  :active:after {
    background-color: var(--button-link-active, #222);
  }

  :active {
    border-color: transparent;
    box-shadow: none;
    color: var(--button-link-active, #222);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: var(--button-link-disabled, #333);
    `}
`;

export const __mixed = css<LayoutProps>`
  padding-left: ${SIDE_PADDING_SM}px;
  padding-right: ${SIDE_PADDING_MD}px;

  & :first-child {
    display: inline-block;
    margin-right: 8px;
  }
`;

const __icon = css<LayoutProps>`
  height: 32px;
  width: 32px;
  padding: 0;

  & :first-child {
    display: inline-block;
    height: 24px;
    width: 24px;
  }
`;

const __defaultDisabled = css`
  cursor: not-allowed;
  pointer-events: none;
`;

export const Layout = styled.button.attrs<LayoutProps>(props => ({
  type: props.type || "button",
  "data-uikit": true
}))<LayoutProps>`
  box-sizing: border-box;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  mix-blend-mode: normal;
  border-radius: 2px;
  cursor: pointer;

  height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  /* button style resets */
  outline: none;
  border: 1px solid transparent;
  padding: 0;
  margin: 0;

  /* anchor style resets */
  text-decoration: none;

  ${({ disabled }) => disabled && __defaultDisabled};

  ${({ variant }) => variant === Variant.primary && __primary};
  ${({ variant }) => variant === Variant.secondary && __secondary};
  ${({ variant }) => variant === Variant.tertiary && __tertiary};
  ${({ variant }) => variant === Variant.link && __link};
  ${({ content }) => content === Content.mixed && __mixed};
  ${({ content }) => content === Content.icon && __icon};
`;

Layout.defaultProps = {
  variant: Variant.primary,
  content: Content.text
};
