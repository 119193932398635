import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const PlatformIconLg = ({ color = "#fff" }) => {
  const uid = useUniqueId();

  return (
    <svg
      width="172"
      height="34"
      viewBox="0 0 172 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`platform-sh-icon-${uid}`}
    >
      <title id={`platform-sh-icon-${uid}`}>Platform.sh icon</title>
      <path d="M172 0H143.553V11.2122H172V0Z" fill={color} />
      <path d="M172 24.4001H143.553V28.2196H172V24.4001Z" fill={color} />
      <path d="M172 14.8376H143.553V20.5908H172V14.8376Z" fill={color} />
      <path
        d="M0 13.927H3.59705V16.0656C4.07148 15.3254 4.72182 14.7138 5.49022 14.2851C6.29662 13.8246 7.2117 13.5876 8.14067 13.5988C8.9624 13.5915 9.77553 13.7661 10.5216 14.1101C11.2676 14.4541 11.9279 14.9589 12.4551 15.5882C13.7185 17.1492 14.3511 19.1255 14.2287 21.1286C14.355 23.1735 13.7198 25.1928 12.4452 26.7984C11.916 27.4279 11.2538 27.9327 10.5062 28.2767C9.75855 28.6206 8.94393 28.7952 8.12075 28.7878C7.36319 28.7971 6.61347 28.6338 5.92864 28.3103C5.14813 27.8911 4.44949 27.3352 3.86607 26.6691V34H0.00997382V13.9071L0 13.927ZM3.81626 20.9595C3.71879 22.2483 4.07082 23.531 4.81267 24.5902C5.09216 24.9558 5.45254 25.252 5.86564 25.4556C6.27874 25.6592 6.73337 25.7647 7.19407 25.7639C7.62553 25.7701 8.05255 25.6763 8.44156 25.4899C8.83057 25.3035 9.17097 25.0296 9.43599 24.6897C10.1201 23.6453 10.4325 22.4015 10.3228 21.1585C10.4162 19.9532 10.0926 18.7524 9.4061 17.7566C9.13746 17.4087 8.79151 17.1278 8.39552 16.9362C7.99953 16.7446 7.56437 16.6475 7.12435 16.6525C6.67602 16.6456 6.23189 16.7394 5.82483 16.9271C5.41778 17.1148 5.05822 17.3915 4.77281 17.7367C4.07965 18.663 3.74046 19.8058 3.81626 20.9595Z"
        fill={color}
      />
      <path
        d="M16.0122 28.5291V8.39648H19.8683V28.5291H16.0122Z"
        fill={color}
      />
      <path
        d="M25.4084 18.3733L21.911 17.7467C22.1898 16.5053 22.8948 15.4004 23.9038 14.6233C25.199 13.8684 26.6924 13.5217 28.1883 13.6286C29.499 13.5566 30.81 13.7635 32.0345 14.2354C32.7779 14.5521 33.4021 15.0952 33.818 15.7871C34.2445 16.8852 34.4215 18.0641 34.3362 19.2387V23.7347C34.3131 24.6835 34.3765 25.6323 34.5255 26.5696C34.6719 27.2598 34.9063 27.9283 35.223 28.559H31.4068C31.3071 28.3004 31.1776 27.9224 31.0381 27.4151C30.9683 27.1863 30.9285 27.0371 30.8986 26.9675C30.2896 27.5742 29.5734 28.0632 28.7862 28.4098C28.0262 28.7291 27.2094 28.8915 26.3848 28.8872C25.0879 28.9566 23.8151 28.5176 22.8376 27.6638C22.4142 27.2715 22.0795 26.7935 21.8562 26.2617C21.6328 25.7299 21.5258 25.1566 21.5423 24.5802C21.5276 23.8069 21.7313 23.045 22.1302 22.3819C22.5234 21.7362 23.0988 21.2206 23.7842 20.8998C24.7665 20.4767 25.7972 20.176 26.8532 20.0046C28.1302 19.7899 29.3845 19.4569 30.5997 19.0099V18.5822C30.6289 18.2947 30.595 18.0042 30.5003 17.7311C30.4057 17.4579 30.2526 17.2087 30.0516 17.0006C29.4326 16.6174 28.7038 16.4495 27.9791 16.5232C27.4151 16.4914 26.855 16.6338 26.3749 16.931C25.9071 17.2968 25.5684 17.8021 25.4084 18.3733ZM30.5698 21.4966C30.0716 21.6558 29.2944 21.8547 28.2182 22.0835C27.4872 22.1961 26.778 22.421 26.1158 22.75C25.8863 22.8958 25.6974 23.0973 25.5668 23.3355C25.4361 23.5738 25.3679 23.8411 25.3685 24.1127C25.367 24.3758 25.4203 24.6362 25.5249 24.8777C25.6296 25.1191 25.7833 25.3362 25.9763 25.5152C26.1802 25.71 26.4209 25.8621 26.6843 25.9629C26.9478 26.0637 27.2288 26.111 27.5108 26.1021C28.232 26.0949 28.9314 25.8541 29.5036 25.4158C29.9435 25.1059 30.2663 24.6575 30.4203 24.1425C30.5485 23.5246 30.5988 22.893 30.5698 22.2626V21.4966Z"
        fill={color}
      />
      <path
        d="M43.6626 13.927V17.0006H41.0221V22.8693C40.9992 23.5633 41.0259 24.258 41.1018 24.9482C41.1537 25.1481 41.278 25.3218 41.4506 25.4357C41.6394 25.5699 41.8665 25.6396 42.0982 25.6346C42.6334 25.593 43.1583 25.4654 43.6526 25.2566L43.9815 28.2407C43.0152 28.6386 41.9776 28.8349 40.9324 28.8176C40.2878 28.8274 39.6476 28.709 39.0492 28.4695C38.5629 28.29 38.1378 27.9761 37.8237 27.5643C37.5306 27.1109 37.3433 26.5976 37.2756 26.0623C37.1702 25.1612 37.1303 24.2537 37.1561 23.3468V17.0006H35.3824V13.927H37.1561V11.0324L41.0321 8.78442V13.927H43.6726H43.6626Z"
        fill={color}
      />
      <path
        d="M44.7886 13.927H46.9308V12.8328C46.884 11.9013 47.0159 10.9695 47.3195 10.0874C47.6177 9.44584 48.1278 8.92609 48.7642 8.61532C49.589 8.2047 50.5036 8.00636 51.4247 8.03838C52.5199 8.03826 53.6087 8.20599 54.653 8.53573L54.1349 11.2214C53.5544 11.0791 52.9591 11.0057 52.3613 11.0026C52.1444 10.981 51.9254 11.0043 51.7179 11.071C51.5105 11.1378 51.319 11.2465 51.1556 11.3905C50.8587 11.8319 50.728 12.3641 50.7869 12.8925V13.8872H53.6666V16.8713H50.7869V28.5291H46.9308V16.9608H44.7886V13.9767V13.927Z"
        fill={color}
      />
      <path
        d="M54.3939 21.0092C54.4143 19.7093 54.7566 18.4347 55.3903 17.2989C55.9877 16.1362 56.9216 15.1797 58.0706 14.5536C59.2569 13.8984 60.591 13.556 61.9467 13.5589C62.9498 13.5243 63.9492 13.6973 64.8821 14.0671C65.8149 14.4368 66.661 14.9953 67.3672 15.7074C68.0691 16.4212 68.619 17.2696 68.9838 18.2013C69.3485 19.1329 69.5206 20.1287 69.4895 21.1285C69.5222 22.1384 69.3502 23.1444 68.9838 24.0862C68.6173 25.028 68.0641 25.8862 67.3572 26.6093C66.6597 27.3237 65.8215 27.8861 64.8954 28.2611C63.9693 28.636 62.9755 28.8153 61.9766 28.7877C60.6476 28.7909 59.3366 28.4808 58.1504 27.8825C56.969 27.3118 55.9993 26.382 55.3804 25.2267C54.7064 23.9242 54.3674 22.4749 54.3939 21.0092ZM58.3795 21.218C58.2867 22.4106 58.6419 23.5949 59.3759 24.5403C59.6911 24.9028 60.0807 25.1936 60.5182 25.3928C60.9557 25.592 61.4309 25.6951 61.9118 25.6951C62.3927 25.6951 62.8679 25.592 63.3054 25.3928C63.7429 25.1936 64.1325 24.9028 64.4477 24.5403C65.1805 23.5866 65.5352 22.3967 65.4441 21.1982C65.5323 20.0153 65.1772 18.842 64.4477 17.9057C64.1316 17.5446 63.7417 17.2552 63.3043 17.057C62.867 16.8587 62.3922 16.7561 61.9118 16.7561C61.4314 16.7561 60.9567 16.8587 60.5193 17.057C60.0819 17.2552 59.692 17.5446 59.3759 17.9057C58.6324 18.8402 58.2632 20.0171 58.3397 21.2081L58.3795 21.218Z"
        fill={color}
      />
      <path
        d="M74.7406 28.5291H70.8845V13.9269H74.4616V15.9959C74.8717 15.2442 75.4352 14.5868 76.1157 14.0662C76.6121 13.7502 77.191 13.5875 77.7797 13.5987C78.6773 13.6034 79.5563 13.8547 80.3205 14.3248L79.1248 17.6869C78.5951 17.3033 77.9649 17.0821 77.3113 17.0503C76.8149 17.0358 76.3288 17.1941 75.9363 17.4979C75.4809 17.9305 75.171 18.4935 75.0495 19.1093C74.769 20.7213 74.6621 22.3586 74.7306 23.9933V28.5291H74.7406Z"
        fill={color}
      />
      <path
        d="M81.4763 13.927H85.0335V15.9164C85.56 15.2059 86.2443 14.6268 87.0326 14.2247C87.8209 13.8226 88.6919 13.6083 89.5771 13.5987C90.4239 13.5777 91.2616 13.7765 92.0083 14.1756C92.7217 14.5872 93.3051 15.1903 93.6923 15.9164C94.2565 15.2006 94.9619 14.6082 95.7648 14.1756C96.4965 13.7935 97.3105 13.5954 98.1363 13.5987C99.0847 13.5668 100.024 13.7901 100.856 14.2453C101.606 14.6866 102.191 15.3609 102.52 16.165C102.836 17.1268 102.971 18.1384 102.919 19.1491V28.5291H99.0729V20.2035C99.1569 19.2536 99.0205 18.2972 98.6743 17.4084C98.4975 17.1383 98.2523 16.9197 97.9636 16.7748C97.6748 16.6298 97.3529 16.5635 97.0303 16.5828C96.4826 16.5861 95.9497 16.76 95.5057 17.0801C95.0236 17.4383 94.6734 17.9453 94.5093 18.5225C94.264 19.4973 94.16 20.5022 94.2004 21.5065V28.4694H90.3344V20.5118C90.3814 19.5953 90.3144 18.6765 90.1351 17.7764C90.0307 17.4062 89.8065 17.0809 89.4974 16.8514C89.1461 16.6323 88.7351 16.528 88.3216 16.5529C87.7508 16.5463 87.1914 16.7128 86.7174 17.0304C86.2417 17.3691 85.8914 17.8551 85.721 18.413C85.4729 19.3874 85.3689 20.3927 85.4121 21.3971V28.5291H81.556V13.927H81.4763Z"
        fill={color}
      />
      <path
        d="M104.852 28.5292V24.6797H108.708V28.5292H104.852Z"
        fill={color}
      />
      <path
        d="M110.153 24.3315L114.029 23.7446C114.144 24.4162 114.498 25.0238 115.026 25.4555C115.646 25.876 116.389 26.0789 117.138 26.0324C117.927 26.0875 118.714 25.8963 119.39 25.4853C119.547 25.3717 119.676 25.2222 119.764 25.0492C119.853 24.8763 119.898 24.6848 119.898 24.4906C119.903 24.3571 119.88 24.224 119.832 24.0992C119.785 23.9745 119.712 23.8606 119.619 23.7645C119.232 23.4977 118.789 23.324 118.324 23.2572C115.215 22.5941 113.242 21.9674 112.405 21.3772C111.857 21.0281 111.409 20.543 111.105 19.9694C110.801 19.3958 110.652 18.7533 110.671 18.1047C110.665 17.4961 110.796 16.8938 111.054 16.3428C111.313 15.7917 111.693 15.3059 112.166 14.9217C113.508 13.9464 115.154 13.4808 116.809 13.6087C118.359 13.5055 119.905 13.8509 121.263 14.6033C122.254 15.2893 122.962 16.3106 123.256 17.478L119.619 18.1544C119.491 17.6271 119.176 17.1641 118.732 16.8514C118.171 16.5179 117.521 16.3619 116.869 16.4037C116.116 16.3473 115.362 16.495 114.687 16.8315C114.552 16.9137 114.441 17.0292 114.364 17.1669C114.287 17.3046 114.247 17.4597 114.248 17.6173C114.252 17.7559 114.29 17.8916 114.358 18.0128C114.425 18.1339 114.521 18.2371 114.637 18.3136C115.787 18.8299 116.999 19.1944 118.244 19.3978C119.787 19.6465 121.256 20.2306 122.548 21.1087C122.953 21.4646 123.271 21.9072 123.48 22.4033C123.689 22.8993 123.782 23.4361 123.754 23.9734C123.753 24.6359 123.602 25.2895 123.312 25.8855C123.022 26.4815 122.601 27.0043 122.08 27.415C120.648 28.4807 118.879 28.993 117.098 28.8573C115.436 28.9448 113.788 28.5212 112.375 27.6438C111.249 26.8525 110.457 25.6722 110.153 24.3315Z"
        fill={color}
      />
      <path
        d="M129.194 8.39648V15.7871C129.99 14.7862 131.095 14.0771 132.338 13.7711C133.58 13.4651 134.889 13.5793 136.06 14.0961C136.701 14.3657 137.258 14.8019 137.674 15.3594C138.033 15.8705 138.284 16.4492 138.411 17.0603C138.568 18.0168 138.635 18.9859 138.61 19.9549V28.5291H134.744V20.8302C134.794 19.8567 134.72 18.8808 134.525 17.9257C134.386 17.5158 134.112 17.1651 133.748 16.931C133.328 16.6787 132.843 16.5543 132.353 16.5729C131.749 16.5624 131.154 16.7246 130.639 17.0404C130.12 17.3671 129.729 17.8624 129.533 18.4429C129.262 19.342 129.144 20.2801 129.184 21.2181V28.5291H125.328V8.39648H129.184H129.194Z"
        fill={color}
      />
    </svg>
  );
};

PlatformIconLg.propTypes = {
  color: PropTypes.string
};

export default PlatformIconLg;
