import React from "react";
import PropTypes from "prop-types";

import * as S from "./styles";

const Node = ({ children, id, isActive, to, ...props }) => (
  <S.Wrapper {...props}>
    <S.Link
      aria-current={isActive ? "page" : undefined}
      id={id}
      isActive={isActive}
      to={to}
    >
      {children}
    </S.Link>
  </S.Wrapper>
);

Node.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  isActive: PropTypes.bool,
  to: PropTypes.string
};

export default Node;
