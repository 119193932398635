import { Map } from "immutable";

const initialState = Map({
  query: ""
});

const SET_QUERY = "app/search/set";

export const search = query => ({ type: SET_QUERY, payload: query });

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_QUERY:
      return state.set("query", action.payload);
    default:
      return state;
  }
};

export default reducer;
