import styled, { css } from "styled-components";

export const Wrapper = styled.nav`
  flex: 1;
  ${({ hiddenXs }) =>
    hiddenXs &&
    css`
      display: none;
      @media (min-width: 765px) {
        display: block;
      }
    `};
`;

export const List = styled.ol`
  list-style: none;
  margin: 0;
  display: flex;
  padding: 0 0 0 5px;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    padding: 0 0 0 5px;
    flex-wrap: nowrap;
  }
`;
