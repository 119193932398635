import styled, { css } from "styled-components";
import { semiBoldAlias } from "Libs/theme";

export const SubNavBarTabWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 2;
  width: 100%;
  height: 30px;
  justify-content: space-between;
  overflow: visible;
  box-sizing: border-box;
  .link-button {
    margin-top: -4px;
  }
`;

export const SubNavBarLayout = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  min-height: auto;
  max-height: initial;
  margin: 0 auto;
  padding: 24px 0 24px;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
  touch-callout: none;
  user-select: none;
  overflow: visible;
  box-sizing: border-box;

  a.button {
    margin-bottom: -50%;
    text-transform: none;
    letter-spacing: normal;
  }
  a:not(.button) {
    padding: 0;
    line-height: 22px;
    height: 22px;
    font-size: 13px;
    &:hover,
    &:focus,
    &:active {
      color: ${props => props.theme.subNavActive};
      outline: none;
    }
    &.active {
      position: relative;
      background: transparent;
      ${semiBoldAlias};
      &:before {
        content: "";
        display: inline-block;
        width: 100%;
        position: absolute;
        bottom: -4px;
        left: 0;
        height: 2px;
        background: ${props => props.theme.subNavActive};
      }
    }
    ${props =>
      props.theme.name === "contrast" &&
      css`
        position: relative;

        &:focus:after {
          content: "";
          display: block;
          position: absolute;
          top: -8px;
          left: 0;
          bottom: -8px;
          right: 0;
          box-shadow: 0 0 0 1px #0a5cff;
          border-radius: 2px;
        }
      `};
  }

  @media (min-width: 768px) {
    a:not(.button) {
      padding: 0 8px;

      &.active {
        &:before {
          left: 8px;
          bottom: -7px;
          width: calc(100% - 17px);
        }
      }
    }

    ${SubNavBarTabWrapper} {
      bottom: 1px;
      height: 30px;
      border-bottom: none;
    }
  }
`;

export const Wrapper = styled.div``;
