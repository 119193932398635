import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import InlineBanner from "Components/InlineBanner";

const ErrorWrapper = styled(InlineBanner)`
  margin: 0 0 10px 0;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  font-weight: 600;
  &.inline {
    margin: 0 0 0 10px;
    position: absolute;
    left: 100%;
    top: 40px;
  }
  &.inline.no-label {
    top: auto;
    height: 100%;
    display: flex;
    align-items: center;
    line-height: 100%;
  }
  p {
    margin: 0 0 15px;
    &:last-child {
      margin: 0;
    }
  }
`;

const Error = ({ children, className }) => {
  return (
    <ErrorWrapper
      className={`error-alert${className ? " " + className : ""}`}
      level="error"
      role="alert"
    >
      <div className="message">{children}</div>
    </ErrorWrapper>
  );
};

Error.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Error;
