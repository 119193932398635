import React, { useRef } from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

import useUniqueId from "Libs/useUniqueId";

import { Wrapper } from "./styles";

const AccessibleTooltip = ({ children, tooltipProps, ...props }) => {
  const tooltip = useRef(null);

  const uid = useUniqueId();

  tooltipProps = {
    place: "bottom",
    ...tooltipProps,
    id: `tooltip-${uid}`
  };

  const show = () => ReactTooltip.show(tooltip.current);
  const hide = () => ReactTooltip.hide(tooltip.current);

  const onKeyUp = event =>
    event.keyCode === 27 && hide() && props.onKeyUp(event);
  const afterShow = () => document.body.addEventListener("keyup", onKeyUp);
  const afterHide = () => document.body.removeEventListener("keyup", onKeyUp);

  return (
    <Wrapper
      onMouseEnter={show}
      onBlur={hide}
      onMouseLeave={hide}
      onFocus={show}
      tabIndex="0"
      aria-labelledby={tooltipProps.id}
      data-tip
      data-for={tooltipProps.id}
      ref={tooltip}
      tooltipProps={tooltipProps}
      {...props}
    >
      {children}
      <ReactTooltip
        role="tooltip"
        effect="solid"
        type="dark"
        wrapper="span"
        afterShow={afterShow}
        afterHide={afterHide}
        {...tooltipProps}
      />
    </Wrapper>
  );
};

AccessibleTooltip.propTypes = {
  children: PropTypes.node,
  tooltipProps: PropTypes.object,
  onKeyUp: PropTypes.func
};

export default AccessibleTooltip;
