import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import logger from "Libs/logger";

import ButtonLink from "../ButtonLink";
import CopyButton from "../CopyButton";
import FullPageError from "../FullPageError";
import { CodeWrapper } from "../FullPageError/FullPageError";

const Buttons = styled.div.attrs(() => ({
  className: "buttons"
}))`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ButtonWrapper = styled.div.attrs(() => ({
  className: "button-wrapper"
}))`
  margin-top: 48px;

  @media screen and (min-width: 900px) {
    margin-top: 64px;
  }
`;

const BackToProjects = styled(ButtonLink)`
  a.button {
    border-radius: 4px;
    padding-top: 8px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 8px;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    height: auto;
    background-color: #4786ff;
    margin-right: 16px;

    &:hover,
    &:focus {
      line-height: 20px;
      margin-right: 16px;
    }
  }
`;

const Report = styled.a`
  background-color: rgba(255, 255, 255, 0.0001);
  border: 1px solid #c9d0e4;
  box-sizing: border-box;
  border-radius: 2px;
  padding-top: 8px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 8px;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #1a192b !important;
  text-decoration: none;
`;

class AppErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: {},
      info: {}
    };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({
      hasError: true,
      error: error,
      info: info
    });
    const spinner = document.getElementById("pre-loading");
    const fallback = document.getElementById("fallback");
    if (spinner) {
      spinner.remove();
    }
    if (fallback) {
      fallback.remove();
    }

    logger(error);
  }

  render() {
    const {
      hasError,
      error: { stack }
    } = this.state;

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <FullPageError
          title="It's not you - it's us"
          message="The following error broke our application before it could load."
        >
          <div className="code">
            <CodeWrapper>{stack}</CodeWrapper>
          </div>
          <Buttons>
            <CopyButton
              text={stack}
              theme={{ buttonText: "#ffffff", buttons: "#4786ff" }}
            />
            <ButtonWrapper>
              <BackToProjects text="Back to projects" to="/" />
              <Report
                href={
                  process.env.CUSTOM_SUPPORT_URL
                    ? process.env.CUSTOM_SUPPORT_URL
                    : `${process.env.ACCOUNTS_URL}/support`
                }
                target="_blank"
              >
                Report
              </Report>
            </ButtonWrapper>
          </Buttons>
        </FullPageError>
      );
    }
    return this.props.children;
  }
}

AppErrorBoundary.propTypes = {
  children: PropTypes.node
};

export default AppErrorBoundary;
