import React, { useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import styled from "styled-components";
import { transparentize } from "polished";

import NavigationMenu from "Containers/NavBar/NavigationMenu";
import BranchIcon from "Icons/BranchIcon";
import RedeployIcon from "Icons/RedeployIcon";
import MergeIcon from "Icons/MergeIcon";
import SyncIcon from "Icons/SyncIcon";
import IconMore from "Icons/IconMore";

import ActionButton from "UI/ActionButton";
import EnvironmentBackupModal from "./BackupModal";
import FloatBox from "Containers/NavBar/FloatBox";

import ActionDropdown from "Components/ActionDropdown";
import { capitalize, getEnvironmentURI } from "Libs/utils";
import { breakpoints } from "Libs/theme";

// this controls when to show the text in the action buttons
// for this to work the text must be wrapped in a <span> tag
const showCompleteButtonsMQ = `(min-width: ${breakpoints[2]})`;

const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: flex-start;
  .info-button,
  & > ${ActionButton} + ${ActionButton} {
    margin-left: 8px;
  }
  .disabled {
    pointer-events: none;
    cursor: not-allowed;
    background: ${props =>
      transparentize(0.4, props.theme?.buttons || "#4786ff")};
    border-color: transparent;
    color: rgba(255, 255, 255, 0.8);
    svg {
      opacity: 0.8;
    }
  }
`;

const EnvironmentNavBar = ({
  push,
  project,
  environment,
  organizationId,
  projectId,
  environmentId,
  intl,
  ...props
}) => {
  const { path } = useRouteMatch();
  const [openBackupModal, setBackupModalState] = useState(false);

  const toggleBackupModal = () => {
    setBackupModalState(!openBackupModal);
  };

  const environmentUri = getEnvironmentURI(
    organizationId,
    projectId,
    environmentId
  );

  const handleMergeClicked = e => {
    e.preventDefault();
    document.activeElement.blur();
    push(`${environmentUri}/actions/merge`);
  };

  const handleBranchClicked = e => {
    e.stopPropagation();
    document.activeElement.blur();
    push(`${environmentUri}/actions/branch`);
  };

  const handleSyncClicked = e => {
    e.stopPropagation();
    document.activeElement.blur();
    push(`${environmentUri}/actions/sync`);
  };

  return (
    <FloatBox>
      <NavigationMenu
        project={project}
        environment={environment}
        environmentId={environmentId}
        organizationId={organizationId}
        projectId={projectId}
        {...props}
      />
      <ActionButtons
        className={`action-buttons${
          environment && environment.status === "inactive" ? " inactive" : ""
        }`}
      >
        {path === "/:organizationId/:projectId/:environmentId" && (
          <React.Fragment>
            <ActionButton
              id="environment-redeploy-btn"
              to={`${environmentUri}/actions/redeploy`}
              use={Link}
              disabled={!environment.hasLink("#redeploy")}
              aria-label={intl.formatMessage({ id: "redeploy" })}
              expandWhen={showCompleteButtonsMQ}
            >
              <RedeployIcon />{" "}
              <span>{capitalize(intl.formatMessage({ id: "redeploy" }))}</span>
            </ActionButton>

            <ActionButton
              id="environment-branch-btn"
              onClick={
                environment.hasLink("#branch") ? handleBranchClicked : () => {}
              }
              disabled={!environment.hasLink("#branch")}
              tabIndex="0"
              aria-label={intl.formatMessage({ id: "branch" })}
              expandWhen={showCompleteButtonsMQ}
            >
              <BranchIcon />{" "}
              <span>{capitalize(intl.formatMessage({ id: "branch" }))}</span>
            </ActionButton>

            <ActionButton
              id="environment-merge-btn"
              onClick={
                environment.hasLink("#merge") ? handleMergeClicked : () => {}
              }
              disabled={!environment.hasLink("#merge")}
              tabIndex="0"
              aria-label={intl.formatMessage({ id: "merge" })}
              expandWhen={showCompleteButtonsMQ}
            >
              <MergeIcon />{" "}
              <span>{capitalize(intl.formatMessage({ id: "merge" }))}</span>
            </ActionButton>

            <ActionButton
              id="environment-synchronize-btn"
              onClick={
                environment.hasLink("#synchronize")
                  ? handleSyncClicked
                  : () => {}
              }
              disabled={!environment.hasLink("#synchronize")}
              aria-label={intl.formatMessage({ id: "sync" })}
              expandWhen={showCompleteButtonsMQ}
            >
              <SyncIcon />{" "}
              <span>{capitalize(intl.formatMessage({ id: "sync" }))}</span>
            </ActionButton>

            {(path === "/:organizationId/:projectId/:environmentId" ||
              path ===
                `/:organizationId/:projectId/:environmentId/backups`) && (
              <ActionDropdown
                icon={<IconMore color={"white"} />}
                ariaLabel="More"
                withArrow={false}
                className="more"
                id="environment-actions-dropdown"
              >
                <ul>
                  <li>
                    <Link
                      id="environment-backup-btn"
                      onClick={
                        environment.hasLink("#backup")
                          ? e => {
                              e.preventDefault();
                              document.activeElement.blur();
                              toggleBackupModal();
                            }
                          : () => {}
                      }
                      className={`${!environment.hasLink("#backup") &&
                        "disabled"}`}
                      tabIndex="0"
                      href="#"
                      type="button"
                    >
                      {intl.formatMessage({ id: "backup" })}
                    </Link>
                  </li>
                </ul>
              </ActionDropdown>
            )}
          </React.Fragment>
        )}

        <EnvironmentBackupModal
          isOpen={openBackupModal}
          closeModal={toggleBackupModal}
        />
      </ActionButtons>
    </FloatBox>
  );
};

EnvironmentNavBar.propTypes = {
  project: PropTypes.object,
  environment: PropTypes.object,
  intl: PropTypes.object,
  push: PropTypes.func,
  organizationId: PropTypes.string,
  projectId: PropTypes.string,
  environmentId: PropTypes.string
};

export default injectIntl(EnvironmentNavBar);
