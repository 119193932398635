import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const BackupIcon = ({ color = "#fff" }) => {
  const uid = useUniqueId();

  return (
    <svg
      className="icon-backup"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      role="img"
      aria-labelledby={`backup-icon-${uid}`}
    >
      <title id={`backup-icon-${uid}`}>Backup icon</title>
      <path
        fillRule="evenodd"
        className="fill-target"
        d="M20,13H4c-0.6,0-1,0.5-1,1v6c0,0.5,0.4,1,1,1h16c0.5,0,1-0.5,1-1v-6C21,13.4,20.5,13,20,13L20,13z M7,19 c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S8.1,19,7,19L7,19z M20,3H4C3.4,3,3,3.4,3,4v6c0,0.6,0.4,1,1,1h16c0.5,0,1-0.4,1-1V4 C21,3.4,20.5,3,20,3L20,3z M7,9C5.9,9,5,8.1,5,7s0.9-2,2-2s2,0.9,2,2S8.1,9,7,9L7,9z"
        fill={color}
      />
    </svg>
  );
};

BackupIcon.propTypes = {
  color: PropTypes.string
};

export default BackupIcon;
