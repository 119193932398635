import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const PlusIcon = ({ color = "#ffffff", ...props }) => {
  const uid = useUniqueId();

  return (
    <svg
      width="10px"
      height="10px"
      viewBox="0 0 10 10"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`plus-icon-${uid}`}
      {...props}
    >
      <title id={`plus-icon-${uid}`}>Plus icon</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-16.000000, -15.000000)" fill={color}>
          <g>
            <polygon points="22 21 22 25 20 25 20 21 16 21 16 19 20 19 20 15 22 15 22 19 26 19 26 21" />
          </g>
        </g>
      </g>
    </svg>
  );
};

PlusIcon.propTypes = {
  color: PropTypes.string
};

export default PlusIcon;
