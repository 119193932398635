import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const EnvBranchIcon = ({ color = "#fff" }) => {
  const uid = useUniqueId();

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`env-branch-icon-${uid}`}
    >
      <title id={`env-branch-icon-${uid}`}>Environment branch icon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 6C7.44772 6 7 6.44772 7 7C7 7.55228 7.44772 8 8 8C8.55228 8 9 7.55228 9 7C9 6.44772 8.55228 6 8 6ZM11 7C11 8.30622 10.1652 9.41746 9 9.82929V14.0645H14C14.5523 14.0645 15 13.6168 15 13.0645V12.8293C13.8348 12.4175 13 11.3062 13 10C13 8.34315 14.3431 7 16 7C17.6569 7 19 8.34315 19 10C19 11.3062 18.1652 12.4175 17 12.8293V13.0645C17 14.7214 15.6569 16.0645 14 16.0645H9V19C9 19.5523 8.55228 20 8 20C7.44772 20 7 19.5523 7 19V15.0645V9.82929C5.83481 9.41746 5 8.30622 5 7C5 5.34315 6.34315 4 8 4C9.65685 4 11 5.34315 11 7ZM15 10C15 9.44772 15.4477 9 16 9C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11C15.4477 11 15 10.5523 15 10Z"
        fill={color}
      />
    </svg>
  );
};

EnvBranchIcon.propTypes = {
  color: PropTypes.string
};

export default EnvBranchIcon;
