import styled, { keyframes } from "styled-components";
import { semiBoldAlias } from "Libs/theme";

const expandOut = keyframes`
  0% {
    width: 68px;
  }
  100% {
    width: 176px;
  }
`;

const expandIn = keyframes`
  0% {
    width: 176px;
  }
  100% {
    width: 68px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  width: auto;
  position: absolute;
  right: 0;
  top: -64px;
  margin-bottom: 0;
  .checkbox-field,
  .checkbox-field label {
    margin: 0;
  }
  .checkbox-field {
    margin-bottom: 8px;
    &.checked {
      label {
        color: ${props => props.theme.primary};
      }
    }
  }
  .radio-field {
    &.checked {
      label {
        color: ${props => props.theme.primary};
      }
    }
  }
  .search-wrapper {
    height: inherit;
    margin-right: 8px;
    display: none;
    input.search {
      width: 86px;
      animation: ${expandIn} 0.18s linear 1;
      padding: 0 8px 0 32px;
      &.active {
        width: 176px;
        animation: ${expandOut} 0.18s linear 1;
      }
    }
  }
  .environments-filter-window {
    width: 300px;
    right: 50%;
    margin-right: -143px;

    h6 {
      font-size: 14px;
      color: ${props => props.theme.sectionText};
      ${semiBoldAlias};
      &.title {
        font-weight: bold;
        line-height: 24px;
      }
    }

    .radio-field:last-child label,
    .radio-field:last-child {
      margin-bottom: 0;
    }
  }
  .info-button {
    > button {
      padding: 0 4px !important;
      border: none;
      width: auto;
      margin-right: 16px;
      @media (min-width: 1280px) {
        width: 70px;
      }
      &:hover,
      &.open {
        svg {
          transform: none;
          rect {
            fill: ${props => props.theme.primary};
          }
        }
      }
    }
    .text {
      display: none;
      @media (min-width: 1280px) {
        display: inline-block;
      }
    }
  }
  @media (min-width: 580px) {
    .search-wrapper {
      display: inline-block;
    }
  }
  @media (min-width: 900px) and (max-width: 1000px) {
    width: 50%;
    .checkbox-field {
      margin-bottom: 8px;
    }
  }
`;
