import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const CopyIcon = ({ color = "#000", ...props }) => {
  const uid = useUniqueId();

  return (
    <svg
      className="icon-copy"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`copy-icon-${uid}`}
      {...props}
    >
      <title id={`copy-icon-${uid}`}>Copy icon</title>
      <g>
        <path
          d="M18.5,21h-9c-1.1,0-2-0.9-2-2V9c0-1.1,0.9-2,2-2h9c1.1,0,2,0.9,2,2v10C20.5,20.1,19.6,21,18.5,21z M9.5,9v10h9 V9H9.5z"
          fill={color}
        />
      </g>
      <g>
        <path
          d="M15.5,4c0,0.5-0.5,1-1,1h-9v9c0,0.5-0.5,1-1,1s-1-0.5-1-1V5c0-1.1,0.9-2,2-2h9C15,3,15.5,3.5,15.5,4z"
          fill={color}
        />
      </g>
    </svg>
  );
};

CopyIcon.propTypes = {
  color: PropTypes.string
};

export default CopyIcon;
