import React from "react";
import styled from "styled-components";

import { Skeleton, Square } from "Components/Skeleton";

const Layout = styled(Skeleton)`
  padding: 32px;
  min-height: 841px;
  box-sizing: border-box;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
`;

const EnvironmentsTreeSkeleton = () => (
  <Layout synchronize className="environments">
    <Row>
      <Square height="24px" width="140px" margin="0 0 16px 0" />
      <Square height="24px" width="280px" margin="0 0 16px auto" />
    </Row>

    <Row>
      <Square margin="0 16px 0 0" height="32px" width="32px" />
      <Square width="140px" height="24px" />
      <Square width="84px" height="24px" margin="0 0 0 auto" />
    </Row>
    <Row>
      <Square margin="0 16px 0 48px" height="32px" width="32px" />
      <Square width="140px" height="24px" />
      <Square width="84px" height="24px" margin="0 0 0 auto" />
    </Row>
    <Row>
      <Square margin="0 16px 0 96px" height="32px" width="32px" />
      <Square width="140px" height="24px" />
      <Square width="84px" height="24px" margin="0 0 0 auto" />
    </Row>
  </Layout>
);

export default EnvironmentsTreeSkeleton;
