import styled from "styled-components";

export const Layout = styled.svg`
  color: var(--icon-primary);
  fill: currentColor;
  stroke: currentColor;
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: 2px;
`;
