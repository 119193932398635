import {
  APP_NAME,
  CLI_COMMAND_NAME,
  CLI_DOWNLOAD_URL,
  CONFIG_DIR_NAME,
  DOCS_CLI_REPO_URL,
  DOCS_CLI_URL,
  DOCS_GETTING_STARTED_DOWNLOAD_CODE_URL,
  DOCS_USING_SSH_KEYS_URL,
  DOCS_SERVICES_CONFIG_URL,
  DOCS_APP_CONTAINERS_CONFIG_URL,
  DOCS_ROUTES_CONFIG_URL,
  DOCS_GETTING_STARTED_PROJECT_CONFIG_OWN_CODE_URL
} from "../documentationUrls";

export const TEMPLATE_WIZARD_STEPS = [
  {
    id: "cli",
    label: "Download the CLI",
    title: "Download the CLI",
    subtitle: "(Command Line Interface)",
    required: false,
    bodyText: `<p>To install the CLI, use the command for either OSX or Windows as shown.</p><p>For more info about our CLI check out our <a href=${DOCS_CLI_URL} target="_blank" rel="noopener noreferrer">documentation</a> or take a look at our <a href=${DOCS_CLI_REPO_URL} target="_blank" rel="noopener noreferrer">CLI source code</a>.</p>`,
    copyCode: [
      {
        label: "OSX or Linux Installer",
        code: [`curl -sS ${CLI_DOWNLOAD_URL} | php`]
      },
      {
        label: "Windows Installer",
        code: [
          `curl ${CLI_DOWNLOAD_URL} -o cli-installer.php`,
          "php cli-installer.php"
        ]
      }
    ]
  },
  {
    id: "sshKey",
    label: "Add your SSH key",
    title: "Add your SSH key",
    required: true,
    bodyText: `<p>To deploy your code to ${APP_NAME} you'll need Git installed on your local machine and your <a href=${DOCS_USING_SSH_KEYS_URL} target="_blank" rel="noopener noreferrer">SSH key</a>. You can paste your Public SSH key in the box below (or do so later in your Account Settings).</p><p>Configuring an SSH key will also allow you to connect to your running applications and services.</p><p>If you don't have an SSH key configured we recommend following GitHub's excellent SSH <a href="https://help.github.com/en/articles/generating-a-new-ssh-key-and-adding-it-to-the-ssh-agent" target="_blank" rel="noopener noreferrer">tutorial</a>.</p>`
  },
  {
    id: "download",
    label: "Download your project",
    title: "Download your project to start using it",
    required: true,
    bodyText: `<p>Use the ${CLI_COMMAND_NAME} get CLI command to download your project template so you can start modifying it.</p><p><br /></p><p>Visit our <a href=${DOCS_GETTING_STARTED_DOWNLOAD_CODE_URL} target="_blank" rel="noopener noreferrer">docs</a> for more information.</p>`,
    copyCode: [
      {
        label: "CLI command",
        code: [`${CLI_COMMAND_NAME} get <project ID>`]
      }
    ]
  },
  {
    id: "configure",
    label: "Configure your project",
    title: "Customize your infrastructure",
    required: true,
    bodyText: `<p>Customize the configuration files included in your template to change your project infrastructure.</p><p>Your required files:</p><p><a href=${DOCS_APP_CONTAINERS_CONFIG_URL} target="_blank" rel="noopener noreferrer"><code>.platform.app.yaml</code></a><br><a href=${DOCS_SERVICES_CONFIG_URL} target="_blank" rel="noopener noreferrer"><code>.platform/services.yaml</code></a><br><a href=${DOCS_ROUTES_CONFIG_URL} target="_blank" rel="noopener noreferrer"><code>.platform/routes.yaml</code></a></p><p></p><p>See our <a href=${DOCS_GETTING_STARTED_PROJECT_CONFIG_OWN_CODE_URL} target="_blank" rel="noopener noreferrer">documentation</a> for more information.</p>`,
    copyCode: [
      {
        label: "Application code structure",
        code: [
          `├── ${CONFIG_DIR_NAME}`,
          "│   ├── routes.yaml",
          "│   └── services.yaml",
          "├── .platform.app.yaml",
          "└── < application code >"
        ],
        noCopy: true
      }
    ]
  }
];

export default {
  TEMPLATE_WIZARD_STEPS
};
