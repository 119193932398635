import { transparentize } from "polished";
import styled, { keyframes } from "styled-components";

import * as S from "../styles";

export const Link = styled(S.Link)`
  border-bottom-color: ${props => props.theme.ebony["400"]};
`;

export const OrganizationSwitcher = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 16px;
  padding: 16px 0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: ${props => props.theme.ebony["400"]};
  .organization_switch_title {
    font-size: 14px;
    color: ${props => props.theme.mobileNavTitle};
  }
  .organization_switch_name_icon {
    transform: rotate(-90deg);
    path {
      fill: white;
    }
  }
`;

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
`;

export const OrganizationMenuWrapper = styled.ul`
  animation: ${slideIn} 0.3s ease-in-out;
  list-style: none;
  padding: 0 24px;
  margin-top: 60px;
  .organization-menu-back {
    align-items: center;
    font-size: 14px;
    .organization-back_text {
      color: ${props => props.theme.mobileNavTitle};
    }
    margin: 0 12px;
    margin-bottom: 21px;
    display: flex;
    align: center;
    gap: 16px;
    svg * {
      fill: white;
    }
    border: none;
  }
`;

export const List = styled.li`
  background: ${props =>
    props.active ? transparentize(0.7, props.theme.ebony["400"]) : "none"};
  .orgainzation-name {
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    color: ${props => (props.active ? props.theme.primary : "currentColor")};
  }
  .orgainzation-switcher {
    border-style: ${props => (props.active ? "none" : "currentColor")};
  }
`;
