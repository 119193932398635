import React from "react";
import PropTypes from "prop-types";

import useUniqueId from "Libs/useUniqueId";

const IconLayoutTree = ({ color = "#5792FF" }) => {
  const uid = useUniqueId();

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby={`layout-tree-icon-${uid}`}
    >
      <title id={`layout-tree-icon-${uid}`}>Layout tree icon</title>
      <path
        d="M14.5,6l-1.9,1.9v3h5.7c0.6,0,1,0.4,1,1v4.3l1.9,1.9c0.4,0.4,0.4,1,0,1.4l-1.8,1.8c-0.4,0.4-1,0.4-1.4,0
	l-1.8-1.8c-0.4-0.4-0.4-1,0-1.4l1.9-1.9V12h-5.6v4.2c0.1,0,0.1,0.1,0.1,0.1l1.8,1.8c0.4,0.4,0.4,1,0,1.4l-1.8,1.8
	c-0.4,0.4-1,0.4-1.4,0l-1.8-1.8c-0.4-0.4-0.4-1,0-1.4l1.8-1.8c0,0,0.1-0.1,0.1-0.1V12H5.8v4.1L7.7,18c0.4,0.4,0.4,1,0,1.4L6,21.2
	c-0.4,0.4-1,0.4-1.4,0l-1.8-1.8c-0.4-0.4-0.4-1,0-1.4l1.9-1.9v-4.3c0-0.6,0.4-1,1-1h5.7v-3L9.5,6c-0.4-0.4-0.4-1,0-1.4l1.8-1.8
	c0.4-0.4,1-0.4,1.4,0l1.8,1.8C14.9,4.9,14.9,5.6,14.5,6z"
        fill={color}
      />
    </svg>
  );
};

IconLayoutTree.propTypes = {
  color: PropTypes.string
};
export default IconLayoutTree;
