import { Map, fromJS } from "immutable";

import { getOrganizationDescriptionId, hasHtml, isJson } from "Libs/utils";
import { ENVIRONMENT_ID_FIELD } from "Constants/constants";
import logger from "Libs/logger";

import localForage from "localforage";

// const LOAD_ENVIRONMENTS_SKIP = "app/environments/load_skip";
const LOAD_ENVIRONMENTS_START = "app/environments/load_start";
const LOAD_ENVIRONMENTS_SUCCESS = "app/environments/load_success";
const LOAD_ENVIRONMENTS_FAILURE = "app/environments/load_failure";

export const DELETE_ENVIRONMENTS = "app/environments/delete";

const LOAD_ENVIRONMENT_START = "app/environment/load_start";
const LOAD_ENVIRONMENT_SKIP = "app/environment/load_skip";
const LOAD_ENVIRONMENT_SUCCESS = "app/environment/load_success";
const LOAD_ENVIRONMENT_FROM_EVENT_SUCCESS =
  "app/environment/load_from_event_success";
const LOAD_ENVIRONMENT_FAILURE = "app/environment/load_failure";

const LOAD_LAST_ENVIRONMENTS_START = "app/last_environment/load_start";
const LOAD_LAST_ENVIRONMENTS_SUCCESS = "app/last_environment/load_success";
const LOAD_LAST_ENVIRONMENTS_FAILURE = "app/last_environment/load_failure";

const UPDATE_ENVIRONMENT_START = "app/environment/update_start";
const UPDATE_ENVIRONMENT_SUCCESS = "app/environment/update_success";
const UPDATE_ENVIRONMENT_FAILURE = "app/environment/update_failure";

const TOGGLE_ACTIVATION_ENVIRONMENT_START =
  "app/environment/toggle_activation_start";
const TOGGLE_ACTIVATION_ENVIRONMENT_SUCCESS =
  "app/environment/toggle_activation_success";
const TOGGLE_ACTIVATION_ENVIRONMENT_FAILURE =
  "app/environment/toggle_activation_failure";

const TOGGLE_SMTP_START = "app/environment/toggle_smtp_start";
const TOGGLE_SMTP_SUCCESS = "app/environment/toggle_smtp_success";
const TOGGLE_SMTP_FAILURE = "app/environment/toggle_smtp_failure";

const INITIALIZE_START = "app/environment/initialize_start";
const INITIALIZE_SUCCESS = "app/environment/initialize_success";
const INITIALIZE_FAILURE = "app/environment/initialize_failure";

const TOGGLE_RESTRICT_ROBOT_START =
  "app/environment/toggle_restrict_robot_start";
const TOGGLE_RESTRICT_ROBOT_SUCCESS =
  "app/environment/toggle_restrict_robot_success";
const TOGGLE_RESTRICT_ROBOT_FAILURE =
  "app/environment/toggle_restrict_robot_failure";

const UPDATE_HTTP_ACCESS_CONTROL_START =
  "app/environment/update_http_access_control_start";
const UPDATE_HTTP_ACCESS_CONTROL_SUCCESS =
  "app/environment/update_http_access_control_success";
const UPDATE_HTTP_ACCESS_CONTROL_FAILURE =
  "app/environment/update_http_access_control_failure";

const SET_EDIT_LINE = "app/environment/edit-line";

export const loadEnvironmentFromEventSuccess = (
  environment,
  organizationDescriptionId
) => {
  return {
    type: LOAD_ENVIRONMENT_FROM_EVENT_SUCCESS,
    payload: { environment },
    meta: { organizationDescriptionId }
  };
};

export const toggleSmtp = (projectDescriptionId, environmentDescriptionId) => {
  return async (dispatch, getState) => {
    dispatch({ type: TOGGLE_SMTP_START });

    try {
      const environment = getState().environment.getIn([
        "data",
        getOrganizationDescriptionId(getState, projectDescriptionId),
        projectDescriptionId,
        environmentDescriptionId
      ]);

      const result = await environment.update({
        enable_smtp: !environment.enable_smtp
      });
      const newEnvironment = await result.getEntity();

      dispatch({ type: TOGGLE_SMTP_SUCCESS, payload: newEnvironment });
    } catch (err) {
      if (![404, 403].includes(err.code)) {
        logger(err, {
          action: "toggle_smtp",
          projectDescriptionId,
          environmentDescriptionId
        });
      }
      dispatch({ type: TOGGLE_SMTP_FAILURE, payload: err });
    }
  };
};

export const initialize = (
  organizationDescriptionId,
  projectDescriptionId,
  profile,
  repository
) => {
  return async (dispatch, getState) => {
    dispatch({ type: INITIALIZE_START });

    try {
      const environments = getState().environment.getIn([
        "data",
        organizationDescriptionId,
        projectDescriptionId
      ]);
      const project = getState().project.getIn([
        "data",
        organizationDescriptionId,
        projectDescriptionId
      ]);
      const environment = environments.find(
        environment =>
          environment.name === (project?.default_branch || "master")
      );

      const result = await environment.initialize(profile, repository);
      const newEnvironment = await result.getEntity();

      dispatch({ type: INITIALIZE_SUCCESS, payload: newEnvironment });
    } catch (err) {
      logger(err);
      dispatch({ type: INITIALIZE_FAILURE, payload: err });
    }
  };
};

export const toggleRestrictRobot = (
  projectDescriptionId,
  environmentDescriptionId
) => {
  return async (dispatch, getState) => {
    dispatch({ type: TOGGLE_RESTRICT_ROBOT_START });

    try {
      const environment = getState().environment.getIn([
        "data",
        getOrganizationDescriptionId(getState, projectDescriptionId),
        projectDescriptionId,
        environmentDescriptionId
      ]);
      const result = await environment.update({
        restrict_robots: !environment.restrict_robots
      });
      const newEnvironment = await result.getEntity();

      dispatch({
        type: TOGGLE_RESTRICT_ROBOT_SUCCESS,
        payload: newEnvironment
      });
    } catch (err) {
      logger(err, {
        action: "toggle_restrict_robot",
        projectDescriptionId,
        environmentDescriptionId
      });
      dispatch({ type: TOGGLE_RESTRICT_ROBOT_FAILURE, payload: err });
    }
  };
};

export const updateHttpAccessControl = (
  projectDescriptionId,
  environmentDescriptionId,
  httpAccess
) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_HTTP_ACCESS_CONTROL_START });

    try {
      const environment = getState().environment.getIn([
        "data",
        getOrganizationDescriptionId(getState, projectDescriptionId),
        projectDescriptionId,
        environmentDescriptionId
      ]);
      const result = await environment.update({
        http_access: httpAccess
      });
      const newEnvironment = await result.getEntity();

      dispatch({
        type: UPDATE_HTTP_ACCESS_CONTROL_SUCCESS,
        payload: newEnvironment
      });
    } catch (err) {
      if (![404, 403].includes(err.code) && !hasHtml(err)) {
        logger(err, {
          action: "update_http_access_control",
          projectDescriptionId,
          environmentDescriptionId,
          httpAccess
        });
      }
      dispatch({ type: UPDATE_HTTP_ACCESS_CONTROL_FAILURE, payload: err });
    }
  };
};

export const loadEnvironments = (
  projectDescriptionId,
  organizationDescriptionId
) => {
  return async dispatch => {
    dispatch({ type: LOAD_ENVIRONMENTS_START });

    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;

      const environments = await client.getEnvironments(projectDescriptionId);

      dispatch({
        type: LOAD_ENVIRONMENTS_SUCCESS,
        payload: {
          environments
        },
        meta: {
          projectDescriptionId,
          organizationDescriptionId
        }
      });
    } catch (err) {
      if (![404, 403].includes(err.code) && !hasHtml(err)) {
        logger(err, {
          action: "environments_load",
          meta: {
            projectDescriptionId
          },
          projectDescriptionId
        });
      }
      dispatch({ type: LOAD_ENVIRONMENTS_FAILURE, error: true, payload: err });
    }
  };
};

export const loadEnvironment = (
  environmentDescriptionId,
  projectDescriptionId,
  projectOrganizationId
) => {
  return async (dispatch, getState) => {
    const organizationDescriptionId =
      projectOrganizationId ||
      getOrganizationDescriptionId(getState, projectDescriptionId);

    let currentEnvironmentLoaded = getState().environment.getIn(
      [
        "data",
        organizationDescriptionId,
        projectDescriptionId,
        environmentDescriptionId
      ],
      {}
    );
    let environmentIsLoading = getState().environment.get("loading");

    if (
      environmentIsLoading ||
      Object.keys(currentEnvironmentLoaded).length > 0
    ) {
      dispatch({ type: LOAD_ENVIRONMENT_SKIP });
      return;
    }

    dispatch({ type: LOAD_ENVIRONMENT_START });

    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;
      const encodedEnvId = encodeURIComponent(environmentDescriptionId);

      const environment = await client.getEnvironment(
        projectDescriptionId,
        encodedEnvId
      );

      let lastVisitedEnvironments = getState().environment.get(
        "lastVisitedEnvironments"
      );

      if (!lastVisitedEnvironments) {
        lastVisitedEnvironments =
          (await localForage.getItem("lastVisitedEnvironments")) || [];
      }

      lastVisitedEnvironments = fromJS(lastVisitedEnvironments);

      const currentEnvironmentIndex = lastVisitedEnvironments.findIndex(env => {
        return env.get("id") === environment.id;
      });
      let newLastVisitedEnvironments = lastVisitedEnvironments;

      if (currentEnvironmentIndex !== -1) {
        newLastVisitedEnvironments = lastVisitedEnvironments.delete(
          currentEnvironmentIndex
        );
      }

      newLastVisitedEnvironments = newLastVisitedEnvironments.unshift(
        fromJS({ ...environment })
      );

      if (newLastVisitedEnvironments.size > 10) {
        newLastVisitedEnvironments = newLastVisitedEnvironments.pop();
      }

      dispatch({
        type: LOAD_ENVIRONMENT_SUCCESS,
        payload: {
          environment
        },
        meta: {
          organizationDescriptionId,
          lastVisitedEnvironments: newLastVisitedEnvironments
        }
      });

      localForage.setItem(
        "lastVisitedEnvironments",
        newLastVisitedEnvironments.toJS()
      );
    } catch (err) {
      let error = err;
      if (error.code === 404) {
        error.message = "environment.notfound";
      } else if (error.code !== 403) {
        logger(err, {
          action: "environment_load",
          meta: {
            organizationDescriptionId: getOrganizationDescriptionId(
              getState,
              projectDescriptionId
            )
          }
        });
      }
      dispatch({ type: LOAD_ENVIRONMENT_FAILURE, error: true, payload: error });
    }
  };
};

export const updateEnvironment = (
  organizationDescriptionId,
  projectDescriptionId,
  environmentDescriptionId,
  environmentData
) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_ENVIRONMENT_START });

    try {
      const environment = getState().environment.getIn([
        "data",
        organizationDescriptionId,
        projectDescriptionId,
        environmentDescriptionId
      ]);
      const result = await environment.update(environmentData);
      const newEnvironment = await result.getEntity();

      dispatch({
        type: UPDATE_ENVIRONMENT_SUCCESS,
        payload: {
          environment: newEnvironment
        },
        meta: {
          organizationDescriptionId
        }
      });
    } catch (err) {
      if (![404, 403].includes(err.code) && !hasHtml(err)) {
        logger(err, {
          action: "environment_update",
          meta: {
            organizationDescriptionId
          },
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId,
          environmentData
        });
      }
      dispatch({ type: UPDATE_ENVIRONMENT_FAILURE, error: true, payload: err });
    }
  };
};

export const toggleEnvironmentActivation = (
  organizationDescriptionId,
  projectDescriptionId,
  environmentDescriptionId
) => {
  return async (dispatch, getState) => {
    const isAlreadyLoading = getState().environment.get(
      "toggleActivationLoading"
    );

    if (isAlreadyLoading) {
      return false;
    }

    dispatch({ type: TOGGLE_ACTIVATION_ENVIRONMENT_START });

    const environment = getState().environment.getIn([
      "data",
      organizationDescriptionId,
      projectDescriptionId,
      environmentDescriptionId
    ]);

    try {
      const isActive = await environment.isActive();

      let activity;

      if (isActive) {
        activity = await environment.deactivate();
      } else {
        activity = await environment.activate();
      }

      activity = await activity.wait();

      if (activity.result === "failure") {
        return dispatch({
          type: TOGGLE_ACTIVATION_ENVIRONMENT_FAILURE,
          error: true,
          payload: activity.log,
          meta: {
            organizationDescriptionId,
            environment
          }
        });
      }

      dispatch({
        type: TOGGLE_ACTIVATION_ENVIRONMENT_SUCCESS,
        payload: {
          environment
        },
        meta: {
          organizationDescriptionId
        }
      });
    } catch (err) {
      if (![404, 403].includes(err.code) && !hasHtml(err)) {
        logger(err, {
          action: "toggleEnvironmentActivation",
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId
        });
      }
      dispatch({
        type: TOGGLE_ACTIVATION_ENVIRONMENT_FAILURE,
        error: true,
        payload: err
      });
    }
  };
};

export const loadLastVisitedEnvironments = () => {
  return async (dispatch, getState) => {
    const environment = getState().environment;

    if (!environment) {
      return false;
    }

    let lastVisitedEnvironments = environment.get("lastVisitedEnvironments");

    if (lastVisitedEnvironments && lastVisitedEnvironments.size) {
      return false;
    }

    try {
      dispatch({ type: LOAD_LAST_ENVIRONMENTS_START });

      lastVisitedEnvironments = await localForage.getItem(
        "lastVisitedEnvironments"
      );

      dispatch({
        type: LOAD_LAST_ENVIRONMENTS_SUCCESS,
        payload: lastVisitedEnvironments
      });
    } catch (err) {
      if (![404, 403].includes(err.code) && !hasHtml(err)) {
        const errorMessage = isJson(err)
          ? err
          : "An error occurred while attempting to load last visited environments.";
        logger(errorMessage, {
          action: "loadLastVisitedEnvironments"
        });
      }
      dispatch({ type: LOAD_LAST_ENVIRONMENTS_FAILURE });
    }
  };
};

export const setEditLine = lineIndex => {
  return {
    type: SET_EDIT_LINE,
    payload: lineIndex
  };
};

export default function environmentReducer(state = new Map(), action) {
  switch (action.type) {
    case UPDATE_ENVIRONMENT_START:
      return state.set("updateLoading", true);
    case LOAD_ENVIRONMENTS_START:
      return state.set("loading", true);
    case LOAD_ENVIRONMENT_START:
      return state.set("loading", true).delete("environmentLoadingError");
    case TOGGLE_ACTIVATION_ENVIRONMENT_START:
      return state.set("toggleActivationLoading", true);
    case TOGGLE_ACTIVATION_ENVIRONMENT_FAILURE:
      return state.set("toggleActivationLoading", false);
    case TOGGLE_ACTIVATION_ENVIRONMENT_SUCCESS:
      return state.set("toggleActivationLoading", false);
    case TOGGLE_RESTRICT_ROBOT_SUCCESS:
    case TOGGLE_SMTP_SUCCESS:
    case UPDATE_ENVIRONMENT_SUCCESS:
    case LOAD_ENVIRONMENT_SUCCESS:
      return state
        .setIn(
          [
            "data",
            action.meta.organizationDescriptionId,
            action.payload.environment.project,
            action.payload.environment[ENVIRONMENT_ID_FIELD]
          ],
          fromJS(action.payload.environment)
        )
        .set("lastVisitedEnvironments", action.meta.lastVisitedEnvironments)
        .set("updateLoading", false)
        .set("loading", false)
        .set("errors", new Map())
        .set("editedLine", "")
        .delete("environmentLoadingError");
    case LOAD_ENVIRONMENT_FROM_EVENT_SUCCESS:
      return state.setIn(
        [
          "data",
          action.meta.organizationDescriptionId,
          action.payload.environment.project,
          action.payload.environment[ENVIRONMENT_ID_FIELD]
        ],
        fromJS(action.payload.environment)
      );
    case SET_EDIT_LINE:
      return state.set("editedLine", action.payload);
    case LOAD_ENVIRONMENTS_SUCCESS:
      return state
        .set("loading", false)
        .setIn(
          [
            "data",
            action.meta.organizationDescriptionId,
            action.meta.projectDescriptionId
          ],
          fromJS(
            action.payload.environments.reduce((list, env) => {
              list[env.id] = fromJS(env);
              return list;
            }, {})
          )
        )
        .setIn(
          [
            "tree",
            action.meta.organizationDescriptionId,
            action.meta.projectDescriptionId
          ],
          fromJS(getTree(action.payload.environments))
        )
        .delete("environmentLoadingError");
    case LOAD_ENVIRONMENTS_FAILURE:
      return state.set("loading", false).set("error", action.payload);
    case UPDATE_ENVIRONMENT_FAILURE:
      return state
        .set("updateLoading", false)
        .set("errors", fromJS(action.payload));
    case LOAD_ENVIRONMENT_FAILURE:
      return state
        .set("loading", false)
        .set("environmentLoadingError", fromJS(action.payload));
    case DELETE_ENVIRONMENTS:
      return state.withMutations(map => {
        action.payload.forEach(environmentId => {
          map.deleteIn([
            "data",
            action.meta.organizationId,
            action.meta.projectId,
            environmentId
          ]);
        });
      });
    default:
      return state;
  }
}

const getTree = data => {
  let root;

  const tree = data.reduce((acc, el) => {
    acc[el.id] = { id: el.id, parent: el.parent };
    return acc;
  }, {});

  for (const elt of Object.values(tree)) {
    // Handle the root element
    if (elt.parent === null) {
      root = elt;
      continue;
    }

    let parentElt = tree[elt.parent];
    // Add our current elt to its parent's `children` array
    parentElt.children = [...(parentElt.children || []), elt];
  }

  const dfs = (node, parent) => {
    const depth = parent ? parent.depth + 1 : 0;
    const path = parent ? [...parent.path, ...[parent.id]] : [];
    const current = { id: node.id, depth, path };
    tree[node.id] = current;

    if (!node.children) return;
    node.children?.forEach(child => dfs(child, current));
  };

  dfs(root);
  return tree;
};

export const environmentsSelector = (state, props) =>
  state.environment?.getIn(
    ["data", props.organizationId, props.projectId],
    Map()
  );

export const environmentTreeSelector = (state, props) =>
  state.environment?.getIn(
    ["tree", props.organizationId, props.projectId],
    Map()
  );

export const environmentSelector = (state, props) =>
  state.environment?.getIn([
    "data",
    props.organizationId,
    props.projectId,
    props.environmentId
  ]);
