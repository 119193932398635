import React from "react";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import Highlighter from "react-highlight-words";

import { goToEnvironment } from "Libs/utils";

import * as S from "./MenuLinkItem.styles";

const MenuLinkItem = ({
  closeDropDown,
  currentEnvironment,
  filter,
  history,
  id,
  node,
  organizationId,
  searchValue
}) => {
  const hasChildren = node => {
    if (node.children?.length !== 0) {
      return true;
    }
    return false;
  };

  const getClasses = node => {
    let classValue = "environment-link";
    classValue += node.isRoot ? " is-root" : "";
    classValue += node.environmentId == currentEnvironment ? " active" : "";
    classValue += hasChildren(node) ? " with-children expanded" : "";
    classValue += node.status === "inactive" ? " inactive" : "";

    return classValue;
  };

  const goToEnv = e => {
    e?.preventDefault();
    if (closeDropDown) {
      closeDropDown();
    }
    goToEnvironment(
      history.push,
      organizationId,
      node.projectId,
      encodeURIComponent(node.environmentId)
    );
  };

  const children = node.children
    ?.sort((a, b) => {
      if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
      if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
      return 0;
    })
    .filter(filter);

  return (
    <S.Wrapper key={node.title} className={getClasses(node)}>
      <S.LinkWrapper className="link-wrapper">
        <Link
          id={`${id}-${node.environmentId}`}
          className="link"
          onClick={e => goToEnv(e)}
          onKeyUp={e => {
            if (e.keyCode === 13 || e.keyCode === 32) goToEnv(e);
          }}
          tabIndex="0"
          to={`/${organizationId}/${node.projectId}/${encodeURIComponent(
            node.environmentId
          )}`}
          role="menuitem"
          aria-label={node.title}
        >
          {node.environmentId == currentEnvironment && (
            <span className="active" />
          )}
          <Highlighter
            highlightClassName="blue"
            searchWords={[searchValue]}
            textToHighlight={node.title ? node.title : ""}
            autoEscape={true}
            activeIndex={-1}
            caseSensitive={false}
          />
        </Link>
      </S.LinkWrapper>
      {children && (
        <ol>
          {children.map(node => {
            return (
              <MenuLinkItem
                id={id}
                node={node}
                organizationId={organizationId}
                currentEnvironment={currentEnvironment}
                key={node.environmentId}
                searchValue={searchValue}
                filter={filter}
                history={history}
                closeDropDown={closeDropDown}
              />
            );
          })}
        </ol>
      )}
    </S.Wrapper>
  );
};

MenuLinkItem.propTypes = {
  node: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  organizationId: PropTypes.string,
  currentEnvironment: PropTypes.string,
  id: PropTypes.string,
  searchValue: PropTypes.string,
  filter: PropTypes.func,
  closeDropDown: PropTypes.func
};

export default withRouter(MenuLinkItem);
