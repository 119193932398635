import React, { useState, useEffect, useContext } from "react";

import { ReactReduxContext } from "react-redux";

import { injectReducer } from "Reducers";

const withReducers = reducers => WrappedComponent => {
  const Extended = props => {
    const [loaded, setLoaded] = useState(false);
    const context = useContext(ReactReduxContext);

    useEffect(() => {
      let isCanceled = false;
      const reducersKeys = Object.keys(reducers);
      const loadReducers = async () => {
        const reducersPromises = await Promise.all(
          reducersKeys.map(key => reducers[key]())
        );

        reducersKeys.forEach((key, i) => {
          injectReducer(context.store, {
            key,
            reducer: reducersPromises[i].default
          });
        });
        if (isCanceled) {
          return;
        }
        setLoaded(true);
      };

      loadReducers();
      return () => (isCanceled = true);
    }, []);

    return loaded ? <WrappedComponent {...props} /> : false;
  };

  return Extended;
};

export default withReducers;
