import { combineReducers } from "redux";
import profileReducer from "Reducers/profile";
import { connectRouter } from "connected-react-router";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

const makeRootReducer = asyncReducers =>
  combineReducers({
    router: connectRouter(history),
    profile: profileReducer,
    ...asyncReducers
  });

export default makeRootReducer;

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;

  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};
