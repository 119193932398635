import styled from "styled-components";
const WIDTH = 24;

export const Layout = styled.button`
  height: ${WIDTH}px;
  width: ${WIDTH}px;
  display: block;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  z-index: 11;
  position: relative;
  :after {
    content: "";
    display: block;
    height: 40px;
    width: 40px;
    position: absolute;
    left: -8px;
    top: -7px;
    z-index: -1;
    border-radius: 2px;
    opacity: 0;
    transition: 0.3s;
  }
  :hover :after {
    opacity: 1;
  }
  .menu-close-btn {
    position: relative;
    right: 2px;
    bottom: 3px;
  }
`;

export const Line = styled.div`
  position: absolute;
  top: 0;
  height: 2px;
  background-color: white;
  width: ${WIDTH}px;
  transition-property: transform, width, height, background-color;
  transition-duration: 0.3s;
  transform-origin: center;

  &:first-child {
    transform: translate3d(0px, 8px, 0px) rotate(0deg);
  }

  &:last-child {
    transform: translate3d(0px, 16px, 0px) rotate(0deg);
  }
`;
