import styled from "styled-components";
import { themeHelpers } from "Libs/theme";

export default styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 4px;
  background: transparent;
  height: 40px;
  box-sizing: border-box;
  padding: 1px;
  .toggle-button,
  a:not(.close) {
    border: none;
    padding: 4px;
    height: 32px;
    width: 32px;
    min-width: 32px;
    cursor: pointer;
    background-color: transparent;
    text-align: center;
    box-sizing: border-box;
    border-radius: 2px;
    margin: 1px;
    display: flex;
    align-items: center;
    svg {
      width: 100%;
      max-width: 100%;
    }
    &:hover {
      background-color: ${props =>
        props.theme.name === "contrast"
          ? themeHelpers(props.theme, "buttonHover")
          : props.theme.name === "dark"
            ? themeHelpers(props.theme, "darkTint")
            : "#fff"};
      box-shadow: ${props =>
        props.theme.name === "dark"
          ? "0px 8px 24px " + themeHelpers(props.theme, "darkShade")
          : "none"};
      svg {
        opacity: 1;
        rect,
        path {
          fill: ${props =>
            props.theme.name === "contrast"
              ? props.theme.buttonText
              : props.theme.darkTint} !important;
        }
      }
    }
    &:focus {
      outline: none;
      border: 1px solid ${props => themeHelpers(props.theme, "buttonHover")};
      box-shadow: 0 0 2px ${props => themeHelpers(props.theme, "buttonHover")};
      padding: 3px;
    }
    &.active,
    &:active {
      box-shadow: ${props =>
        props.theme.name === "dark"
          ? "0px 8px 24px " + themeHelpers(props.theme, "darkShade")
          : "0 1px 4px rgba(152, 160, 171, 0.6)"};
      border: none !important;
      padding: 4px;
      background-color: ${props =>
        props.theme.name === "contrast"
          ? themeHelpers(props.theme, "buttonHover")
          : props.theme.name === "dark"
            ? themeHelpers(props.theme, "darkTint")
            : "#fff"};
    }
  }
  .active {
    background-color: ${props =>
      props.theme.name === "contrast"
        ? themeHelpers(props.theme, "buttonHover")
        : props.theme.name === "dark"
          ? themeHelpers(props.theme, "darkTint")
          : "#fff"};
    box-shadow: ${props =>
      props.theme.name === "dark"
        ? "0px 8px 24px " + themeHelpers(props.theme, "darkShade")
        : "0 1px 4px rgba(152, 160, 171, 0.6)"};
    svg {
      opacity: 1;
      rect,
      path {
        fill: ${props =>
          props.theme.name === "contrast"
            ? props.theme.buttonText
            : props.theme.primary} !important;
      }
    }
  }

  .tooltip {
    width: 300px;
    section > span {
      float: right;
      .close:not(a) {
        padding: 10px;
      }
    }
  }
  h6.title {
    display: flex;
    align-items: center;
    svg {
      margin-right: 16px;
    }
  }
`;
