import styled, { css } from "styled-components";
import { themeHelpers } from "Libs/theme";

const LEVELS = {
  info: {
    color: props => props.theme.bannerInlineInfoColor,
    background: props => themeHelpers(props.theme, "primaryTint"),
    border: ({ theme }) =>
      theme.name === "contrast"
        ? themeHelpers(theme, "primaryShade")
        : theme.primary
  },
  low: {
    color: props => props.theme.bannerInlineLowColor,
    background: () => "#D6F6EF",
    border: props => props.theme.success
  },
  warning: {
    color: props => props.theme.bannerInlineWarningColor,
    background: props => props.theme.bannerInlineWarningBg,
    border: props => props.theme.bannerInlineWarningBorder
  },
  error: {
    color: props => props.theme.bannerInlineErrorColor || "#302f45",
    background: props => props.theme.bannerInlineErrorBg || "#FCE1E1",
    border: props => props.theme.error || "#EC4646"
  }
};

const __dash = (color, direction) => `
  repeating-linear-gradient(
    to ${direction},
    ${color} 0%,
    ${color} 35%,
    transparent 35%,
    transparent 55%,
    ${color} 55%,
    ${color} 75%,
    transparent 75%,
    transparent 100%
  )
`;

const getCustomBorder = color => css`
  background-image: ${__dash(color, "right")}, ${__dash(color, "right")},
    ${__dash(color, "bottom")}, ${__dash(color, "bottom")};
`;

export default styled.div`
  font-size: 13px;
  line-height: 20px;
  box-sizing: border-box;
  padding: ${props => props.padding || "32px"};
  margin-bottom: 32px;
  border-radius: 2px;
  background: ${props => LEVELS[props.level || "info"].background(props)};
  ${props => getCustomBorder(LEVELS[props.level || "info"].border(props))};
  background-position: left top, left bottom, left top, right top;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 24px 1px, 24px 1px, 1px 24px, 1px 24px;
  color: ${props => LEVELS[props.level || "info"].color(props)};
  p {
    color: ${props => LEVELS[props.level || "info"].color(props)};
  }
`;
